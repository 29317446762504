export const ActionTypess = {
    GET_PAYOUT:'GET_PAYOUT',
    GET_PAYOUT_FAIL:'GET_PAYOUT_FAIL',
    GET_PAYOUT_SUCCESS:'GET_PAYOUT_SUCCESS',
    GET_PAYOUT_STATS:'GET_PAYOUT_STATS',
    GET_PAYOUT_STATS_FAIL:'GET_PAYOUT_STATS_FAIL',
    GET_PAYOUT_STATS_SUCCESS:'GET_PAYOUT_STATS_SUCCESS',
    CREATE_PAYOUT:'CREATE_PAYOUT',
    CREATE_PAYOUT_FAIL:'CREATE_PAYOUT_FAIL',
    CREATE_PAYOUT_SUCCESS:'CREATE_PAYOUT_SUCCESS',
    GET_PAYOUT_BY_NAME:'GET_PAYOUT_BY_NAME',
    GET_PAYOUT_BY_NAME_FAIL:'GET_PAYOUT_BY_NAME_FAIL',
    GET_PAYOUT_BY_NAME_SUCCESS:'GET_PAYOUT_BY_NAME_SUCCESS',
    UPDATE_PAYOUT:'UPDATE_PAYOUT',
    UPDATE_PAYOUT_FAILED:'UPDATE_PAYOUT_FAILED',
    UPDATE_PAYOUT_SUCCESS:'UPDATE_PAYOUT_SUCCESS',
    UPDATE_PAYOUT_BY_NAME:'UPDATE_PAYOUT_BY_NAME',
    UPDATE_PAYOUT_BY_NAME_FAILED:'UPDATE_PAYOUT_BY_NAME_FAILED',
    UPDATE_PAYOUT_BY_NAME_SUCCESS:'UPDATE_PAYOUT_BY_NAME_SUCCESS',
  } ;
  
 
  
  export function getPayout(filter) {
    return { type: ActionTypess.GET_PAYOUT,
    payload:filter};
}

export function getPayoutSuccess(payout) {

  return { type: ActionTypess.GET_PAYOUT_SUCCESS, payload: payout };
}

export function getPayoutFailed(payload) {

  return { type: ActionTypess.GET_PAYOUT_FAIL, payload };
}
  export function getPayoutStats() {
    return { type: ActionTypess.GET_PAYOUT_STATS};
}

export function getPayoutStatsSuccess(payout) {

  return { type: ActionTypess.GET_PAYOUT_STATS_SUCCESS, payload: payout };
}

export function getPayoutStatsFailed(payload) {

  return { type: ActionTypess.GET_PAYOUT_STATS_FAIL, payload };
}
export function getPayoutByName(payload) {
  return {
    type: ActionTypess.GET_PAYOUT_BY_NAME,
    payload: payload
  };
}

export function getPayoutByNameSuccess(payout) {

  return { type: ActionTypess.GET_PAYOUT_BY_NAME_SUCCESS, payload: payout };
}

export function getPayoutByNameFailed(payload) {

  return { type: ActionTypess.GET_PAYOUT_BY_NAME_FAIL, payload };
}

export function updatePayout(payout) {
  return { type: ActionTypess.UPDATE_PAYOUT, payload: payout };
}

export function updatePayoutSuccess(payouts) {

  return { type: ActionTypess.UPDATE_PAYOUT_SUCCESS, payload: payouts };
}

export function updatePayoutFailed(payouts) {

  return { type: ActionTypess.UPDATE_PAYOUT_FAILED, payouts };
}

export function createPayout(payout) {
  return { type: ActionTypess.CREATE_PAYOUT, payload: payout };
}

export function createPayoutSuccess(payout) {

  return { type: ActionTypess.CREATE_PAYOUT_SUCCESS, payload: payout };
}

export function createPayoutFailed(payout) {

  return { type: ActionTypess.CREATE_PAYOUT_FAILED, payout };
}
