import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_TRANSACTIONS,GET_TRANSACTIONSINCOME, GET_TRANSACTIONSINCOME_STATS, GET_TRANSACTIONS_PARTNER, GET_TRANSACTIONS_PARTNER_STATS } from "./actionTypes"

import {
  getTransactionsSuccess,
  getTransactionsFailed,
  getTransactionsIncomeSuccess,
  getTransactionsIncomeFailed,
  getTransactionsPartnerSuccess,
  getTransactionsPartnerFailed,
  getTransactionsIncomeStatsSuccess,
  getTransactionsIncomeStatsFailed,
  getTransactionsPartnerStatsSuccess,
  getTransactionsPartnerStatsFailed,
 
} from "./actions"
import { getTransactions, getTransactionsIncome, getTransactionsIncomestats, getTransactionsPartner, getTransactionsPartnerstats } from "helpers/api_helper"

//Include Both Helper File with needed methods

function* getTransactionsSaga({payload}) {
  //  console.log("paayload", payload)
  try {
    const data = yield getTransactions(payload)
    yield put(getTransactionsSuccess(data))
  } catch (error) {
    console.log('error', error)
    yield put(getTransactionsFailed(error))
  }
}

function* getTransactionsIncomeSaga({payload}) {
  try {
    const response = yield getTransactionsIncome(payload)
    const { data, ...incomeFilters } = response 
    yield put(getTransactionsIncomeSuccess({incomeFilters:incomeFilters,incomeData:data}))
  } catch (error) {
    yield put(getTransactionsIncomeFailed(error))
  }
}

function* getTransactionsIncomeStatsSaga() {
  try {

    const response = yield getTransactionsIncomestats()
    // console.log("response",response)
    yield put(getTransactionsIncomeStatsSuccess(response))
  } catch (error) {
    yield put(getTransactionsIncomeStatsFailed(error))
  }
}

function* getTransactionsPartnerSaga({payload}) {
 try {
   const response = yield getTransactionsPartner(payload)
   const { data, ...partnerFilters } = response
  //  console.log("data", data)
   yield put(getTransactionsPartnerSuccess({partnerFilters:partnerFilters,incomePartner:data}))
 } catch (error) {
   yield put(getTransactionsPartnerFailed(error))
 }
}
function* getTransactionsPartnerStatsSaga() {
 try {
   const response = yield getTransactionsPartnerstats()
  //  console.log("data", data)
   yield put(getTransactionsPartnerStatsSuccess(response))
 } catch (error) {
   yield put(getTransactionsPartnerStatsFailed(error))
 }
}

// function* onUpdateUser({ payload: user }) {
//   try {
//     const {id, ...updatedUser} = user
//     updatedUser.roles = updatedUser.roles?.map(el => {return el.id})
//     const response = yield updateAdmin(id, updatedUser)
//     yield put(updateUserSuccess(user))
//   } catch (error) {
//     yield put(updateUserFail(error))
//   }
// }

// function* onDeleteUser({ payload: id }) {
//   try {
//     //const response = yield call(deleteUser, user) replace with api
//     yield put(deleteUserSuccess(id))
//   } catch (error) {
//     yield put(deleteUserFail(error))
//   }
// }

// function* resetUsersMessagesSaga() {
//   yield put(resetUsersMessagesSuccess())
// }

// function* onAddNewUser({ payload: user }) {

//   try {
//     //console.log("----", user)
//     user.roles = user.roles?.map(role => {return role.id})
//     const response = yield createAdmin(user)
//     const {data} = yield call(getAdmins)
//     yield put(getUsersSuccess(data))
//   } catch (error) {
//     yield put(addUserFail(error))
//   }
// }

function* transactionsSaga() {
  yield takeEvery(GET_TRANSACTIONS, getTransactionsSaga)
  yield takeEvery(GET_TRANSACTIONSINCOME_STATS, getTransactionsIncomeStatsSaga)
  yield takeEvery(GET_TRANSACTIONS_PARTNER_STATS, getTransactionsPartnerStatsSaga)
  yield takeEvery(GET_TRANSACTIONSINCOME, getTransactionsIncomeSaga)
  yield takeEvery(GET_TRANSACTIONS_PARTNER, getTransactionsPartnerSaga)
}

export default transactionsSaga;
