import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import { isEmpty, map } from "lodash"
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import moment from "moment"
import {
  getRetailers,
  resetRetailersMessages,
  updateRetailer,
  getMDMCategories,
  getMDMBrand,
  getMDMCities,
  getRetailersStats,
} from "store/actions"
import ModalRetailer from "./ModalRetailer"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Col, Row, UncontrolledTooltip, Card, CardBody, Button } from "reactstrap"
import SuccessModal from "components/Common/SuccessModal"
import { withTranslation } from "react-i18next"
import MiniCards from "pages/Dashboard/mini-cards"
import { checkPermission } from "helpers/functions"

function Retailer(props) {
  //meta title
  document.title = "Kasb"

  const history = useHistory()
  const [hiddenColumn, setHiddenColumn] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const currentLanguage = localStorage.getItem("I18N_LANGUAGE")

  const [retailer, setRetailer] = useState(null)

  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState("")

  const dispatch = useDispatch()
  const [filter, setFilter] = useState({
    shopName: "",
    brandId: "",
    categoryId: "",
    cityId: "",
    from:"",
    to:"",
    page: 1,
    limit: 100,
  })
  const {
    retailersData,
    success,
    error,
    nextPage,
    previousPage,
    currentPage,
    total,
    limit,
    loading
  } = useSelector(state => state.retailers)
  const userRoles = useSelector(state => state.Login.user?.roles)

  const {
    retailersStats
  } = useSelector(state => state.retailers)
  const { categoriesData } = useSelector(state => state.categories)
  const { citiesData } = useSelector(state => state.cities)
  const { brandData } = useSelector(state => state.brands)
  const componentRef = useRef();
  useEffect(() => {
    dispatch(getRetailers(filter))
  }, [dispatch, filter])

  useEffect(() => {
    dispatch(getRetailersStats())
    dispatch(getMDMCategories())
    dispatch(getMDMBrand())
    dispatch(getMDMCities())
    // dispatch(getRetailersByName(filter.name))
  }, [dispatch, filter])

   const [miniCards, setMiniCards] = useState([
    {
      title: props.t("Total joinings"),
      iconClass: "bx-check-circle",
      text: 0,
    },
    {
      title: props.t("Today joinings"),
      iconClass: "dripicons-user-group",
      text:0,
    },
  ])
  useEffect(() => {
    if(retailersData != undefined){
      setMiniCards([
        {
          title: props.t("Total joinings"),
          iconClass: "bx-check-circle",
          text: retailersStats?.totalJoinings,
        },
        {
          title: props.t("Today joinings"),
          iconClass: "dripicons-user-group",
          text:retailersStats?.todayJoinings,
        },
      ]
      )
    }
  }, [retailersStats])
  useEffect(() => {
    if (!isEmpty(retailersData) && !!isEdit) {
      setIsEdit(false)
    }
  }, [retailersData])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setRetailer(null)
    } else {
      setModal(true)
    }
  }
  const handleOrderClick = arg => {
    const retailer = arg
    setRetailer({
      ...retailer,
    })

    setIsEdit(true)

    toggle()
  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = retailer => {
    setRetailer(retailer)
    setDeleteModal(true)
  }

  const handleDeleteRetailer = () => {
    if (retailer.id) {
      dispatch(updateRetailer({ id: retailer.id, status: "archived" }))
      setDeleteModal(false)
      dispatch(getRetailers())
    }
  }
  const handleRetailerClicks = () => {
    setRetailer()
    setIsEdit(false)
    toggle()
  }

  useEffect(() => {
    if (success === "CREATE") {
      setSuccessModal(true)
      setMessage("Retailer created successfully !")
      dispatch(resetRetailersMessages())
    } else if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("successfully !")
      dispatch(resetRetailersMessages())
    }
  }, [success])


  // console.log("retailersData",retailersData)

  const columns = useMemo(
    () => [
      {
        Header: props.t("Logo"),
        accessor: "image_url",
        disableFilters: true,
        accessor: cellProps => (
          <>
            {!cellProps.image_url ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  {cellProps.firstname.charAt(0)}
                </span>
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={cellProps.brand?.imageUrl}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      
      {
        Header: props.t("Full name"),
        accessor: "firstname",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <div
              className="external-event"
              onClick={() => {if (checkPermission(userRoles, ["Retailer", "Retailer", "Show"])) history.push(`/retailer-profile/${cellProps.id}`)}}
            >
              {cellProps.firstname + " " + cellProps.lastname + " " ||
                props.t("Not provided")}
              ({cellProps.username})
            </div>
          )
        },
      },
      {
        Header: props.t("Sales PartnerId"),
        accessor: "salesPartnerId",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.salesPartnerId || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Shop Name"),
        accessor: "shopName",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.shopName || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("City"),
        accessor: "city",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {cellProps.city
                ? (currentLanguage == "ar" && cellProps.city?.nameAr) ||
                  (currentLanguage == "en" && cellProps.city?.nameEn) ||
                  (currentLanguage == "fr" && cellProps.city?.nameFr)
                : cellProps.governorate
                ? (currentLanguage == "ar" && cellProps.governorate?.nameAr) ||
                  (currentLanguage == "en" && cellProps.governorate?.nameEn) ||
                  (currentLanguage == "fr" && cellProps.governorate?.nameFr)
                : cellProps.country
                ? (currentLanguage == "ar" && cellProps.country?.nameAr) ||
                  (currentLanguage == "en" && cellProps.country?.nameEn) ||
                  (currentLanguage == "fr" && cellProps.country?.nameFr)
                : props.t("Not provided")}
            </>
          )
        },
      },

      {
        Header: props.t("Category"),
        accessor: "category",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {cellProps.category
                ? (currentLanguage == "ar" && cellProps.category?.nameAr) ||
                  (currentLanguage == "en" && cellProps.category?.nameEn) ||
                  (currentLanguage == "fr" && cellProps.category?.nameFr)
                : props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Brand"),
        accessor: "brand",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {(currentLanguage == "ar" && cellProps.brand?.nameAr) ||
                (currentLanguage == "en" && cellProps.brand?.nameEn) ||
                (currentLanguage == "fr" && cellProps.brand?.nameFr) ||
                props.t("Not provided")}
            </>
          )
        },
      },

      {
        Header: props.t("RCOM") + "%",
        accessor: "comission",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.comission || props.t("Not provided")}</>
        },
      },
      // {
      //   Header: props.t("Last Transaction Date"),
      //   accessor: "lastTransaction",
      //   disableFilters: true,
      //   accessor: cellProps => {
      //     return (
      //       <>
      //         {(cellProps.lastTransaction &&
      //           moment(new Date(cellProps.lastTransaction)).format(
      //             "YYYY-MM-DD HH:mm"
      //           )) ||
      //           props.t("Not provided")}
      //       </>
      //     )
      //   },
      // },

      {
        Header: props.t("Last Transaction Date"),
        accessor: "lastTransaction",
        disableFilters: true,
        accessor: cellProps => {
          return <>{(cellProps.lastTransaction && moment(cellProps.lastTransaction).format("YYYY-MM-DD HH:mm")) || props.t("Not provided")}</>
        },
      },



      {
        Header: props.t("Added by"),
        accessor: "createdByAdmin",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.createdBy &&
                cellProps.createdByAdmin?.firstname +
                  " " +
                  cellProps.createdByAdmin?.lastname) ||
                props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Join Date"),
        accessor: "createdAt",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {moment(new Date(cellProps.createdAt)).format("YYYY-MM-DD HH:mm")}
            </>
          )
        },
      },
      {
        Header: props.t("Status"),
        accessor: "status",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.status || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Action"),
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {checkPermission(userRoles, ["Retailer", "Retailer", "Update"]) && 
              <Link
                to="#"
                className="text-tip"
                onClick={() => {
                  const orderData = cellProps.row.original
                  handleOrderClick(orderData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>}
              {checkPermission(userRoles, ["Retailer", "Retailer", "Delete"]) &&
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDelete(orderData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>}
            </div>
          )
        },
      },
    ],
    []
  )

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }
  const setCategory = category => {
    setFilter(prevFilter => {
      return { ...prevFilter, categoryId: category.value }
    })
  }

  const setShopname = name => {
    setFilter(prevFilter => {
      return { ...prevFilter, shopName: name.label }
    })
  }

  const setCity = city => {
    setFilter(prevFilter => {
      return { ...prevFilter, cityId: city.value }
    })
  }
  const setBrand = brand => {
    setFilter(prevFilter => {
      return { ...prevFilter, brandId: brand.value }
    })
  }


  
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteRetailer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <div  className="container-fluid">
          <Breadcrumbs
            title={props.t("Retailer")}
            breadcrumbItem={props.t("Retailer List")}
          />
          <Row>
            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody >
                  <TableContainer
                    columns={columns}
                    data={retailersData}
                    userRef={componentRef}
                    // isPrintOption={checkPermission(userRoles, ["Retailer", "Retailer", "Print"])}
                    isExcelOption={checkPermission(userRoles, ["Retailer", "Retailer", "Export"])}
                    shopnameFilter={true}
                    setHiddenColumn={setHiddenColumn}
                    setShopname={setShopname}
                    retailers={retailersData}
                    resetOption={true}
                    categoryFilter={true}
                    categories={categoriesData}
                    setCategory={setCategory}
                    cityFilter={true}
                    cities={citiesData}
                    setCity={setCity}
                    brandFilter={true}
                    brands={brandData}
                    setBrand={setBrand}
                    handleOrderClicks={handleRetailerClicks}
                    isAddRetailerOptions={checkPermission(userRoles, ["Retailer", "Retailer", "Create"])}
                    customPageSize={100}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    // setFilter={doSearch}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                  
                  <ModalRetailer
                    modal={modal}
                    toggle={toggle}
                    isEdit={isEdit}
                    retailer={retailer}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
Retailer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(Retailer)
