import axios from "axios";
import { del, get, post, put, uploadFile } from "./api_setup";
import * as url from "./url_helper";

//permission

// const getRoless = ({ limit, page}) => get(url.GET_ROLESS+`?limit=${limit}&page=${page}`);
// const getRolesById = (id) => get(url.GET_ROLES+id);
const createRole = (data) => post(url.POST_ROLES, data);
// const updateRole = (id, data) => put(url.PUT_ROLES+id, data);
const deleteRoles = (id) => del(url.DELETE_ROLES+ id);

// Login Method
const postLogin = data => post(url.POST_LOGIN, data);
const postResetPassword = (token, password) => post(url.RESET_PASSWORD+token, password);
//cities
const getCities = ({limit = 1000, page = 1}) => get(url.GET_METADATA_CITY+`?limit=${limit}&page=${page}`);
const putCities = (id,city) => put(url.PUT_METADATA_CITY + id, city);
const postCities = (city) => post(url.POST_METADATA_CITY, city);

//countries
const getCountries = ({limit = 1000, page = 1}) => get(url.GET_METADATA_COUNTRY+`?limit=${limit}&page=${page}`);
const putCountries = (id,country) => put(url.PUT_METADATA_COUNTRY + id, country);
const postCountries = (country) => post(url.POST_METADATA_COUNTRY, country);

//category
const getCategories = ({limit = 1000, page = 1}) => get(url.GET_METADATA_CATEGORY+`?limit=${limit}&page=${page}`);
const putCategories = (id,category) => put(url.PUT_METADATA_CATEGORY + id, category);
const postCategories = (category) => post(url.POST_METADATA_CATEGORY, category);
const deleteCategories = (id) => del(url.DELETE_METADATA_CATEGORY+ id);
//levelcomision 
const getLevelcomissions = ({ limit = 1000, page = 1 }) => get(url.GET_METADATA_LEVEL+`?limit=${limit}&page=${page}`);
const putLevelcomissions = (id,level) => put(url.PUT_METADATA_LEVEL + id, level);
const postLevelcomissions  = (level) => post(url.POST_METADATA_LEVEL, level);
//governorate
const getGovernorates = ({ limit = 1000, page = 1 }) => get(url.GET_METADATA_GOVERNORATE+`?limit=${limit}&page=${page}`);
const putGovernorates = (id,governorate) => put(url.PUT_METADATA_GOVERNORATE + id, governorate);
const postGovernorates = (governorate) => post(url.POST_METADATA_GOVERNORATE, governorate);
//brand
const getBrand = ({ limit = 1000, page = 1}) => get(url.GET_METADATA_BRAND+`?limit=${limit}&page=${page}`);
const putBrand = (id,brand) => put(url.PUT_METADATA_BRAND + id, brand);
const postBrand = (brand) => post(url.POST_METADATA_BRAND, brand);
//payment-method
const getPaymentMethod = ({ limit = 1000, page = 1, status="" }) => get(url.GET_METADATA_PAYMENT_METHOD+`?limit=${limit}&page=${page}&status=${status}`);
const putPaymentMethod = (id,paymentMethod) => put(url.PUT_METADATA_PAYMENT_METHOD + id, paymentMethod);
const postPaymentMethod = (paymentMethod) => post(url.POST_METADATA_PAYMENT_METHOD, paymentMethod);
// e-wallet
const getEwallet = ({limit = 1000, page = 1}) => get(url.GET_EWALLET+`?limit=${limit}&page=${page}`);
const getEwalletStats = () => get(url.GET_EWALLET_STATS);
// Payout 
const getPayouts = ({ limit = 1000, page = 1 ,from="",to="",partnerId="",status="" }) => get(url.GET_PAYOUT+`?limit=${limit}&page=${page}&partnerId=${partnerId}&status=${status}&from=${from}&to=${to}`);
const getPayoutStats = () => get(url.GET_PAYOUT_STATS);
const getPayoutByname = ({ limit = 1000, page = 1 ,from,to,partnerId=""}) => get(url.GET_PAYOUT_BY_NAME+`?status=released&status=paid&?limit=${limit}&page=${page}&partnerId=${partnerId}&from=${from}&to=${to}`);
const putPayouts = (id,data) => put(url.PUT_PAYOUT+ id, data);
const postPayout = (data) => post(url.POST_PAYOUT, data);
// Payin
const getPayins = ({ limit = 1000, page = 1 ,from="",to="",partnerId="",status="" }) => get(url.GET_PAYIN+`?limit=${limit}&page=${page}&partnerId=${partnerId}&status=${status}&from=${from}&to=${to}`);
const getPayinStats = () => get(url.GET_PAYIN_STATS);
const putPayins = (id,data) => put(url.PUT_PAYIN+ id, data);
const postPayin = (data) => post(url.POST_PAYIN, data);

// Gets the logged in user data from local session
const getLoggedInUser = () => get(url.VERIFY_ME);

const getAdmins = ({ limit = 20, page = 1 }) => get(url.GET_ADMINS+`?limit=${limit}&page=${page}`);

const getAdmin= (id) => get(url.GET_ADMIN+id);

const getUser = (id) => get(url.GET_USER+id);

const createAdmin = (data) => post(url.POST_REGISTER, data);

const updateAdmin = (id, data) => put(url.PUT_UPDATE+id, data);

const getRoles = ({limit, page}) => get(url.GET_ROLES+`?limit=${limit}&page=${page}`);
const updateRole = (id, role) => put(url.PUT_ROLE+id, role);
const getPermissions = () => get(url.GET_PERMISSIONS);

const getRetailers = ({ limit, page, retailerId="", brandId="", categoryId="",from,to, shopName="", cityId=""  }) => get(url.GET_RETAILERS+`?limit=${limit}&page=${page}&retailerId=${retailerId}&cityId=${cityId}&brandId=${brandId}&categoryId=${categoryId}&shopName=${shopName}&from=${from}&to=${to}`);
const getRetailersByName = (name) => get(url.GET_RETAILERS+`?q=${name}`);

const getRetailerstats = () => get(url.GET_RETAILERS_STATS);
const getRetailer = (id) => get(url.GET_RETAILER+id);
const createRetailer = (data) => post(url.POST_RETAILER, data);
const updateRetailer = (id, data) => put(url.PUT_RETAILER+id, data);

const getBuisnessPartners = ({ limit = 1000, page = 1 ,from,to }) => get(url.GET_BUINESS_PARTNERS+`?limit=${limit}&page=${page}&from=${from}&to=${to}`);
const getBuisnessPartnersStats = ({ limit = 1000, page = 1 ,from,to,partnerId}) => get(url.GET_BUINESS_PARTNERS_STATS+`?from=${from}&to=${to}&limit=${limit}&page=${page}&partnerId=${partnerId}`);

const getBuisnessPartner = (id) => get(url.GET_BUINESS_PARTNER+id);
const getPartnerstats = () => get(url.GET_PARTNERS_STATS);
const updateBuisnessPartner = (id, data) => put(url.PUT_BUINESS_PARTNER+id, data);

//Transactions partnerId, retailerId, from & to
const getTransactions = ({userId="",partnerId="",retailerId="",from,to, limit, page}) => get(url.GET_TRANSACTIONS+`?userId=${userId}&partnerId=${partnerId}&retailerId=${retailerId}&limit=${limit}&page=${page}&from=${from}&to=${to}`);
const getTransactionsIncomestats = () => get(url.GET_TRANSACTIONSINCOME_STATS);
const getTransactionsPartnerstats = () => get(url.GET_TRANSACTIONS_PARTNER_STATS);
const getTransactionsIncome = ({userId="",partnerId,retailerId,from,to,limit, page}) => get(url.GET_TRANSACTIONSINCOME+`?partnerId=${partnerId}&retailerId=${retailerId}&limit=${limit}&page=${page}&userId=${userId}&from=${from}&to=${to}`);
const getTransactionsPartner = ({partnerId="",commissionType="", from, to, limit, page}) => get(url.GET_TRANSACTIONS_PARTNER+`?partnerId=${partnerId}&commissionType=${commissionType}&limit=${limit}&page=${page}&from=${from}&to=${to}`);

//configs 
const getConfigs = () => get(url.GET_CONFIGS);
const updateConfigs = (id, data) => put(url.PUT_CONFIGS+id, data);
//Logs
const getLogs = ({adminId, model, limit, page}) => get(url.GET_LOGS+`?model=${model}&adminId=${adminId}&page=${page}&limit=${limit}`);
const getLog = () => get(url.GET_LOGS_BY_MODEL);

//upload files 
const upload = (data) => uploadFile(url.UPLOAD_FILES, data);


//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser();
};

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};


// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
//const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);


// get Events
export const getEvents = () => get(url.GET_EVENTS);

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } });


// get contacts
export const getUsers = () => get(url.GET_USERS);

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user);

// update user
export const updateUser = user => put(url.UPDATE_USER, user);

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } });



export const getUserProfile = () => get(url.GET_USER_PROFILE);


// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA);
export const getYearlyData = () => get(url.GET_YEARLY_DATA);
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA);

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });


export {
  getLoggedInUser,
  isUserAuthenticated,
  createRole,
  updateRole,
  deleteRoles,
  postLogin,
  getCities,
  putCities,
  postCities,
  getLevelcomissions,
  putLevelcomissions,
  postLevelcomissions,
  getCountries,
  putCountries,
  postCountries,
  getCategories,
  putCategories,
  postCategories,
  deleteCategories,
  getGovernorates,
  putGovernorates,
  postGovernorates,
  getBrand,
  putBrand,
  postBrand,
  getPaymentMethod,
  putPaymentMethod,
  postPaymentMethod,
  getEwallet,
  getEwalletStats,
  getPayouts,
  getPayoutStats,
  getPayins,
  getPayinStats,
  getPayoutByname,
  putPayouts,
  putPayins,
  postFakeRegister,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtForgetPwd,
  postJwtProfile,
  getAdmins,
  createAdmin,
  updateAdmin,
  getRoles,
  getRetailer,
  getRetailerstats,
  getRetailers,
  getRetailersByName,
  updateRetailer,
  getBuisnessPartners,
  getBuisnessPartnersStats,
  getBuisnessPartner,
  getPartnerstats,
  updateBuisnessPartner,
  upload,
  createRetailer,
  getTransactions,
  getTransactionsIncome,
  getTransactionsIncomestats,
  getTransactionsPartnerstats,
  getConfigs,
  updateConfigs,
  getUser, 
  getAdmin,
  getLogs,
  getLog,
  getTransactionsPartner,
  postResetPassword,
  postPayout,
  postPayin,
  getPermissions,
  
};
