
import {
    COUNTRY_MESSAGES_RESET,
    CREATE_COUNTRY_FAILED,
    CREATE_COUNTRY_REQUEST,
    CREATE_COUNTRY_SUCCESS,
    DELETE_COUNTRY_FAILED,
    DELETE_COUNTRY_REQUEST,
    DELETE_COUNTRY_SUCCESS,
    GET_COUNTRY_BY_NAME_FAILED,
    GET_COUNTRY_BY_NAME_REQUEST,
    GET_COUNTRY_BY_NAME_SUCCESS,
    GET_COUNTRY_FAILED,
    GET_COUNTRY_REQUEST,
    GET_COUNTRY_SUCCESS,
    UPDATE_COUNTRY_FAILED,
    UPDATE_COUNTRY_REQUEST,
    RESET_COUNTRY_MESSAGES,
    RESET_COUNTRY_MESSAGES_SUCCESS,
    UPDATE_COUNTRY_SUCCESS
} from "./actionTypes.js"

export const getCountriesByName = name => ({
    type: GET_COUNTRY_BY_NAME_REQUEST,
    payload: name
})


export const getCountriesByNameSuccess = payload => ({
    type: GET_COUNTRY_BY_NAME_SUCCESS,
    payload
})


export const getCountriesByNameFailed = payload => ({
    type: GET_COUNTRY_BY_NAME_FAILED,
    payload
})




export const getMDMCountries = (filter) => ({
    type: GET_COUNTRY_REQUEST,
    payload:filter
})



export const getMDMCountriesSuccess = COUNTRY => ({
    type: GET_COUNTRY_SUCCESS,
    payload: COUNTRY
})

export const getMDMCountriesFailed = payload => ({
    type: GET_COUNTRY_FAILED,
    payload
})

export const createMDMCountry = country => ({
    type: CREATE_COUNTRY_REQUEST,
    payload: country
})

export const createMDMCountrySuccess = country => ({
    type: CREATE_COUNTRY_SUCCESS,
    payload: country
})

export const createMDMCountryFailed = payload => ({
    type: CREATE_COUNTRY_FAILED,
    payload
})

export const deleteMDMCountry = id => ({
    type: DELETE_COUNTRY_REQUEST,
    payload: id
})

export const deleteMDMCountrySuccess = id => ({
    type: DELETE_COUNTRY_SUCCESS,
    payload: id
})

export const deleteMDMCountryFailed = id => ({
    type: DELETE_COUNTRY_FAILED,
    payload: id
})

export const updateMDMCountry = (country )=> ({
    type: UPDATE_COUNTRY_REQUEST,
    payload: country
})

export const updateMDMCountrySuccess = (country ) => ({
    type: UPDATE_COUNTRY_SUCCESS,
    payload: country
})

export const updateMDMCountryFailed = payload => ({
    type: UPDATE_COUNTRY_FAILED,
    payload
})

export const resetMDMCountryMessages = () => ({
    type: COUNTRY_MESSAGES_RESET
}
)


export const resetCountryMessages = () => ({
    type: RESET_COUNTRY_MESSAGES,
  })
  
  export const resetCountryMessagesSuccess = () => ({
    type: RESET_COUNTRY_MESSAGES_SUCCESS,
  })

