
import { all,call, put, takeEvery } from "redux-saga/effects";
import {
 
  createMDMCategoryFailed,
  createMDMCategorySuccess,
  deleteMDMCategoryFailed,
  deleteMDMCategorySuccess,
  getMDMCategoriesFailed,
  getMDMCategoriesSuccess,
  updateMDMCategoryFailed,
  updateMDMCategorySuccess,
  resetCategoryMessagesSuccess,
  
} from "./actions"
import {
  CATEGORY_MESSAGES_RESET,
  CREATE_CATEGORY_REQUEST,
  DELETE_CATEGORY_REQUEST,
  GET_CATEGORY_BY_NAME_REQUEST,
  GET_CATEGORY_REQUEST,
  RESET_CATEGORY_MESSAGES,
  UPDATE_CATEGORY_REQUEST
} from "./actionTypes";



import {deleteCategories, getCategories, postCategories, putCategories } from "helpers/api_helper";



function* getCategoriesSaga({payload}) {
  try {
    const response = yield getCategories(payload || {page: 1, limit: 1000});
    yield put(getMDMCategoriesSuccess(response))
  } catch (e) {
    console.log("eerr", e.repsonse)
    yield put(getMDMCategoriesFailed(e))
  }
}

function* getCategoriesBynameSaga() {
 
}


function* createCategorySaga({ payload: category }) {
  try {

    const data = yield postCategories(category);
    const  response = yield getCategories({limit: 20, page: 1});
    yield put(getMDMCategoriesSuccess(category))

  } catch (e) {
    yield put(createMDMCategoryFailed(e))
  }
}

function* deleteCategorySaga({ payload: id }) {
  try {
    // const{id, ...updatedid}= idcategory
    // console.log("delete",idcategory, updatedid  )
    // console.log("delete category",id   )
    const response = yield deleteCategories(id);
    yield put(deleteMDMCategorySuccess(id))
  } catch (e) {
    yield put(deleteMDMCategoryFailed(e))
  }
}

function* updateCategorySaga({payload:category }) {
  try {
    // separer l id & objet category
    const{id, ...updatedCategory}= category
    const data = yield putCategories(id,updatedCategory);
    const response = yield getCategories({limit: 20, page: 1});
    yield put(getMDMCategoriesSuccess(response))
  } catch (e) {
    yield put(updateMDMCategoryFailed(e))
  }
}



function* resetCategoryMessagesSaga() {
  yield put(resetCategoryMessagesSuccess())
}




export default function* categorySaga() {
  yield all([
    takeEvery(GET_CATEGORY_REQUEST, getCategoriesSaga),
    takeEvery(GET_CATEGORY_BY_NAME_REQUEST, getCategoriesBynameSaga),
    takeEvery(CREATE_CATEGORY_REQUEST, createCategorySaga),
    takeEvery(DELETE_CATEGORY_REQUEST, deleteCategorySaga),
    takeEvery(RESET_CATEGORY_MESSAGES, resetCategoryMessagesSaga),
    takeEvery(UPDATE_CATEGORY_REQUEST, updateCategorySaga),
  ])
}
