import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_USERS, GET_USER_PROFILE , ADD_NEW_USER , DELETE_USER, UPDATE_USER, RESET_USERS_MESSAGES, GET_ADMIN_PROFILE, UPDATE_PROFILE } from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  resetUsersMessagesSuccess,
  updateProfilSuccess,
  updateProfilFail
} from "./actions"

//Include Both Helper File with needed methods
import { getUserProfile , getAdmins, createAdmin, updateAdmin, getUser, getAdmin } from "../../helpers/api_helper"

function* fetchUsers({payload}) {
  try {
    // console.log("payloadAdmin",payload)
    const data = yield getAdmins(payload)
    yield put(getUsersSuccess(data))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchAdminProfile({payload}) {
  try {
    const response = yield getAdmin(payload)
    yield put(getUserProfileSuccess(response))
  } catch (error) {
    yield put(getUserProfileFail(error))
  }
}

function* fetchUserProfile({payload}) {
  try {
    const response = yield getUser(payload)
    yield put(getUserProfileSuccess(response))
  } catch (error) {
    yield put(getUserProfileFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const {id, ...updatedUser} = user
    updatedUser.roles = updatedUser.roles?.map(el => {return el.id})
    const response = yield updateAdmin(id, updatedUser)
    yield put(updateUserSuccess(user))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}
function* onUpdateProfile({ payload: profile }) {
  try {
    const {id, ...updatedProfile} = profile
    
    yield put(updateProfilSuccess(updatedProfile))
  } catch (error) {
    yield put(updateProfilFail(error))
  }
}

function* onDeleteUser({ payload: id }) {
  try {
    //const response = yield call(deleteUser, user) replace with api
    yield put(deleteUserSuccess(id))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* resetUsersMessagesSaga() {
  yield put(resetUsersMessagesSuccess())
}

function* onAddNewUser({ payload: user }) {

  try {
    //console.log("----", user)
    user.roles = user.roles?.map(role => {return role.id})
    const response = yield createAdmin(user)
    const {data} = yield call(getAdmins)
    yield put(getUsersSuccess(data))
  } catch (error) {
    yield put(addUserFail(error))
  }
}

function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile)
  yield takeEvery(GET_ADMIN_PROFILE, fetchAdminProfile)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(UPDATE_PROFILE, onUpdateProfile)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(RESET_USERS_MESSAGES, resetUsersMessagesSaga)

}

export default usersSaga;
