import { put, takeEvery } from "redux-saga/effects"

import { ActionsTypess, createPayinFailed,getPayinStatsFailed,getPayinStatsSuccess,updatePayinFailed, updatePayinSuccess } from "./actions";

import {
  getPayinSuccess,
  getPayinFailed,

} from "./actions"
import {  getPayins, postPayin, putPayins,  getPayinStats } from "helpers/api_helper"

//Include Both Helper File with needed methods


function* getPayinSaga({ payload }) {

  try {
    const response = yield getPayins(payload);
    yield put(getPayinSuccess(response));
  } catch (e) {
    yield put(getPayinFailed(e.response?.status));
  }
}
function* getPayinStatsSaga() {

  try {
    const response = yield   getPayinStats();
    yield put(getPayinStatsSuccess(response));
  } catch (e) {
    yield put(getPayinStatsFailed(e.response?.status));
  }
}


function* createPayinSaga({ payload: payin }) {
  try {
    const data = yield postPayin(payin);
    const response = yield getPayins({ page: 1, limit: 20 });
    yield put(getPayinSuccess(response));
  } catch (e) {
    yield put(createPayinFailed(e));
  }
}


function* updatePayinSaga({ payload: payin }) {
  try {
    const { id, ...updatedPayin } = payin
    const data = yield putPayins(id, updatedPayin);
    // console.log("data",data)
    const response = yield getPayins({ page: 1, limit: 20 });
    yield put(getPayinSuccess(response));
  } catch (e) {
    // console.log("error update:", e.response);
    yield put(updatePayinFailed(e));
  }
}





function* payinSaga() {
  yield takeEvery(ActionsTypess.GET_PAYIN, getPayinSaga)
  yield takeEvery(ActionsTypess.GET_PAYIN_STATS, getPayinStatsSaga)
  yield takeEvery(ActionsTypess.UPDATE_PAYIN, updatePayinSaga)
  yield takeEvery(ActionsTypess.CREATE_PAYIN, createPayinSaga)
}

export default payinSaga;
