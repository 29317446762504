import React, { useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
 
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap"


//redux
import {  useDispatch, useSelector } from "react-redux"


import {
  createMDMGovernorate,
  getMDMCountries,
  updateMDMGovernorate,
  
} from "store/actions"

import { withTranslation } from "react-i18next"


const GovernorateModal = props => {
  const dispatch = useDispatch()
  const { modal, toggle, governorate, isEdit } = props

  const { countriesData } = useSelector(state => state.countries)

  useEffect(() => {
    {
      dispatch(getMDMCountries())
    }
  }, [dispatch])

    // console.log(loading)
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nameEn: (governorate && governorate.nameEn) || "",
      nameFr: (governorate && governorate.nameFr) || "",
      nameAr: (governorate && governorate.nameAr) || "",
      type: (governorate && governorate.type) || "governorate",
      parentId: (governorate && governorate.parentId) || "",
    },
    validationSchema: Yup.object({
      nameEn: Yup.string().required(props.t("Please Enter Your English name")),
      nameFr: Yup.string().required(props.t("Please Enter Your French name")),
      nameAr: Yup.string().required(props.t("Please Enter Your Arabic Name")),
      parentId: Yup.string().required(props.t("Please Enter Your country")),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateGovernorate = {
          id: governorate.id,
          nameEn: values.nameEn,
          nameFr: values.nameFr,
          nameAr: values.nameAr,
          parentId: parseInt(values.parentId),
        }
        // update
        //console.log("updategovernorate", updateGovernorate)
        dispatch(updateMDMGovernorate(updateGovernorate))
        validation.resetForm()
      } else {
        const newGovernorate = {
          nameEn: values["nameEn"],
          nameFr: values["nameFr"],
          nameAr: values["nameAr"],
          type: values["type"],
          parentId: parseInt(values["parentId"]),
        }
        //console.log("New Governorates", newGovernorate)
        //save new governorate
        dispatch(createMDMGovernorate(newGovernorate))
        validation.resetForm()
      }
      toggle()
    },
  })

  return (

<Modal isOpen={modal} toggle={toggle}>
<ModalHeader toggle={toggle} tag="h4">
  {!!isEdit ? props.t("Edit Governorate") : props.t("Add Governorate")}
</ModalHeader>
<ModalBody>
  <Form
    onSubmit={e => {
      e.preventDefault()
      validation.handleSubmit()
      return false
    }}
  >
    <Row form>
      <Col className="col-12">
        <div className="mb-3">
          <Label className="form-label">{props.t("English Name")}</Label>
          <Input
            name="nameEn"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.nameEn || ""}
            invalid={
              validation.touched.nameEn &&
              validation.errors.nameEn
                ? true
                : false
            }
          />
          {validation.touched.nameEn &&
          validation.errors.nameEn ? (
            <FormFeedback type="invalid">
              {validation.errors.nameEn}
            </FormFeedback>
          ) : null}
        </div>
        <div className="mb-3">
          <Label className="form-label">{props.t("French Name")}</Label>
          <Input
            name="nameFr"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.nameFr || ""}
            invalid={
              validation.touched.nameFr &&
              validation.errors.nameFr
                ? true
                : false
            }
          />
          {validation.touched.nameFr &&
          validation.errors.nameFr ? (
            <FormFeedback type="invalid">
              {validation.errors.nameFr}
            </FormFeedback>
          ) : null}
        </div>
        <div className="mb-3">
          <Label className="form-label">{props.t("Arabic Name")}</Label>
          <Input
            name="nameAr"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.nameAr || ""}
            invalid={
              validation.touched.nameAr &&
              validation.errors.nameAr
                ? true
                : false
            }
          />
          {validation.touched.nameAr &&
          validation.errors.nameAr ? (
            <FormFeedback type="invalid">
              {validation.errors.nameAr}
            </FormFeedback>
          ) : null}
        </div>
        <div className="mb-3">
          <Label className="form-label">{props.t("Country")}</Label>
          <Input
            type="select"
            name="parentId"
            className="form-select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.parentId || []}
            invalid={
              validation.touched.parentId &&
              validation.errors.parentId
                ? true
                : false
            }
          >
            <option key="empty" disabled></option>
            {countriesData.map(item => (
              <option key={item.id} value={item.id}>
                {item.nameAr}
              </option>
            ))}
          </Input>
          {validation.touched.parentId &&
          validation.errors.parentId ? (
            <FormFeedback type="invalid">
              {validation.errors.parentId}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="text-end">
          <button
            type="submit"
            className="btn btn-success save-customer"
          >
            {props.t("Save")}
          </button>
        </div>
      </Col>
    </Row>
  </Form>
</ModalBody>
</Modal>)
}
export default withTranslation()(GovernorateModal)
