/* ROLES */
export const GET_ROLES = "GET_ROLES"
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS"
export const GET_ROLES_FAIL = "GET_ROLES_FAIL"

export const CREATE_ROLE = "CREATE_ROLE"
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS"
export const CREATE_ROLE_FAIL = "CREATE_ROLE_FAIL"

export const UPDATE_ROLE = "UPDATE_ROLE"
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS"
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL"
export const DELETE_ROLE = "DELETE_ROLE"
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS"
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL"