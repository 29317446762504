export const ActionsTypess = {
    GET_PAYIN:'GET_PAYIN',
    GET_PAYIN_FAIL:'GET_PAYIN_FAIL',
    GET_PAYIN_SUCCESS:'GET_PAYIN_SUCCESS',
    GET_PAYIN_STATS:'GET_PAYIN_STATS',
    GET_PAYIN_STATS_FAIL:'GET_PAYIN_STATS_FAIL',
    GET_PAYIN_STATS_SUCCESS:'GET_PAYIN_STATS_SUCCESS',
    CREATE_PAYIN:'CREATE_PAYIN',
    CREATE_PAYIN_FAIL:'CREATE_PAYIN_FAIL',
    CREATE_PAYIN_SUCCESS:'CREATE_PAYIN_SUCCESS',
    GET_PAYIN_BY_NAME:'GET_PAYIN_BY_NAME',
    GET_PAYIN_BY_NAME_FAIL:'GET_PAYIN_BY_NAME_FAIL',
    GET_PAYIN_BY_NAME_SUCCESS:'GET_PAYIN_BY_NAME_SUCCESS',
    UPDATE_PAYIN:'UPDATE_PAYIN',
    UPDATE_PAYIN_FAILED:'UPDATE_PAYIN_FAILED',
    UPDATE_PAYIN_SUCCESS:'UPDATE_PAYIN_SUCCESS',
    UPDATE_PAYIN_BY_NAME:'UPDATE_PAYIN_BY_NAME',
    UPDATE_PAYIN_BY_NAME_FAILED:'UPDATE_PAYIN_BY_NAME_FAILED',
    UPDATE_PAYIN_BY_NAME_SUCCESS:'UPDATE_PAYIN_BY_NAME_SUCCESS',
  } ;
  
 
  
  export function getPayin(filter) {
    return { type: ActionsTypess.GET_PAYIN,
    payload:filter};
}

export function getPayinSuccess(payin) {

  return { type: ActionsTypess.GET_PAYIN_SUCCESS, payload: payin };
}

export function getPayinFailed(payload) {

  return { type: ActionsTypess.GET_PAYIN_FAIL, payload };
}
  export function getPayinStats() {
    return { type: ActionsTypess. GET_PAYIN_STATS,
};
}

export function getPayinStatsSuccess(payin) {

  return { type: ActionsTypess.GET_PAYIN_STATS_SUCCESS, payload: payin };
}

export function getPayinStatsFailed(payload) {

  return { type: ActionsTypess.GET_PAYIN_STATS_FAIL, payload };
}
export function getPayinByName(payload) {
  return {
    type: ActionsTypess.GET_PAYIN_BY_NAME,
    payload: payload
  };
}

export function getPayinByNameSuccess(payin) {

  return { type: ActionsTypess.GET_PAYIN_BY_NAME_SUCCESS, payload: payin };
}

export function getPayinByNameFailed(payload) {

  return { type: ActionsTypess.GET_PAYIN_BY_NAME_FAIL, payload };
}

export function updatePayin(payin) {
  return { type: ActionsTypess.UPDATE_PAYIN, payload: payin };
}

export function updatePayinSuccess(payin) {

  return { type: ActionsTypess.UPDATE_PAYIN_SUCCESS, payload: payin };
}

export function updatePayinFailed(payin) {

  return { type: ActionsTypess.UPDATE_PAYIN_FAILED, payin };
}

export function createPayin(payin) {
  return { type: ActionsTypess.CREATE_PAYIN, payload: payin };
}

export function createPayinSuccess(payin) {

  return { type: ActionsTypess.CREATE_PAYIN_SUCCESS, payload: payin };
}

export function createPayinFailed(payin) {

  return { type: ActionsTypess.CREATE_PAYIN_FAIL, payin };
}
