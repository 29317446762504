import React, { useEffect, useState, useMemo } from "react"
import classnames from "classnames"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  NavItem,
  NavLink,
} from "reactstrap"
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteModal from "../../../components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "../../../components/Common/TableContainer"
import moment from "moment"
import { getBusinessPartner, getBusinessPartnerStats } from "store/actions"
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const BusinessPartner = props => {
  //meta title
  document.title = "Kasb"
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    partnerId:"",
    page: 1,
    limit: 100,
  })
  const dispatch = useDispatch()

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [partnerStats, setPartnerStats] = useState(null)
 
  const {
    PartnerStatsData,
    nextPage,
    previousPage,
    currentPage,
    total,
    limit,
  } = useSelector(state => state.businesspartners)
  const { BusinessPartnerData } = useSelector(state => state.businesspartners);
  useEffect(() => {
    dispatch(getBusinessPartnerStats(filter))
    dispatch(getBusinessPartner(filter))
  }, [dispatch, filter])

  // Business Partner Column
  const columns = useMemo(
    () => [
      {
        Header: props.t("Logo"),
        accessor: "image_url",
        disableFilters: true,
        accessor: cellProps => (
          <>
            {!cellProps.image_url ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  {cellProps.firstname.charAt(0)}
                </span>
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={cellProps.image_url}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: props.t("Full Name"),
        accessor: "",
        disableFilters: true,
        accessor: cellProps => {
          return (
  
              <>
                {cellProps.firstname + " " + cellProps.lastname + " " ||
                  props.t("Not provided")}
              </>
          )
        },
      },
      {
        Header: props.t("Buyer COM"),
        accessor: "",
        disableFilters: true,
        accessor: cellProps => {
          return <>{(cellProps.levels && cellProps.levels[4]?.count) || "---"}</>
        },
      },
      {
        Header: props.t("COM L") + "1",
        accessor: "",
        disableFilters: true,
        accessor: cellProps => {
          return <>{(cellProps.levels && cellProps.levels[0]?.count) || "---"}</>
        },
      },
      {
        Header: props.t("COM L") + "2",
        accessor: "",
        disableFilters: true,
        accessor: cellProps => {
          return <>{(cellProps.levels && cellProps.levels[1]?.count) || "---"}</>
        },
      },
      {
        Header: props.t("COM L") + "3",
        accessor: "",
        disableFilters: true,
        accessor: cellProps => {
          return <>{(cellProps.levels && cellProps.levels[2]?.count) || "---"}</>
        },
      },
      {
        Header: props.t("COM L") + "4",
        accessor: "",
        disableFilters: true,
        accessor: cellProps => {
          return <>{(cellProps.levels && cellProps.levels[3]?.count) || "---"}</>
        },
      },
    
      {
        Header: props.t("Total Commission"),
        accessor: "totalComission",
        disableFilters: true,
        accessor: cellProps => {
          return <> {cellProps.totalComission ||"---"}</>
        },
      },
      {
        Header: props.t("Pending Commission"),
        accessor: "pendingComission",
        disableFilters: true,
        accessor: cellProps => {
          return <> {cellProps.pendingComission ||"---"}</>
        },
      },
      {
        Header: props.t("Monthly total purchases/ Minimum monthly purchases"),
        accessor: "",
        disableFilters: true,
        accessor: cellProps => {
          return <> {(cellProps.monthlyTotalPurchase)+ "/" +(cellProps.monthlyMinimumPurchase)||"---"}</>
        },
      },
    ],
    []
  )

  const toggle = () => {
    if (modal) {
      setModal(false)
      setPartnerStats(null)
    } else {
      setModal(true)
    }
  }
  const [activeTab, setActiveTab] = useState(moment().format("M"))

  //delete Business Partner
  const [deleteModal, setDeleteModal] = useState(false)
  const toggleTab = tab => {
    if (activeTab !== tab) {
      let now = new Date()
      let activeMonth = now.setMonth(tab - 1)
      let startDay = new Date(now.getFullYear(), now.getMonth(), 1)
      let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0)
      setFilter(prevFilter => { return { ...prevFilter, from: moment(startDay).format('Y-MM-DD'), to: moment(lastDay).format('Y-MM-DD') } })
      setActiveTab(tab)
    }
  }
  const onClickDelete = buisnessPartner => {
    setPartnerStats(buisnessPartner)
    setDeleteModal(true)
  }

  const handleDeleteCustomer = () => {
    if (partnerStats.firstname) {
      //dispatch(deleteBusinessPartner(buisnessPartner))
      setDeleteModal(false)
    }
  }

  const handleCustomerClicks = () => {
    setPartnerStats(null)
    setIsEdit(false)
    toggle()
  }
  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }
  const handleDateFromTo = date => {
    if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.startDate).add(1, "days").format("Y-MM-DD")
        }
      })
    }else {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.endDate).format("Y-MM-DD"),
        }
      })
    }
  }

  const handlePartner =(partner)=>{setFilter(prevFilter => {return {...prevFilter, partnerId:partner.value }})}

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Buisness Partners")}
            breadcrumbItem={props.t("Business Partner Stats")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <h1>2022</h1>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                  {months.map((month, index) => (
                      <NavItem key={index}>
                        <NavLink
                          className={classnames({
                            active: activeTab == index + 1,
                          })}
                          onClick={() => {
                            toggleTab(index + 1)
                          }}
                        >
                          {props.t(month)}
                        </NavLink>
                      </NavItem>
                    ))}
                  
                  </ul>

                  <div className="mt-4">
                      <TableContainer
                        columns={columns}
                        data={PartnerStatsData}
                        handelAddClick={handleCustomerClicks}
                        customPageSize={100}
                        setPageNumber={setPageNumber}
                        setLimit={handleLimit}
                        partners={BusinessPartnerData}
                        setPartner={handlePartner}
                        isPartnersFilter={true}
                        resetBPStatsOption={true}
                        // filterDate={true}
                        setDateFilter={handleDateFromTo}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        currentPage={currentPage}
                        total={total}
                        limit={limit}
                        className="custom-header-css"
                      />
                     </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

BusinessPartner.propTypes = {}

export default withTranslation()(BusinessPartner)
