
/**filter */
export const GET_CITY_BY_NAME_SUCCESS = "GET_CITY_BY_NAME_SUCCESS";
export const GET_CITY_BY_NAME_REQUEST = "GET_CITY_BY_NAME_REQUEST";
export const GET_CITY_BY_NAME_FAILED = "GET_CITY_BY_NAME_FAILED";

/**Get */
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';
export const GET_CITY_REQUEST = 'GET_CITY_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_CITY_FAILED = 'GET_CITY_FAILED';

/**Create */
export const CREATE_CITY_SUCCESS = 'CREATE_CITY_SUCCESS';
export const CREATE_CITY_FAILED = 'CREATE_CITY_FAILED';
export const CREATE_CITY_REQUEST = 'CREATE_CITY_REQUEST';
/**Delete */
export const DELETE_CITY_SUCCESS = 'DELETE_CITY_SUCCESS';
export const DELETE_CITY_FAILED = 'DELETE_CITY_FAILED';
export const DELETE_CITY_REQUEST = 'DELETE_CITY_REQUEST';
/**Update */
export const UPDATE_CITY_SUCCESS = 'UPDATE_CITY_SUCCESS';
export const UPDATE_CITY_FAILED = 'UPDATE_CITY_FAILED';
export const UPDATE_CITY_REQUEST = 'UPDATE_CITY_REQUEST';

export const CITY_MESSAGES_RESET = 'CITY_MESSAGES_RESET';
export const CITY_MESSAGES_SUCCESS = 'CITY_MESSAGES_SUCCESS';
export const RESET_CITY_MESSAGES_SUCCESS = 'RESET_CITY_MESSAGES_SUCCESS'
export const RESET_CITY_MESSAGES = 'RESET_CITY_MESSAGES'

