import {
    GET_LOG,
    GET_LOG_SUCCESS,
    GET_LOG_FAIL
  } from "./actionTypes"
  
  const INIT_STATE = {
    logsData: [],
    log: {},
    error: null,
    success: null,
    loading: false,
    nextPage: null,
    previousPage: null,
    limit: null,
    total: null,
    currentPage: null
  }
  
  const logs = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_LOG:
        return {
          ...state,
          loading: true,
          success: "",
          error: ""
        }
      case GET_LOG_SUCCESS:
       const { data, ...filters } = action.payload
        return {
          ...state,
          logsData: [...data],
         ...filters,
          loading: false
        }
      case GET_LOG_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        }
  
      default:
        return state
    }
  }
  
  export default logs
  