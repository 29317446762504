import React, { useEffect, useState } from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap"
import ReactSelect from "react-select"

import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { withTranslation } from "react-i18next"
import {
  createPayin,
  getMDMPayment,
  getRetailers,
  updatePayin,
} from "store/actions"

const PayInModal = props => {


  const { payin, isEdit, modal, toggle } = props
  const dispatch = useDispatch()
  const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
  const [retailer, setRetailer] = useState()
  const [balance, setBalance] = useState(0)
  const [paymentMethod, setPaymentMethod] = useState()
  const { paymentData } = useSelector(state => state.payments)
  const { retailersData } = useSelector(state => state.retailers)
  const { payinData } = useSelector(state => state.payin)
  useEffect(() => {
    dispatch(getMDMPayment({ limit: 1000, page: 1 }))
    dispatch(getRetailers({ limit: 10000, page: 1 }))
  }, [])

  useEffect(() => {
    if (payin?.id) {
      setRetailer({
        label: payin?.retailer?.firstname + " " + payin?.retailer?.lastname,
        value: payin?.retailerId,
      })
      setPaymentMethod({
        value: payin?.payinMethodId,
        label:
          (currentLanguage == "ar" && payin?.payinMethod?.nameAr) ||
          (currentLanguage == "en" && payin?.payinMethod?.nameEn) ||
          (currentLanguage == "fr" && payin?.payinMethod?.nameFr),
      })
      setBalance(payin.partner?.wallet?.balance || 0)
    }
  }, [payin])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      retailerId: (payin && payin.retailerId) || "",
      amount: (payin && payin.amount) || "",
      payinMethodId: (payin && payin.payinMethodId) || "",
      status: (payin && payin.status) || "paid",
      balance:
        (payin && payin?.retailer && payin.retailer?.wallet?.balance) || 0,
    },
    validationSchema: Yup.object({
      retailerId: Yup.string().required("Please select retailer"),
      amount: Yup.number()
        .typeError("Amount must be a number")
        // .moreThan(0, "Amount must be more than 0")
        .required("Please enter your amount"),
        // .when("balance", (balance, schema) => {
        //   return schema.test({
        //     test: amount => {
        //       if (!parseFloat(amount)) return true
        //       return parseFloat(amount) < parseFloat(balance)
        //     },
        //     message: "Amount is greater then this user's balance",
        //   })
        // }),
      payinMethodId: Yup.string().required("Please select your payment method"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatePayins = {
          id: payin.id,
          retailerId: values.retailerId,
          amount: values.amount,
          payinMethodId: values.payinMethodId,
        }
        dispatch(updatePayin(updatePayins))
        validation.resetForm()
        setRetailer()
        setBalance(0)
        setPaymentMethod()
        setIsEdit(false)
      } else {
        const newPayin = {
          retailerId: values["retailerId"],
          amount: parseInt(values["amount"]),
          payinMethodId: values["payinMethodId"],
          status: values["status"],
        }
        // console.log("+++++++++++++++++++++++++++++++++++++++++++++", newPayin)

        // save new user
        dispatch(createPayin(newPayin))
        validation.resetForm()
        setRetailer()
        setBalance(0)
        setPaymentMethod()
      }
      toggle(false)
    },
  })

  return (
    <Modal isOpen={modal} toggle={() => toggle(false)}>
      <ModalHeader toggle={() => toggle(false)} tag="h4">
        {!!isEdit ? props.t("Edit PayIn") : props.t("Add PayIn")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form="true">
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Retailer Name")}</Label>
                <ReactSelect
                  value={retailer}
                  onChange={el => {
                    setRetailer(el)
                    let balanceAmount = payinData.find(
                      data => data.retailerId == el.value
                    )?.retailer?.wallet?.balance
                    setBalance(
                      (balanceAmount != undefined && balanceAmount) || 0
                    )
                    validation.setFieldValue(
                      "balance",
                      (balanceAmount != undefined && balanceAmount) || 0
                    )
                    validation.setFieldValue("retailerId", el.value)
                  }}
                  onBlur={validation.handleBlur}
                  placeholder={props.t("select retailer...")}
                  options={retailersData.map(retailer => {
                    return {
                      value: retailer?.id,
                      label: retailer.firstname + " " + retailer.lastname,
                    }
                  })}
                  classNamePrefix="select2-selection"
                />
                {validation.errors.retailerId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.retailerId}
                  </FormFeedback>
                ) : null}
              </div>
              {retailer && balance != undefined && (
                <div className="mb-3">
                  <Label className="form-label">{props.t("Balance")}</Label>
                  <Input name="balance" type="text" value={balance} disabled />
                </div>
              )}

              <div className="mb-3">
                <Label className="form-label">{props.t("Amount")}</Label>
                <Input
                  name="amount"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.amount || ""}
                  invalid={
                    validation.touched.amount && validation.errors.amount
                      ? true
                      : false
                  }
                />
                {validation.touched.amount && validation.errors.amount ? (
                  <FormFeedback type="invalid">
                    {validation.errors.amount}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">
                  {props.t("Payment Method")}
                </Label>
                <ReactSelect
                  value={paymentMethod}
                  onChange={el => {
                    setPaymentMethod(el)
                    validation.setFieldValue("payinMethodId", el.value)
                  }}
                  onBlur={validation.handleBlur}
                  placeholder={props.t("select payment method...")}
                  options={paymentData.map(payment => {
                    return {
                      value: payment?.id,
                      label:
                        (currentLanguage == "ar" && payment?.nameAr) ||
                        (currentLanguage == "en" && payment?.nameEn) ||
                        (currentLanguage == "fr" && payment?.nameFr),
                    }
                  })}
                  classNamePrefix="select2-selection"
                />
                {validation.errors.paymentMethod ? (
                  <FormFeedback type="invalid">
                    {validation.errors.paymentMethod}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  disabled={
                    !(
                      validation.values?.retailerId &&
                      validation.values?.payinMethodId
                    )
                  }
                  type="submit"
                  className={`btn btn-${
                    isEdit ? "success" : "primary"
                  } save-user`}
                >
                  {isEdit ? props.t("Update") : props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(PayInModal)
