import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { checkPermission } from "helpers/functions"
import { useSelector } from "react-redux"

const SidebarContent = props => {
  const ref = useRef()
  const userRoles = useSelector(state => state.Login.user?.roles)

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {checkPermission(userRoles, ["User", "User", "List"]) &&
              <>
                <li className="menu-title active">{props.t("Menu")} </li>
                <li>
                  <Link to="/#">
                    <i className="bx bx-home-circle"></i>

                    <span>{props.t("Dashboards")}</span>
                  </Link>
                  {/* <ul className="sub-menu">
                <li>
                  <Link to="/dashboard-saas">{props.t("Saas")}</Link>
                </li>
              </ul> */}
                </li>
              </>
            }

            <li className="menu-title active">{props.t("Apps")}</li>

            {(checkPermission(userRoles, ["User", "User", "List"])
            || checkPermission(userRoles, ["User", "Role", "List"])
            ) &&
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="fas fa-users"></i>
                  <span>{props.t("Users")}</span>
                </Link>
                <ul className="sub-menu">
                  {/* <li>
                  <Link to="/contacts-grid">{props.t("User Grid")}</Link>
                </li> */}
                  {checkPermission(userRoles, ["User", "User", "List"]) &&
                    <li>
                      <Link to="/users-list">{props.t("User Management")}</Link>
                    </li>
                  }
                  {checkPermission(userRoles, ["User", "Role", "List"]) &&
                    <li>
                      <Link to="/roles-list">{props.t("Roles Management")}</Link>
                    </li>
                  }
                  {/* <li>
                  <Link to="/contacts-profile">{props.t("Profile")}</Link>
                </li> */}
                </ul>
              </li>}

            {(checkPermission(userRoles, ["Retailer", "Retailer", "List"])
              || checkPermission(userRoles, ["Retailer", "CommissionsIn", "List"])
              || checkPermission(userRoles, ["Retailer", "RetailerStats", "List"])) &&
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-store"></i>
                  <span>{props.t("Retailers")}</span>
                </Link>
                <ul className="sub-menu">
                  {checkPermission(userRoles, ["Retailer", "Retailer", "List"]) &&
                    <li>
                      <Link to="/retailers-list">{props.t("Retailer List")}</Link>
                    </li>
                  }
                  {checkPermission(userRoles, ["Retailer", "CommissionsIn", "List"]) &&
                    <li>
                      <Link to="/comissionin">{props.t("Commission In")}</Link>
                    </li>}
                  {checkPermission(userRoles, ["Retailer", "RetailerStats", "List"]) &&
                    <li>
                      <Link to="/retailerStats-list">
                        {props.t("Retailers Stats")}
                      </Link>
                    </li>}
                </ul>
              </li>}

            {(checkPermission(userRoles, ["Partner", "Partner", "List"])
              || checkPermission(userRoles, ["Partner", "PartnerStats", "List"])) &&
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-user"></i>
                  <span>{props.t("Buisness Partners")}</span>
                </Link>
                <ul className="sub-menu">
                  {checkPermission(userRoles, ["Partner", "Partner", "List"]) &&
                    <li>
                      <Link to="/businessPartners-list">
                        {props.t("Business Partner List")}
                      </Link>
                    </li>}
                  {checkPermission(userRoles, ["Partner", "PartnerStats", "List"]) &&
                    <li>
                      <Link to="/businessPartners-stats">
                        {props.t("Business Partner Stats")}
                      </Link>
                    </li>}
                </ul>
              </li>}

            {(checkPermission(userRoles, ["MasterData", "Brand", "List"])
              || checkPermission(userRoles, ["MasterData", "PaymentMethod", "List"])
              || checkPermission(userRoles, ["MasterData", "LevelCommission", "List"])
              || checkPermission(userRoles, ["MasterData", "Category", "List"])
              || checkPermission(userRoles, ["MasterData", "Country", "List"])
              || checkPermission(userRoles, ["MasterData", "Governorate", "List"])
              || checkPermission(userRoles, ["MasterData", "City", "List"])
              || checkPermission(userRoles, ["MasterData", "Settings", "List"])

            ) &&
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-door-open"></i>
                  <span>{props.t("Master Data")}</span>
                </Link>
                <ul className="sub-menu">
                  {checkPermission(userRoles, ["MasterData", "Brand", "List"]) &&
                    <li>
                      <Link to="/brands">
                        {props.t("Brand List")}
                      </Link>
                    </li>}
                  {checkPermission(userRoles, ["MasterData", "PaymentMethod", "List"]) &&
                    <li>
                      <Link to="/payment-method">
                        {props.t("Payment Method")}
                      </Link>
                    </li>}
                  {checkPermission(userRoles, ["MasterData", "LevelCommission", "List"]) && <li>
                    <Link to="/level-comission-list">
                      {props.t("Level Commission list")}
                    </Link>
                  </li>}
                  {checkPermission(userRoles, ["MasterData", "Category", "List"]) && <li>
                    <Link to="/categories-list">
                      {props.t("Categories List")}
                    </Link>
                  </li>}
                  {checkPermission(userRoles, ["MasterData", "Country", "List"]) && <li>
                    <Link to="/countries-list">{props.t("Countries List")}</Link>
                  </li>}
                  {checkPermission(userRoles, ["MasterData", "Governorate", "List"]) && <li>
                    <Link to="/governorates-list">
                      {props.t("Governorates List")}
                    </Link>
                  </li>}
                  {checkPermission(userRoles, ["MasterData", "City", "List"]) && <li>
                    <Link to="/cities-list">{props.t("Cities List")}</Link>
                  </li>}
                  {checkPermission(userRoles, ["MasterData", "Settings", "List"]) && <li>
                    <Link to="/setting">{props.t("Settings")}</Link>
                  </li>}
                </ul>
              </li>}
            {(checkPermission(userRoles, ["Accounting", "Payout", "List"]) 
            || checkPermission(userRoles, ["Accounting", "Payin", "List"])) && 
            <li>
              <Link to="/#" className="has-arrow">
                <i className="fas fa-money-bill-alt"></i>
                <span>{props.t("Accounting")}</span>
              </Link>
              <ul>
                {checkPermission(userRoles, ["Accounting", "Payout", "List"]) &&
                  <li>
                    <Link to="/payout">
                      {props.t("Payout")}
                    </Link>
                  </li>}
                {checkPermission(userRoles, ["Accounting", "Payin", "List"]) && <li>
                  <Link to="/payin">
                    {props.t("PayIn")}
                  </Link>
                </li>}
              </ul>
            </li>}
            {(checkPermission(userRoles, ["Ewallet", "Transaction", "List"])
              || checkPermission(userRoles, ["Ewallet", "Balance", "List"])
              || checkPermission(userRoles, ["Ewallet", "Statement", "List"])
              || checkPermission(userRoles, ["Ewallet", "UserEarning", "List"])
            )
              &&
              <li>
                <Link to="/e-wallet">
                  <i className="bx bxs-briefcase"></i>

                  <span>{props.t("Ewallet")}</span>
                </Link>
              </li>}
            {(checkPermission(userRoles, ["Transaction", "Transaction", "List"])
            ||checkPermission(userRoles, ["Transaction", "PartnerIncome", "List"])
            ||checkPermission(userRoles, ["Transaction", "TransactionIncome", "List"]) ) && 
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-transfer"></i>
                <span>{props.t("Transactions")}</span>
              </Link>
              <ul>
                {checkPermission(userRoles, ["Transaction", "Transaction", "List"]) &&
                  <li>
                    <Link to="/transactions-list">
                      {props.t("Transactions List")}
                    </Link>
                  </li>}
                {checkPermission(userRoles, ["Transaction", "PartnerIncome", "List"]) &&
                  <li>
                    <Link to="/partner-transactions-income">
                      {props.t("Partner Transactions Income")}
                    </Link>
                  </li>}
                {checkPermission(userRoles, ["Transaction", "TransactionIncome", "List"]) &&
                  <li>
                    <Link to="/transactions-income">
                      {props.t("Transactions Income")}
                    </Link>
                  </li>}
              </ul>
            </li>}
            {checkPermission(userRoles, ["Audit", "Log", "List"]) &&
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-file"></i>
                  <span>{props.t("Audit")}</span>
                </Link>
                <ul>
                  <li>
                    <Link to="/logs">
                      {props.t("Logs")}
                    </Link>
                  </li>
                </ul>
              </li>}

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
