
import {
    CITY_MESSAGES_RESET,
    CREATE_CITY_FAILED,
    CREATE_CITY_REQUEST,
    CREATE_CITY_SUCCESS,
    DELETE_CITY_FAILED,
    DELETE_CITY_REQUEST,
    DELETE_CITY_SUCCESS,
    GET_CITY_BY_NAME_FAILED,
    GET_CITY_BY_NAME_REQUEST,
    GET_CITY_BY_NAME_SUCCESS,
    GET_CITY_FAILED,
    GET_CITY_REQUEST,
    GET_CITY_SUCCESS,
    UPDATE_CITY_FAILED,
    UPDATE_CITY_REQUEST,
    RESET_CITY_MESSAGES,
    RESET_CITY_MESSAGES_SUCCESS,
    UPDATE_CITY_SUCCESS
} from "./actionTypes.js"

export const getCitiesByName = name => ({
    type: GET_CITY_BY_NAME_REQUEST,
    payload: name
})


export const getCitiesByNameSuccess = payload => ({
    type: GET_CITY_BY_NAME_SUCCESS,
    payload
})


export const getCitiesByNameFailed = payload => ({
    type: GET_CITY_BY_NAME_FAILED,
    payload
})




export const getMDMCities = (filter) => ({
    type: GET_CITY_REQUEST,
    payload:filter
})



export const getMDMCitiesSuccess = CITY => ({
    type: GET_CITY_SUCCESS,
    payload: CITY
})

export const getMDMCitiesFailed = payload => ({
    type: GET_CITY_FAILED,
    payload
})

export const createMDMCity = city => ({
    type: CREATE_CITY_REQUEST,
    payload: city
})

export const createMDMCitySuccess = city => ({
    type: CREATE_CITY_SUCCESS,
    payload: city
})

export const createMDMCityFailed = payload => ({
    type: CREATE_CITY_FAILED,
    payload
})

export const deleteMDMCity = id => ({
    type: DELETE_CITY_REQUEST,
    payload: id
})

export const deleteMDMCitySuccess = id => ({
    type: DELETE_CITY_SUCCESS,
    payload: id
})

export const deleteMDMCityFailed = id => ({
    type: DELETE_CITY_FAILED,
    payload: id
})

export const updateMDMCity = (city )=> ({
    type: UPDATE_CITY_REQUEST,
    payload: city
})

export const updateMDMCitySuccess = (city ) => ({
    type: UPDATE_CITY_SUCCESS,
    payload: city
})

export const updateMDMCityFailed = payload => ({
    type: UPDATE_CITY_FAILED,
    payload
})

export const resetMDMCityMessages = () => ({
    type: CITY_MESSAGES_RESET
}
)



export const resetCityMessages = () => ({
    type: RESET_CITY_MESSAGES,
  })
  
  export const resetCityMessagesSuccess = () => ({
    type: RESET_CITY_MESSAGES_SUCCESS,
  })
