import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"


// import { Permissions } from "./roletlistCol"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import SuccessModal from "components/Common/SuccessModal"

// import {
//   getUsers as onGetUsers,
//   deleteUser as onDeleteUser,
//   resetUsersMessages as onReset,
// } from "store/users/actions"

import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"
import { deleteRole, getRoles } from "store/actions"
import { withTranslation } from "react-i18next"
import TableContainer from "components/Common/TableContainer"
import { checkPermission } from "helpers/functions"
// import RoleAddEdit from "./role-add-edit"

const RolesList = props => {
  //meta title
  document.title = "Kasb"
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
  })
  const dispatch = useDispatch()
  const history = useHistory()
  const [role, setRole] = useState()
  const [message, setMessage] = useState("")
  const [deleteModal, setDeleteModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)

  const {
    roles,
    success,
    nextPage,
    previousPage,
    limit,
    total,
    currentPage,
    loading,
  } = useSelector(state => state.Roles)
  const userRoles = useSelector(state => state.Login.user?.roles)

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  // const [filter, setFilter] = useState(null)

  // console.log(filter)

  const columns = useMemo(
    () => [
      {
        Header: props.t("Name"),
        accessor: "name",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.name || props.t("Not provided")}</>
        },
      },
      // {
      //   Header: props.t("Permissions"),
      //   accessor: "permissions",
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     return <Permissions {...cellProps} />
      //   },
      // },
      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <div>
             {checkPermission(userRoles, ["User", "Role", "Update"])&& 
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const role = cellProps.row.original
                  // onClickChange(orderData)
                  //console.log("role",role)
                  handleEdit(role)
                }}
              >
                <i
                  className="mdi mdi-pencil font-size-18 me-3"
                  id="edittooltip"
                />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>}
              {cellProps?.row?.original?.admins=="" && checkPermission(userRoles, ["User", "Role", "Delete"]) &&
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDelete(orderData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
        }
            </div>
          )
        },
      },
    ],
    []
  )


 

  const handleEdit= (arg) => {

    props.history.push({pathname:(`/roles/${arg.id}`) })  
    //toggle()
   
  }
  useEffect(() => {
    dispatch(getRoles(filter))
  }, [dispatch, filter])

  useEffect(() => {
    setRole(roles)
    setIsEdit(false)
  }, [roles])

  useEffect(() => {
    if (!isEmpty(roles) && !!isEdit) {
      setRole(roles)
      setIsEdit(false)
    }
  }, [roles])

  
  const toggle = () => {
    setModal(!modal)
  }

  const handleRoleClick = arg => {
    const role = arg
    setRole({
      id: role.id,
      name: role.name,
      permissions: role.permissions,
    })
    setIsEdit(true)

    toggle()
  }

  //delete customer

  const onClickDelete = role => {
    setRole(role)
    setDeleteModal(true)
  }
// console.log(role,"role")
  const handleDeleteRole = () => {
    // if(!role.admins){
    dispatch(deleteRole(role?.id))
    setDeleteModal(false)
    // }
  }

  const handleRoleClicks = () => {
    setRole()
    setIsEdit(false)
    toggle()
  }

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteRole}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={props.t("Roles")}
            breadcrumbItem={props.t("Role List")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {checkPermission(userRoles, ["User", "Role", "Create"]) && 
                  <Col>
                    <div className="text-sm-end">
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded mb-2 me-2"
                       
                        onClick={() => history.push(`/roles`)}
                      >
                        <i className="mdi mdi-plus me-1" />
                        {props.t("Add")}
                      </Button>
                    </div>
                  </Col>}
                  <TableContainer
                    columns={columns}
                    data={roles}
                    loading={loading}
                    success={success}
                    handelAddClick={handleRoleClicks}
                    handleUserClick={handleRoleClicks}
                    customPageSize={100}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    className="custom-header-css"
                  />

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

RolesList.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(RolesList))
