import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import "react-datepicker/dist/react-datepicker.css"
import { Button, Col, TabPane } from "reactstrap"

import { withTranslation } from "react-i18next"
import TableContainer from "../../../components/Common/TableContainer"
import { Link } from "react-router-dom"
//redux
import { useSelector, useDispatch } from "react-redux"
import * as moment from "moment"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import { getPayout, updatePayout } from "store/actions"
import { resetUsersMessages as onReset } from "store/users/actions"
import ConfirmModalPaid from "components/Common/ConfirmModalPaid"

import PayoutPaidPrint from "./PayoutPaidPrint"
import ErrorModal from "components/Common/ErrorModal"
import SuccessModal from "components/Common/SuccessModal"
import { checkPermission } from "helpers/functions"

const PaymentProcess = props => {
  //meta title
  document.title = "Kasb"

  const [filter, setFilter] = useState({
    partnerId: "",
    from: "",
    to: "",
    status: "",
    page: 1,
    limit: 100,
  })

  const {
    payoutData,
    success,
    error,
    nextPage,
    previousPage,
    currentPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.payout)
  const userRoles = useSelector(state => state.Login.user?.roles)
  const [confirm2Modal, setConfirm2Modal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [payoutPrintModal, setPayoutPrintModal] = useState(false)
  const [payoutPaimentEntity, setPayoutPaimentEntity] = useState({})
  const [message, setMessage] = useState("")

  const [isEdit, setIsEdit] = useState(false)

  const [payout, setPayout] = useState(null)
  const [processPayment, setProcessPayment] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPayout(filter))
  }, [dispatch, filter])

  useEffect(() => {
    if (payoutData.length > 0) {
      setProcessPayment(
        payoutData.filter(
          data => data.status != "rejected" && data.status != "pending"
        )
      )
    }
  }, [payoutData])

  const handleChange = (event, data) => {
    if (event.target.checked) {
      setPayout(data)
    } else {
      setPayout(null)
    }
  }

  const columns2 = useMemo(
    () => [
      {
        Header: "#",
        accessor: cellProps => {
          return (
            cellProps.status == "released" && (
              <input
                type="radio"
                name="pay"
                onChange={e => handleChange(e, cellProps)}
              />
            )
          )
        },
      },
      {
        Header: props.t("Member Name"),
        accessor: "name",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {" "}
              {cellProps.partner?.firstname +
                " " +
                cellProps.partner?.lastname +
                " " || props.t("Not provided")}
            </>
          )
        },
      },

      {
        Header: props.t("Amount"),
        accessor: "amount",
        filterable: true,
        accessor: cellProps => {
          return <>{cellProps.amount || 0}</>
        },
      },

      {
        Header: props.t("Approval Date"),
        accessor: "releasedAt",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {" "}
              {(cellProps.releasedAt &&
                moment(new Date(cellProps.releasedAt)).format(
                  "YYYY-MM-DD HH:mm"
                )) ||
                props.t("------")}
            </>
          )
        },
      },
      {
        Header: props.t("Approved by"),
        accessor: "releasedBy",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.releasedBy &&
                cellProps.releasedBy?.firstname +
                  " " +
                  cellProps.releasedBy?.lastname) ||
                props.t("------")}
            </>
          )
        },
      },
      {
        Header: props.t("Status"),
        accessor: "status",
        filterable: true,
        accessor: cellProps => {
          return <>{cellProps.status || props.t("------")}</>
        },
      },
      {
        Header: props.t("Paiement date"),
        accessor: "paidAt",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {" "}
              {(cellProps.paidAt &&
                moment(new Date(cellProps.paidAt)).format(
                  "YYYY-MM-DD HH:mm"
                )) ||
                props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Payed by"),
        accessor: "paidBy",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.paidBy &&
                cellProps.paidBy?.firstname +
                  " " +
                  cellProps.paidBy?.lastname) ||
                props.t("------")}
            </>
          )
        },
      },
      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <>
              <div className="d-flex ">
                {checkPermission(userRoles, ["Accounting", "Payout", "PrintProcessPayment"]) && 
                <Link
                  to="#"
                  onClick={() => {
                    let payoutPaiement = cellProps.row.original
                    handlePayoutPrintClick(payoutPaiement)
                  }}
                  className="text-success "
                >
                  <i className="fa fa-print font-size-15" />
                </Link>}
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    // console.log("success", success)
    if (success === "CREATE") {
      setSuccessModal(true)
      setMessage("Payout created successfully !")
      dispatch(onReset())
    } else if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("Payout updated successfully !")
      dispatch(onReset())
    }
  }, [success])
  useEffect(() => {
    if (error === "FAILED UPDATE") {
      setErrorModal(true)
      setMessage("Insufisant balance")
    }
  }, [error])

  const changeStatusPaid = () => {
    //setPayout(payout)
    if (payout.id) {
      if (payout.status == "released") {
        if (payout.releasedAmount != null) {
          dispatch(updatePayout({ id: payout.id, status: "paid" }))
          setConfirm2Modal(false)
          dispatch(getPayout())
        } else {
          dispatch(
            updatePayout({
              id: payout.id,
              releasedAmount: payout.amount,
              status: "paid",
            })
          )
          setConfirm2Modal(false)
          dispatch(getPayout())
        }
      }
    }
  }

  const onClickChangeButton = () => {
    // setPayout(payout)
    if(payout.id){
    setConfirm2Modal(true)
    }
  }

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }

  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  const handlePayoutPrintClick = payout => {
    // console.log("payout", payout)
    setPayoutPaimentEntity(payout)
    setIsEdit(true)
    setPayoutPrintModal(true)
  }
  const handleDateFromTo = date => {
    if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.startDate).add(1, "days").format("Y-MM-DD"),
        }
      })
    } else {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.endDate).format("Y-MM-DD"),
        }
      })
    }
  }
  return (
    <React.Fragment>
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <ConfirmModalPaid
        show={confirm2Modal}
        onUpdateClick={changeStatusPaid}
        onCloseClick={() => setConfirm2Modal(false)}
      />
      <ErrorModal
        show={errorModal}
        onCloseClick={() => setErrorModal(false)}
        message={message}
      />
      <TabPane tabId="3" id="processing">
        <div>
          {checkPermission(userRoles, ["Accounting", "Payout", "PayProcessPayment"]) && 
          <Col>
            <div>
              <Button
                type="button"
                color="info"
                className="btn mb-2 me-2"
                onClick={payout => onClickChangeButton(payout)}
                // disabled={isDisabled}
              >
              {props.t("Pay")}
              </Button>
            </div>
          </Col>}
          <TableContainer
            columns={columns2}
            data={processPayment}
            setDateFilter={handleDateFromTo}
            // ActionPay={true}
            filterDate={true}
            customPageSize={100}
            setPageNumber={setPageNumber}
            setLimit={handleLimit}
            nextPage={nextPage}
            previousPage={previousPage}
            currentPage={currentPage}
            total={total}
            limit={limit}
            loading={loading}
            success={success}
          />
        </div>
      </TabPane>

      <PayoutPaidPrint
        modal={payoutPrintModal}
        payout={payoutPaimentEntity}
        isEdit={isEdit}
        toggle={setPayoutPrintModal}
      />
    </React.Fragment>
  )
}

PaymentProcess.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(PaymentProcess)
