import {
    CREATE_LEVEL,
    CREATE_LEVEL_FAIL,
    CREATE_LEVEL_SUCCESS,
    DELETE_LEVEL,
    DELETE_LEVEL_FAIL,
    DELETE_LEVEL_SUCCESS,
    GET_LEVELS,
    GET_LEVELS_FAIL,
    GET_LEVELS_SUCCESS,
    RESET_MESSAGES,
    UPDATE_LEVEL,
    RESET_LEVEL_MESSAGES_SUCCESS,
    RESET_LEVEL_MESSAGES,
    UPDATE_LEVEL_FAIL,
    UPDATE_LEVEL_SUCCESS
} from "./actionTypes"

export const getLevels = (filter) => ({
    type: GET_LEVELS,
    payload: filter,
})


export const getLevelsSuccess = levels => ({
    type: GET_LEVELS_SUCCESS,
    payload: levels,
})

export const getLevelsFaild = levels => ({
    type: GET_LEVELS_FAIL,
    payload: levels,
})

export const createLevel = level => ({
    type: CREATE_LEVEL,
    payload: level,
})

export const createlevelSuccess = level => ({
    type: CREATE_LEVEL_SUCCESS,
    payload: level,
})

export const createlevelFail = error => ({
    type: CREATE_LEVEL_FAIL,
    payload: error,
})



export const updateLevel = level => ({
    type: UPDATE_LEVEL,
    payload: level,
})

export const updatelevelSuccess = level => ({
    type: UPDATE_LEVEL_SUCCESS,
    payload: level,
})

export const updatelevelFail = error => ({
    type: UPDATE_LEVEL_FAIL,
    payload: error,
})

export const deleteLevel = id => ({
    type: DELETE_LEVEL,
    payload: id,
})

export const deleteLevelSuccess = id => ({
    type: DELETE_LEVEL_SUCCESS,
    payload: id,
})

export const deleteLevelFail = error => ({
    type: DELETE_LEVEL_FAIL,
    payload: error,
})

export const resetLevelsMessages = () => ({
    type: RESET_MESSAGES,
})

export const resetLevelMessages = () => ({
    type: RESET_LEVEL_MESSAGES,
  })
  
  export const resetLevelMessagesSuccess = () => ({
    type: RESET_LEVEL_MESSAGES_SUCCESS,
  })
