import React, { useEffect, useState, useMemo } from "react"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"

import { withTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteModal from "../../../components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "../../../components/Common/TableContainer"

// Column
import { UserName, ArabicName, EnName } from "./CitiesCol"
import {
  getMDMCities,
  getMDMGovernorates,
  resetCityMessages,
  updateMDMCity,
} from "store/actions"
import SuccessModal from "components/Common/SuccessModal"
import { checkPermission } from "helpers/functions"
import CityModal from "./CityModal"

const City = props => {
  const {
    citiesData,
    error,
    success,
    nextPage,
    previousPage,
    limit,
    total,
    currentPage,
    loading,
  } = useSelector(state => state.cities)
 
  const userRoles = useSelector(state => state.Login.user?.roles)

  //meta title
  document.title = "Kasb"

  const dispatch = useDispatch()

  const [modal, setModal] = useState(false)
  const [cityList, setCityList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [City, setCity] = useState(null)
  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState("")
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
  })
  // validation

  const handleCustomerClick = arg => {
    const City = arg

    setCity({
      id: City.id,
      nameEn: City.nameEn,
      nameFr: City.nameFr,
      nameAr: City.nameAr,
      parentId: City.parentId,
    })

    setIsEdit(true)
    toggle()
  }

  // Countries Column
  const columns = useMemo(
    () => [
      {
        Header: props.t("English Name"),
        accessor: "nameEn",
        disableFilters: true,
        Cell: cellProps => {
          return <EnName {...cellProps} />
        },
      },
      {
        Header: props.t("French Name"),
        accessor: "nameFr",
        disableFilters: true,
        Cell: cellProps => {
          return <UserName {...cellProps} />
        },
      },
      {
        Header: props.t("Arabic Name"),
        accessor: "nameAr",
        disableFilters: true,
        Cell: cellProps => {
          return <ArabicName {...cellProps} />
        },
      },
      {
        Header: props.t("Governorate"),
        accessor: "parent",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.parent?.nameAr || "not provided"}</>
        },
      },

      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {checkPermission(userRoles, ["MasterData", "City", "Update"]) && (
                <Link
                  to="#"
                  className="text-tip"
                  onClick={() => {
                    const cityData = cellProps.row.original
                    handleCustomerClick(cityData)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              )}
              {checkPermission(userRoles, ["MasterData", "City", "Delete"]) && (
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const cityData = cellProps.row.original
                    onClickDelete(cityData)
                  }}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              )}
            </div>
          )
        },
      },
    ],
    []
  )

  const toggle = () => {
    if (modal) {
      setModal(false)
      setCity(null)
    } else {
      setModal(true)
    }
  }

  useEffect(() => {
    if (success === "DELETE") {
      setSuccessModal(true)
      setMessage("City deleted successfully !")
      dispatch(resetCityMessages())
    }
    if (success === "CREATE") {
      setSuccessModal(true)
      setMessage("City created successfully !")
      dispatch(resetCityMessages())
    } else if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("successfully !")
      dispatch(resetCityMessages())
    }
  }, [success])

  //delete
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = City => {
    setCity(City)
    setDeleteModal(true)
  }

  const handleDeleteCity = () => {
    if (City.id) {
      dispatch(updateMDMCity({ id: City.id, status: "archived" }))
      setDeleteModal(false)
      dispatch(getMDMCities())
    }
  }

  useEffect(() => {
    {
      dispatch(getMDMCities(filter))
      dispatch(getMDMGovernorates())
    }
  }, [dispatch, filter])

  useEffect(() => {
    setCityList(citiesData)
  }, [citiesData])

  useEffect(() => {
    if (!isEmpty(citiesData)) {
      setCityList(citiesData)
    }
  }, [citiesData])

  const handleCityClicks = () => {
    setCityList("")
    setIsEdit(false)
    toggle()
  }
  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCity}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("MDM")}
            breadcrumbItem={props.t("List Cities")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={citiesData}
                    //isGlobalFilter={true}
                    isAddCustList={checkPermission(userRoles, [
                      "MasterData",
                      "City",
                      "Create",
                    ])}
                    handelAddClick={handleCityClicks}
                    customPageSize={100}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    setLimit={handleLimit}
                    limit={limit}
                    total={total}
                    currentPage={currentPage}
                    setPageNumber={setPageNumber}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                  <CityModal
                    modal={modal}
                    toggle={toggle}
                    isEdit={isEdit}
                    City={City}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(City)
