import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"

//redux
import { useDispatch, useSelector } from "react-redux"
import { Col, Row, Label } from "reactstrap"
import { withTranslation } from "react-i18next"
import { updateConfigs } from "store/actions"
import ReactSwitch from "react-switch"
import { checkPermission } from "helpers/functions"
import SuccessModal from "components/Common/SuccessModal"

function Referral(props) {
  //meta title
  document.title = "Kasb"

  const dispatch = useDispatch()
  const [referral, setReferral] = useState(null)
  const { configs, error, success } = useSelector(state => state.settings)
  const userRoles = useSelector(state => state.Login.user?.roles)
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState('');
  useEffect(() => {
    if (configs.length > 0) {
      setReferral(configs.find(el => el.key == "referral_required"))
    }
  }, [configs])



//   useEffect(() => {
//     console.log("configs",configs.value)
//     if (success == "UPDATE") {
//       setShowMessage(true)
//     }
//     if (error == "UPDATE") {

//     }
//   }, [success, error])



  const ChangeStatus = status => {
    if (status) {
      dispatch(updateConfigs({ id: referral?.id, value: "true" }))
      setShowMessage(true)
      setMessage(props.t("Referral code becomes mandatory!"))
    } else {
      dispatch(updateConfigs({ id: referral?.id, value: "false" }))
      setShowMessage(true)
      setMessage(props.t("Referral code becomes non-mandatory"))
    }
  }

  return (
    <React.Fragment>
      <SuccessModal
        show={showMessage}
        onCloseClick={() => setShowMessage(false)}
        message={message}
      />
      <Row form>
        <Col className="col-lg-4">
          <div className="mb-3">
            <Label className="form-label">{props.t("Referral code required")}</Label>
            <Row>
              <ReactSwitch
                disabled={
                  !checkPermission(userRoles, [
                    "MasterData",
                    "Settings",
                    "UpdateReferralCode",
                  ])
                }
                onColor="#34c38f"
                onChange={ChangeStatus}
                checked={referral?.value == "false" ? false : true}
              />
            </Row>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}
Referral.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(Referral)
