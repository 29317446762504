/* TRANSACTIONS */
export const GET_TRANSACTIONS = "GET_TRANSACTIONS"
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS"
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL"
/* TRANSACTIONS INCOME*/
export const GET_TRANSACTIONSINCOME = "GET_TRANSACTIONSINCOME"
export const GET_TRANSACTIONSINCOME_SUCCESS = "GET_TRANSACTIONSINCOME_SUCCESS"
export const GET_TRANSACTIONSINCOME_FAIL = "GET_TRANSACTIONSINCOME_FAIL"

/* TRANSACTIONS INCOME*/
export const GET_TRANSACTIONS_PARTNER = "GET_TRANSACTIONS_PARTNER"
export const GET_TRANSACTIONS_PARTNER_SUCCESS = "GET_TRANSACTIONS_PARTNER_SUCCESS"
export const GET_TRANSACTIONS_PARTNER_FAIL = "GET_TRANSACTIONS_PARTNER_FAIL"

export const GET_TRANSACTIONSINCOME_STATS = "GET_TRANSACTIONSINCOME_STATS"
export const GET_TRANSACTIONSINCOME_FAIL_STATS = "GET_TRANSACTIONSINCOME_FAIL_STATS"
export const GET_TRANSACTIONSINCOME_SUCCESS_STATS = "GET_TRANSACTIONSINCOME_SUCCESS_STATS"
export const GET_TRANSACTIONS_PARTNER_STATS = "GET_TRANSACTIONS_PARTNER_STATS"
export const GET_TRANSACTIONS_PARTNER_STATS_SUCCESS = "GET_TRANSACTIONS_PARTNER_STATS_SUCCESS"
export const GET_TRANSACTIONS_PARTNER_STATS_FAIL = "GET_TRANSACTIONS_PARTNER_STATS_FAIL"



// /**
//  * add TRANSACTIONS
//  */
// export const ADD_NEW_TRANSACTION = "ADD_NEW_TRANSACTION"
// export const ADD_TRANSACTION_SUCCESS = "ADD_TRANSACTION_SUCCESS"
// export const ADD_TRANSACTION_FAIL = "ADD_TRANSACTION_FAIL"

// /**
//  * Edit TRANSACTION
//  */
// export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION"
// export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS"
// export const UPDATE_TRANSACTION_FAIL = "UPDATE_TRANSACTION_FAIL"

// /**
//  * Delete TRANSACTION
//  */
// export const DELETE_TRANSACTION = "DELETE_TRANSACTION"
// export const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS"
// export const DELETE_TRANSACTION_FAIL = "DELETE_TRANSACTION_FAIL"

// /** Reset Messages */
// export const RESET_TRANSACTIONS_MESSAGES = "RESET_TRANSACTIONS_MESSAGES"
// export const RESET_TRANSACTIONS_MESSAGES_SUCCESS = "RESET_TRANSACTIONS_MESSAGES_SUCCESS"
