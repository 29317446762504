
/**filter */
export const GET_COUNTRY_BY_NAME_SUCCESS = "GET_COUNTRY_BY_NAME_SUCCESS";
export const GET_COUNTRY_BY_NAME_REQUEST = "GET_COUNTRY_BY_NAME_REQUEST";
export const GET_COUNTRY_BY_NAME_FAILED = "GET_COUNTRY_BY_NAME_FAILED";

/**Get */
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_COUNTRY_FAILED = 'GET_COUNTRY_FAILED';

/**Create */
export const CREATE_COUNTRY_SUCCESS = 'CREATE_COUNTRY_SUCCESS';
export const CREATE_COUNTRY_FAILED = 'CREATE_COUNTRY_FAILED';
export const CREATE_COUNTRY_REQUEST = 'CREATE_COUNTRY_REQUEST';
/**Delete */
export const DELETE_COUNTRY_SUCCESS = 'DELETE_COUNTRY_SUCCESS';
export const DELETE_COUNTRY_FAILED = 'DELETE_COUNTRY_FAILED';
export const DELETE_COUNTRY_REQUEST = 'DELETE_COUNTRY_REQUEST';
/**Update */
export const UPDATE_COUNTRY_SUCCESS = 'UPDATE_COUNTRY_SUCCESS';
export const UPDATE_COUNTRY_FAILED = 'UPDATE_COUNTRY_FAILED';
export const UPDATE_COUNTRY_REQUEST = 'UPDATE_COUNTRY_REQUEST';

export const COUNTRY_MESSAGES_RESET = 'COUNTRY_MESSAGES_RESET';
export const COUNTRY_MESSAGES_SUCCESS = 'COUNTRY_MESSAGES_SUCCESS';
export const RESET_COUNTRY_MESSAGES_SUCCESS = 'RESET_COUNTRY_MESSAGES_SUCCESS';
export const RESET_COUNTRY_MESSAGES = 'RESET_COUNTRY_MESSAGES';

