export const checkPermission = (userRoles, checkList) => {
    let authorized = false
    userRoles?.map(role => {
        role.permissions?.map(element => {
            let permissionArray = element.name.split(".")
            if (checkList?.every(value => {return permissionArray?.includes(value)})) {
                authorized = true
            }
        })
    })
    return authorized
}


export function reduceArray(array) {
	const permissions = [];
	const modules = [];

	if (array) {
		array.map(el => {
			const module = el.name.split('.')[0];
			if (!modules.includes(module)) {
				modules.push(module);
			}
		});
		modules.map((module, i) => {
			permissions.push({
				module,
				[module]: []
			});
			const models = [];
			return array.map(el => {
				const element = el.name.split('.');
				if (module === element[0]) {
					const ta = permissions[i];
					const model = element[1];

					if (!models.includes(model)) {
						models.push(model);
						ta[module].push({
							model,
							[model]: [{ id: el.id, action: element[2] }]
						});
					} else {
						ta[module].map(lo => {
							if (lo[model]) {
								lo[model].push({ id: el.id, action: element[2] });
							}
						});
					}
				}
			});
		});
	}
	return permissions;
}