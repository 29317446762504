import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
  GET_TRANSACTIONSINCOME,
  GET_TRANSACTIONSINCOME_SUCCESS,
  GET_TRANSACTIONSINCOME_FAIL,
  GET_TRANSACTIONS_PARTNER_SUCCESS,
  GET_TRANSACTIONS_PARTNER,
  GET_TRANSACTIONS_PARTNER_FAIL,
  GET_TRANSACTIONSINCOME_STATS,
  GET_TRANSACTIONSINCOME_FAIL_STATS,
  GET_TRANSACTIONSINCOME_SUCCESS_STATS,
  GET_TRANSACTIONS_PARTNER_STATS,
  GET_TRANSACTIONS_PARTNER_STATS_SUCCESS,
  GET_TRANSACTIONS_PARTNER_STATS_FAIL
} from "./actionTypes"

const INIT_STATE = {
  transactionsData: [],
  transactionIncomeData:[],
  partnerTransactionsData:[],
  transaction: {},
  transactionIncomeStats: {},
  partnerTransactionsStats: {},
  error: null,
  success: null,
  loading: false,
  nextPage: null,
  previousPage: null,
  limit: null,
  total: null,
  currentPage: null
}

const transactions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case GET_TRANSACTIONS_SUCCESS:
     let { data, ...filters } = action.payload
      return {
        ...state,
        transactionsData: [...data],
       ...filters,
        loading: false
      }
    case GET_TRANSACTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_TRANSACTIONSINCOME:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case GET_TRANSACTIONSINCOME_SUCCESS:
     const { incomeData, incomeFilters } = action.payload
      return {
        ...state,
        transactionIncomeData: [...incomeData],
       ...incomeFilters,
        loading: false
      }
    case GET_TRANSACTIONSINCOME_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_TRANSACTIONSINCOME_STATS:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case GET_TRANSACTIONSINCOME_SUCCESS_STATS:

      return {
        ...state,
        transactionIncomeStats: action.payload,
        loading: false
      }
    case GET_TRANSACTIONSINCOME_FAIL_STATS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

      case GET_TRANSACTIONS_PARTNER:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case GET_TRANSACTIONS_PARTNER_SUCCESS:
     const { incomePartner, partnerFilters } = action.payload
      return {
        ...state,
        partnerTransactionsData: [...incomePartner],
       ...partnerFilters,
        loading: false
      }
    case GET_TRANSACTIONS_PARTNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
      case GET_TRANSACTIONS_PARTNER_STATS:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case GET_TRANSACTIONS_PARTNER_STATS_SUCCESS:
    
      return {
        ...state,
        partnerTransactionsStats:  action.payload,
        loading: false
      }
    case GET_TRANSACTIONS_PARTNER_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default transactions
