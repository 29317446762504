import {
    GOVERNORATE_MESSAGES_RESET,
    RESET_GOVERNORATE_MESSAGES_SUCCESS,
    CREATE_GOVERNORATE_FAILED,
    CREATE_GOVERNORATE_REQUEST,
    CREATE_GOVERNORATE_SUCCESS,
    DELETE_GOVERNORATE_FAILED,
    DELETE_GOVERNORATE_REQUEST,
    DELETE_GOVERNORATE_SUCCESS,
    GET_GOVERNORATE_BY_NAME_FAILED,
    GET_GOVERNORATE_BY_NAME_REQUEST,
    GET_GOVERNORATE_BY_NAME_SUCCESS,
    GET_GOVERNORATE_FAILED,
    GET_GOVERNORATE_REQUEST,
    GET_GOVERNORATE_SUCCESS,
    UPDATE_GOVERNORATE_FAILED,
    UPDATE_GOVERNORATE_REQUEST,
    UPDATE_GOVERNORATE_SUCCESS,
  } from "./actionTypes"
  
  export const initialState = {
    loading: false,
    governoratesData: [],
    success: "",
    error: "",
  }
  
  function reducer(state = initialState, action) {
    switch (action.type) {
      case GET_GOVERNORATE_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_GOVERNORATE_SUCCESS:
        const {data, ...filters} = action.payload
        return {
          ...state,
          governoratesData: [...data],
          ...filters,
          loading: false,
          success: "GET",
        }
      case GET_GOVERNORATE_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case GET_GOVERNORATE_BY_NAME_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_GOVERNORATE_BY_NAME_SUCCESS:
        return {
          ...state,
          governoratesData: action.payload,
          loading: false,
          success: "SUCCESS",
        }
      case GET_GOVERNORATE_BY_NAME_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case CREATE_GOVERNORATE_REQUEST:
        return {
          ...state,
          loading: true,
        }
      case CREATE_GOVERNORATE_SUCCESS:
        return {
          ...state,
          governoratesData: [...state.governoratesData, action.payload],
          success: "CREATE",
          loading: false,
        }
      case CREATE_GOVERNORATE_FAILED:
        return {
          ...state,
          error: "FAILED CREATE",
          loading: false,
        }
      case DELETE_GOVERNORATE_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case DELETE_GOVERNORATE_SUCCESS:
        return {
          ...state,
          governoratesData: state.governoratesData.filter(
            governorate => governorate.id.toString() !== action.payload.toString()
          ),
          success: "DELETE",
          loading: false,
        }
      case DELETE_GOVERNORATE_FAILED:
        return {
          ...state,
          error: "FAILED DELETE",
          loading: false,
        }
      case UPDATE_GOVERNORATE_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case UPDATE_GOVERNORATE_SUCCESS:
        return {
          ...state,
          governoratesData: state.governoratesData.map(governorate =>
            governorate.id.toString() === action.payload.id.toString()
              ? { governorate, ...action.payload }
              : governorate
          ),
          success: "UPDATE",
          loading: false,
        }
      case UPDATE_GOVERNORATE_FAILED:
        return {
          ...state,
          error: "FAILED UPDATE",
          loading: false,
        }
      case GOVERNORATE_MESSAGES_RESET:
        return {
          ...state,
          error: "",
          success: "",
          loading: false,
        }
        case RESET_GOVERNORATE_MESSAGES_SUCCESS:
          return {
            ...state,
            error: null,
            success: null
          }
      default:
        return state
    }
  }
  
  export default reducer
  
