export const ActionTypes = {
    GET_BUSINESS_PARTNER_BY_NAME_SUCCESS: 'GET_BUSINESS_PARTNER_BY_NAME_SUCCESS',
    GET_BUSINESS_PARTNER_BY_NAME_REQUEST: 'GET_BUSINESS_PARTNER_BY_NAME_REQUEST',
    GET_BUSINESS_PARTNER_BY_NAME_FAILED: 'GET_BUSINESS_PARTNER_BY_NAME_FAILED',
    GET_BUSINESS_PARTNER_SUCCESS: 'GET_BUSINESS_PARTNER_SUCCESS',
    GET_BUSINESS_PARTNER_REQUEST: 'GET_BUSINESS_PARTNER_REQUEST',
    GET_BUSINESS_PARTNER_FAILED: 'GET_BUSINESS_PARTNER_FAILED',
    GET_PARTNER_STATS_SUCCESS: 'GET_PARTNER_STATS_SUCCESS',
    GET_PARTNER_STATS_REQUEST: 'GET_PARTNER_STATS_REQUEST',
    GET_PARTNER_STATS_FAILED: 'GET_PARTNER_STATS_FAILED',
    GET_BUSINESS_PARTNER_STATS_SUCCESS: 'GET_BUSINESS_PARTNER_STATS_SUCCESS',
    GET_BUSINESS_PARTNER_STATS_REQUEST: 'GET_BUSINESS_PARTNER_STATS_REQUEST',
    GET_BUSINESS_PARTNER_STATS_FAILED: 'GET_BUSINESS_PARTNER_STATS_FAILED',
    CREATE_BUSINESS_PARTNER_REQUEST: 'CREATE_BUSINESS_PARTNER_REQUEST',
    CREATE_BUSINESS_PARTNER_SUCCESS: 'CREATE_BUSINESS_PARTNER_SUCCESS',
    CREATE_BUSINESS_PARTNER_FAILED: 'CREATE_BUSINESS_PARTNER_FAILED',
    DELETE_BUSINESS_PARTNER_SUCCESS: 'DELETE_BUSINESS_PARTNER_SUCCESS',
    DELETE_BUSINESS_PARTNER_FAILED: 'DELETE_BUSINESS_PARTNER_FAILED',
    DELETE_BUSINESS_PARTNER_REQUEST: 'DELETE_BUSINESS_PARTNER_REQUEST',
    UPDATE_BUSINESS_PARTNER_SUCCESS: 'UPDATE_BUSINESS_PARTNER_SUCCESS',
    UPDATE_BUSINESS_PARTNER_FAILED: 'UPDATE_BUSINESS_PARTNER_FAILED',
    UPDATE_BUSINESS_PARTNER_REQUEST: 'UPDATE_BUSINESS_PARTNER_REQUEST',
    BUSINESS_PARTNER_MESSAGES_RESET: 'BUSINESS_PARTNER_MESSAGES_RESET',
    BUSINESS_PARTNER_MESSAGES_SUCCESS: 'BUSINESS_PARTNER_MESSAGES_SUCCESS',

};






export function getBusinessPartner(filter) {
    return { type: ActionTypes.GET_BUSINESS_PARTNER_REQUEST,
    payload:filter};
}

export function getBusinessPartnerSuccess(orders) {
   
    return { type: ActionTypes.GET_BUSINESS_PARTNER_SUCCESS, payload:orders };
}

export function getBusinessPartnerFailed(payload) {
   
    return { type: ActionTypes.GET_BUSINESS_PARTNER_FAILED, payload };
}
export function getPartnerStats() {
    return { type: ActionTypes.GET_PARTNER_STATS_REQUEST};
}

export function getPartnerStatsSuccess(orders) {
   
    return { type: ActionTypes.GET_PARTNER_STATS_SUCCESS, payload:orders };
}

export function getPartnerStatsFailed(payload) {
   
    return { type: ActionTypes.GET_PARTNER_STATS_FAILED, payload };
}
export function getBusinessPartnerStats(filter) {
    return { type: ActionTypes.GET_BUSINESS_PARTNER_STATS_REQUEST,
    payload:filter};
}

export function getBusinessPartnerStatsSuccess(orders) {
   
    return { type: ActionTypes.GET_BUSINESS_PARTNER_STATS_SUCCESS, payload:orders };
}

export function getBusinessPartnerStatsFailed(payload) {
   
    return { type: ActionTypes.GET_BUSINESS_PARTNER_STATS_FAILED, payload };
}

export function createBusinessPartner(BusinessPartner) {
    return { type: ActionTypes.CREATE_BUSINESS_PARTNER_REQUEST, payload: BusinessPartner};
}

export function createBusinessPartnerSuccess(BusinessPartner) {
   
    return { type: ActionTypes.CREATE_BUSINESS_PARTNER_SUCCESS, payload: BusinessPartner };
}

export function createBusinessPartnerFailed(payload) {
   
    return { type: ActionTypes.CREATE_BUSINESS_PARTNER_FAILED, payload };
}

export function deleteBusinessPartner(id) {
    return { type: ActionTypes.DELETE_BUSINESS_PARTNER_REQUEST, payload: id};
}

export function deleteBusinessPartnerSuccess(BusinessPartner) {
   
    return { type: ActionTypes.DELETE_BUSINESS_PARTNER_SUCCESS, payload: BusinessPartner };
}

export function deleteBusinessPartnerFailed(payload) {
   
    return { type: ActionTypes.DELETE_BUSINESS_PARTNER_FAILED, payload };
}

export function updateBusinessPartner(BusinessPartner) {
    return { type: ActionTypes.UPDATE_BUSINESS_PARTNER_REQUEST, payload: BusinessPartner};
}

export function updateBusinessPartnerSuccess(BusinessPartner) {
   
    return { type: ActionTypes.UPDATE_BUSINESS_PARTNER_SUCCESS, payload: BusinessPartner};
}

export function updateBusinessPartnerFailed(BusinessPartner) {
   
    return { type: ActionTypes.UPDATE_BUSINESS_PARTNER_FAILED, BusinessPartner };
}

