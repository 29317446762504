import React, { useEffect, Fragment, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import CreatableSelect from "react-select/creatable"
import ReactSelect from "react-select"
import { Table, Row, Col, Button, Input, Spinner } from "reactstrap"
import { Filter, DefaultColumnFilter } from "./filters"
import { withTranslation } from "react-i18next"
import { useDebouncedCallback } from "use-debounce"
import { DateRangePicker } from "react-date-range"
import "react-date-range/dist/styles.css" 
import "react-date-range/dist/theme/default.css" 
import format from "date-fns/format"
import { addDays } from "date-fns"
import ReactToPrint from "react-to-print"
import { downloadExcel } from "react-export-table-to-excel"
import moment from "moment"
import { useHistory } from "react-router-dom"

//

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isAddOptions,
  isAddRetailerOptions,
  isAddPayIn,
  isPrintOption,
  isExcelPartner,
  isExcelOption,
  isAddOptionsEdit,
  isAddUserList,
  filterDate,
  handleDate,
  handleOrderClicks,
  handleUserClick,
  handelAddClick,
  isAddCustList,
  isAddRoleList,
  customPageSize,
  className,
  customPageSizeOptions,
  setPageNumber,
  nextPage,
  previousPage,
  currentPage,
  total,
  limit,
  t,
  loading,
  success,
  setFilter,
  partners,
  retailers,
  users,
  setUser,
  isUsersFilter,
  setPartner,
  setRetailer,
  isPartnersFilter,
  isRetailersFilter,
  isRetailerTransactionFilter,
  commissions,
  isCommissionFilter,
  ResetCommissionFilter,
  ActionReleaseReject,
  ActionPay,
  setCommission,
  setLimit,
  commissionTypeFilter,
  setDateFilter,
  categoryFilter,
  shopnameFilter,
  setCategory,
  setShopname,
  setHiddenColumn,
  categories,
  brandFilter,
  setBrand,
  brands,
  cityFilter,
  setCity,
  cities,
  resetOption,
  resetTransactionIncomeOption,
  resetBPStatsOption,
  resetPartnerTransactionOption,
  resetCommissionInOption,
  resetRetailerStatsOption,
}) => {
  const [columnsData, setColumnsData] = useState(columns || [])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columnsData,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )
  const history = useHistory()
  const userRef = useRef()
  const [retailerValue, setRetailerValue] = useState("")
  const [partnerValue, setPartnerValue] = useState("")
  const [userValue, setUserValue] = useState("")

  const commissionRef = useRef()
  const [categoryValue, setCategoryValue] = useState("")
  const [shopnameValue, setShopnameValue] = useState("")
  const [brandValue, setBrandValue] = useState("")
  const [cityValue, setCityValue] = useState("")
  const componentRef = useRef()
  const currentLanguage = localStorage.getItem("I18N_LANGUAGE")

  // Define a default UI for filtering
  // function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {

  //   const count = preGlobalFilteredRows.length;
  //   // const [value, setValue] = React.useState(globalFilter);
  //   // const onChange = useAsyncDebounce(value => {
  //   //   setGlobalFilter(value || undefined);
  //   // }, 200);

  //   return (

  //   );
  // }

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setLimit(event.target.value)
  }

  const onChangePageSize = page => {
    setPageNumber(page)
    //const page = event.target.value ? Number(event.target.value) - 1 : 0;
    // gotoPage(page);
  }
  const debounced = useDebouncedCallback(
    // function
    value => {
      //console.log(value)
      setFilter(value)
    },
    // delay in ms
    500
  )

  // date state
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ])

  // open close
  const [open, setOpen] = useState(false)

  // get the target element to toggle
  const refOne = useRef(null)

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside, true)
  }, [])

  // hide dropdown on ESC press
  const hideOnEscape = e => {
    // console.log(e.key)
    if (e.key === "Escape") {
      setOpen(false)
    }
  }
  // Hide dropdown on outside click
  const hideOnClickOutside = e => {
    //setOpen(false)
    // console.log(refOne.current)
    // console.log(e.target)
    // if (refOne.current && !refOne.current.contains(e.target)) {
    //   setOpen(false)
    // }
  }
  const handleDownloadExcelRetailer = () => {
    const header = [
      "Full Name",
      "Shop Name",
      "City",
      "Governorate",
      "Country",
      "Category",
      "Brand",
      "Commission",
      "Last Transaction",
      "Created By",
      "Created At",
      "Status",
    ]
    let tableBody = retailers.map(retailer => {
      return {
        fullName: retailer.firstname + " " + retailer.lastname,
        shopName: retailer.shopName || "-----",
        city:
          (currentLanguage == "ar" && retailer.city?.nameAr) ||
          (currentLanguage == "en" && retailer.city?.nameEn) ||
          (currentLanguage == "fr" && retailer.city?.nameFr) ||
          "-----",
        governorate:
          (currentLanguage == "ar" && retailer.governorate?.nameAr) ||
          (currentLanguage == "en" && retailer.governorate?.nameEn) ||
          (currentLanguage == "fr" && retailer.governorate?.nameFr) ||
          "-----",
        country:
          (currentLanguage == "ar" && retailer.country?.nameAr) ||
          (currentLanguage == "en" && retailer.country?.nameEn) ||
          (currentLanguage == "fr" && retailer.country?.nameFr) ||
          "-----",
        category:
          (currentLanguage == "ar" && retailer.category?.nameAr) ||
          (currentLanguage == "en" && retailer.category?.nameEn) ||
          (currentLanguage == "fr" && retailer.category?.nameFr) ||
          "-----",
        brand:
          (currentLanguage == "ar" && retailer.brand?.nameAr) ||
          (currentLanguage == "en" && retailer.brand?.nameEn) ||
          (currentLanguage == "fr" && retailer.brand?.nameFr) ||
          "-----",
        commission: retailer.commission || "-----",
        lastTransaction: retailer.lastTransaction || "-----",
        createdBy:
          (retailer.createdBy &&
            retailer.createdByAdmin?.firstname +
              " " +
              retailer.createdByAdmin?.lastname) ||
          "-----",
        createdAt:
          moment(retailer.createdAt).format("YYYY-MM-DD HH:mm") || "-----",
        status: retailer.status || "-----",
      }
    })
    downloadExcel({
      fileName: "Retailers",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        // accept two different data structures
        body: tableBody || [],
      },
    })
  }
  const handleDownloadExcelPartner = () => {
    const header = [
      "Full name",
      "Parent ID",
      "Nr. L1",
      " Nr. L2",
      "Nr. L3",
      " Nr. L4",
      "Member Since",
      "Last referral date",
      "Last purchase date",
      "Status",
    ]
    let tableBody = partners.map(partner => {
      return {
        fullName: partner.firstname + " " + partner.lastname,
        parrainId: partner.parrainId || "-----",
        levels0: partner.levels[0]?.count || "-----",
        levels1: partner.levels[1]?.count || "-----",
        levels2: partner.levels[2]?.count || "-----",
        levels3: partner.levels[3]?.count || "-----",
        createdAt:
          moment(new Date(partner.createdAt)).format("YYYY-MM-DD") || "-----",
        lastReferral:
          (partner.lastReferral &&
            moment(new Date(partner.lastReferral)).format(
              "YYYY-MM-DD HH:mm"
            )) ||
          "-----",
        lastTransaction:
          (partner.lastTransaction &&
            moment(new Date(partner.lastTransaction)).format(
              "YYYY-MM-DD HH:mm"
            )) ||
          "-----",
        status: partner.status || "-----",
      }
    })

    downloadExcel({
      fileName: "Partners",
      sheet: "export-table-to-excel",
      tablePayload: {
        header,
        // accept two different data structures
        body: tableBody || [],
      },
    })
  }

  return (
    <Fragment>
      <Row className="mb-2">
        {ActionPay && (
          <Col sm="1">
            <div>
              <Button
                type="button"
                color="success"
                className="btn  mb-2 me-2"
                onClick={changeStatusPaid}
              >
                {t("Pay")}
              </Button>
            </div>
          </Col>
        )}
        {categoryFilter && (
          <Col xl="2">
            <CreatableSelect
              className="mb-2 me-2"
              value={categoryValue}
              onChange={value => {
                setCategoryValue(value)
                setCategory(value)
              }}
              placeholder={t("select category...")}
              options={categories.map(category => {
                return {
                  value: category.id,
                  label:
                    (currentLanguage == "ar" && category.nameAr) ||
                    (currentLanguage == "en" && category.nameEn) ||
                    (currentLanguage == "fr" && category.nameFr),
                }
              })}
              classNamePrefix="select2-selection"
            />
          </Col>
        )}
        {brandFilter && (
          <Col xl="2">
            <CreatableSelect
              className="mb-2 me-2"
              value={brandValue}
              onChange={value => {
                setBrandValue(value)
                setBrand(value)
              }}
              placeholder={t("select brand...")}
              options={brands.map(brand => {
                return {
                  value: brand.id,
                  label:
                    (currentLanguage == "ar" && brand.nameAr) ||
                    (currentLanguage == "en" && brand.nameEn) ||
                    (currentLanguage == "fr" && brand.nameFr),
                }
              })}
              classNamePrefix="select2-selection"
            />
          </Col>
        )}
        {cityFilter && (
          <Col xl="2">
            <CreatableSelect
              className="mb-2 me-2"
              value={cityValue}
              onChange={value => {
                setCityValue(value)
                setCity(value)
              }}
              placeholder={t("select city...")}
              options={cities.map(city => {
                return {
                  value: city.id,
                  label:
                    (currentLanguage == "ar" && city.nameAr) ||
                    (currentLanguage == "en" && city.nameEn) ||
                    (currentLanguage == "fr" && city.nameFr),
                }
              })}
              classNamePrefix="select2-selection"
            />
          </Col>
        )}
        
        {filterDate && (
          <>
            <Col xl="3" >
              <input
                value={`${format(range[0].startDate, "d MMMM")}  To  ${format(
                  range[0].endDate,
                  "d MMMM"
                )}`}
                readOnly
                className="form-control external-event mb-2 me-2"
                onClick={() => setOpen(!open)}
                
              />
              {open && (
                <DateRangePicker
                  onChange={item => {
                    setRange([item.selection])
                    setDateFilter(item.selection)
                  }}
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  scroll={{ enabled: true }}
                  ranges={range}
                  months={2}
                  direction="horizontal"
                  className="calendarElement mt-3 ReactModal__Overlay position-relative shadow rdrInputRanges"
                  showSelectionPreview={true}
                />
              )}
            </Col>
          </>
        )}
        {isGlobalFilter && (
          <Col xl="2">
            <div className="search-box me-2 mb-2 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <Input
                    onChange={e => {
                      //setValue(e.target.value);
                      //onChange(e.target.value);
                      debounced(e.target.value)
                    }}
                    id="search-bar-0"
                    type="text"
                    className="form-control mb-2 me-2"
                    placeholder={`${data.length} ${t("Search")}...`}
                    //value={value || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon  mb-2 me-2"></i>
              </div>
            </div>
          </Col>
        )}
        {ActionPay && (
          <Col sm="1">
            <div>
              <Button
                type="button"
                color="success"
                className="btn  mb-2 me-2"
                onClick={changeStatusPaid}
              >
                {t("Pay")}
              </Button>
            </div>
          </Col>
        )}
        {commissionTypeFilter && (
          <Col xl="2">
            <select
              className="form-select mb-3"
              ref={commissionRef}
              onChange={e => {
                setCommission(e.target.value)
                //commissionRef.current.value = "Select commission type"
              }}
            >
              <option key="empty" style={{ display: "none" }}>
                {t("Select commission type")}
              </option>
              {["buyerCom", "kasbCom", "levelCommission", "MLCOM"].map(
                commission => (
                  <option key={commission} value={commission}>
                    {commission}
                  </option>
                )
              )}
            </select>
          </Col>
        )}

        {isUsersFilter && (
          <Col xl="2">
            <ReactSelect
              className="mb-2 me-2"
              value={userValue}
              onChange={value => {
                setUserValue(value)
                setUser(value)
              }}
              placeholder={t("Select user")}
              options={users.map(user => {
                return {
                  value: user.id,
                  label: user.firstname + " " + user.lastname,
                }
              })}
              classNamePrefix="select2-selection"
            />
          </Col>
        )}
        {isPartnersFilter && (
          <Col xl="2">
            <ReactSelect
              className="mb-2 me-2"
              value={partnerValue}
              onChange={value => {
                setPartnerValue(value)
                setPartner(value)
              }}
              placeholder={t("select partner...")}
              options={partners.map(partner => {
                return {
                  value: partner.id,
                  label: partner.firstname + " " + partner.lastname,
                }
              })}
              classNamePrefix="select2-selection"
            />
          </Col>
        )}
        {isRetailerTransactionFilter && (
          <Col xl="2">
            <ReactSelect
              className="mb-2 me-2"
              value={retailerValue}
              onChange={value => {
                setRetailerValue(value)
                setRetailer(value)
              }}
              placeholder={t("select retailer...")}
              options={retailers?.map(retailer => {
                return {
                  value: retailer.id,
                  label: retailer.firstname + " " + retailer.lastname,
                }
              })}
              classNamePrefix="select2-selection"
            />
          </Col>
        )}

        {isRetailersFilter && (
          <Col xl="2">
            <ReactSelect
              className="mb-2 me-2"
              value={retailerValue}
              onChange={value => {
                setRetailerValue(value)
                setRetailer(value)
              }}
              placeholder={t("select retailer...")}
              options={retailers
                .filter(retailer => retailer.shopName)
                ?.map(retailer => {
                  return {
                    value: retailer.id,
                    label: retailer.firstname + " " + retailer.lastname,
                  }
                })}
              classNamePrefix="select2-selection"
            />
          </Col>
        )}
        {shopnameFilter && (
          <Col xl="2">
            <CreatableSelect
              className="mb-2 me-2"
              value={shopnameValue}
              onChange={value => {
                setShopnameValue(value)
                setShopname(value)
              }}
              placeholder={t("select Shop Name")}
              options={retailers
                .filter(retailer => retailer.shopName)
                ?.map(retailer => {
                  return {
                    value: retailer.id,
                    label: retailer.shopName,
                  }
                })}
              classNamePrefix="select2-selection"
            />
          </Col>
        )}
        {isCommissionFilter && (
          <Col xl="2">
            <select
              className="form-select mb-3 me-3"
              ref={commissionRef}
              onChange={e => setCommission(e.target.value)}
            >
              <option key="empty" style={{ display: "none" }}>
                {t("Select parameter")}
              </option>
              {commissions.map(commission => (
                <option key={commission.shorts} value={commission.name}>
                  {commission.shorts}
                </option>
              ))}
            </select>
          </Col>
        )}
        {ResetCommissionFilter && (
          <Col sm="7">
            <div>
              <Button
                type="button"
                color="secondary"
                className="btn  mb-2 me-2"
                onClick={() => {
                  setUser({ value: "", label: "" })
                  setUserValue("")
                  setCommission("")
                  commissionRef.current.value = "Select parameter"
                }}
              >
                {t("Reset")}
              </Button>
            </div>
          </Col>
        )}
        {ActionReleaseReject && (
          <Col sm="8">
            <div>
              <Button
                type="button"
                color="info"
                className="btn  mb-2 me-2"
                disabled
              >
                {t("Release")}
              </Button>
              <Button
                type="button"
                color="danger"
                className="btn  mb-2 me-2"
                disabled
              >
                {t("Reject")}
              </Button>
            </div>
          </Col>
        )}

        {isAddCustList && (
          <Col>
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded mb-2 me-2"
                onClick={handelAddClick}
              >
                <i className="mdi mdi-plus me-1" />
                {t("Add")}
              </Button>
            </div>
          </Col>
        )}

        {resetOption && (
          <Col xl="1">
            <div>
              <Button
                type="button"
                color="secondary"
                className="btn  mb-2 me-2"
                onClick={() => {
                  setBrand({ value: "", label: "" })
                  setCategory({ value: "", label: "" })
                  setCity({ value: "", label: "" })
                  setShopname({ value: "", label: "" })
                  setShopnameValue("")
                  setBrandValue("")
                  setCategoryValue("")
                  setCityValue("")
                }}
              >
                {t("Reset")}
              </Button>
            </div>
          </Col>
        )}
        {resetCommissionInOption && (
          <Col sm="1">
            <div className="text-sm-end">
              <Button
                type="button"
                color="secondary"
                className="btn  mb-2 me-2"
                onClick={() => {
                  setRetailer({ value: "", label: "" })
                  setRetailerValue("")
                }}
              >
                {t("Reset")}
              </Button>
            </div>
          </Col>
        )}
        {resetRetailerStatsOption && (
          <Col sm="1">
            <div className="text-sm-end">
              <Button
                type="button"
                color="secondary"
                className="btn  mb-2 me-2"
                onClick={() => {
                  setRetailer("")
                  setRetailerValue("")
                }}
              >
                {t("Reset")}
              </Button>
            </div>
          </Col>
        )}
        {resetTransactionIncomeOption && (
          <Col sm="1">
            <div className="text-sm-end">
              <Button
                type="button"
                color="secondary"
                className="btn  mb-2 me-2"
                onClick={() => {
                  setRetailer({ value: "", label: "" })
                  setRetailerValue("")
                  setPartner({ value: "", label: "" })
                  setPartnerValue("")
                }}
              >
                {t("Reset")}
              </Button>
            </div>
          </Col>
        )}
        {resetBPStatsOption && (
          <Col sm="1">
            <div className="text-sm-end">
              <Button
                type="button"
                color="secondary"
                className="btn  mb-2 me-2"
                onClick={() => {
                  setPartner({ value: "", label: "" })
                  setPartnerValue("")
                }}
              >
                {t("Reset")}
              </Button>
            </div>
          </Col>
        )}
        {resetPartnerTransactionOption && (
          <Col sm="1">
            <div className="text-sm-end">
              <Button
                type="button"
                color="secondary"
                className="btn  mb-2 me-2"
                onClick={() => {
                  setCommission("")
                  commissionRef.current.value = "Select commission type"
                  setPartner({ value: "", label: "" })
                  setPartnerValue("")
                }}
              >
                {t("Reset")}
              </Button>
            </div>
          </Col>
        )}

        {isAddOptions && (
          <Col xl="12">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                {t("Add")}
              </Button>
            </div>
          </Col>
        )}

        {isAddRetailerOptions && (
          <Col xl="11">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                {t("Add")}
              </Button>
            </div>
          </Col>
        )}
        {isPrintOption && (
          <Col>
            <Row>
              <div className="text-sm-end">
                <div className="d-print-none">
                  <div className="float">
                    <ReactToPrint
                      //onBeforeGetContent={() => {setColumnsData([])}}
                      // onAfterPrint={() => setHiddenColumn(false)}
                      trigger={() => (
                        <Button type="button" color="success">
                          <i className="fa fa-print font-size-15" /> Print{" "}
                        </Button>
                      )}
                      content={() => componentRef.current}
                    />
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        )}

        {isExcelOption && (
          <Col xl="">
            <div className="text-sm-end">
              <div className="d-print-none">
                <div className="float">
                  <Button
                    onClick={handleDownloadExcelRetailer}
                    type="button"
                    color="secondary"
                  >
                    <i className="fas fa-file-excel font-size-15" /> Excel{" "}
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {isExcelPartner && (
          <Col xl="12">
            <div className="text-sm-end">
              <div className="d-print-none">
                <div className="float">
                  <Button
                    onClick={handleDownloadExcelPartner}
                    type="button"
                    color="secondary"
                  >
                    <i className="fas fa-file-excel font-size-15" /> Excel{" "}
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {isAddPayIn && (
          <Col sm="9">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                {t("Add")}
              </Button>
            </div>
          </Col>
        )}
        {isAddOptionsEdit && (
          <Col sm="12">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded  mb-2 me-2"
                // onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                {t("Add")}
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="10">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                {t("Create new user")}
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table
          bordered
          hover
          {...getTableProps()}
          className={className}
          innerRef={componentRef}
        >
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="pt-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {!loading && (
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          )}
        </Table>
      </div>
      {!loading && data.length < 1 && (
        <div className="text-center font-size-18">{t("No Data")}</div>
      )}
      {loading && (
        <div className="text-center">
          <Spinner animation="border" role="status" />
        </div>
      )}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1 ">
            <select
              className="form-select mb-3 mt-3"
              //value={limit}
              defaultValue={customPageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 50, 100, 500, 1000].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {t("Show")} {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-md-end justify-content-center align-items-center">
        {/* <Col className="col-md-auto">
          <div className="d-flex gap-1 ">
            <select
              className="form-select mb-13 mt-3"
              //value={limit}
              defaultValue={customPageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 50, 100, 500, 1000].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {t("Show")} {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Col> */}
        <Col className="col-md-auto">
          <div className="d-flex gap-1 ">
            <Button
              className=" mb-10 me-10"
              color="primary"
              onClick={() => onChangePageSize(1)}
              disabled={!previousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={() => onChangePageSize(currentPage - 1)}
              disabled={!previousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          {t("Page")}{" "}
          <strong>
            {currentPage} {t("Of")} {Math.ceil(total / limit) || 0}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={Math.ceil(total / limit) || 0}
            defaultValue={1}
            onChange={e => onChangePageSize(e.target.value)}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => onChangePageSize(currentPage + 1)}
              disabled={!nextPage}
            >
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => onChangePageSize(Math.ceil(total / limit))}
              disabled={!nextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(TableContainer)
