import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import { withTranslation } from "react-i18next"
import moment from "moment"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap"
import { getRetailers, getTransactionsIncome } from "store/actions"
import MiniCards from "pages/Dashboard/mini-cards"
import { map } from "lodash"

function ComissionIn(props) {

  //meta title
  document.title = "Kasb"


  const [filter, setFilter] = useState({
    partnerId: '',
    retailerId: '',
    from: "",
    to: "",
    page: 1,
    limit: 100,
  })


  const dispatch = useDispatch()

  const { retailersData } = useSelector(state => state.retailers);


 
  const {
    transactionIncomeData,
    success,
    error,
    nextPage,
    previousPage,
    currentPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.transactions)

  useEffect(() => {
    dispatch(getTransactionsIncome(filter))
    dispatch(getRetailers(filter))
  }, [dispatch, filter])

  const [miniCards, setMiniCards] = useState([
    {
      title: props.t("Total Commission Amount"),
      iconClass: "bx bx-dollar",
      text: props.t("1250"),
    },
    {
      title: props.t("Total Transaction Amount"),
      iconClass: "bx bx-dollar",
      text: props.t("524"),
    },
  ])

  const columns = useMemo(
    () => [
      {
        Header: props.t("Reference"),
        accessor: "reference",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.transaction?.reference || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Date"),
        accessor: "createdAt",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {moment(new Date(cellProps.createdAt)).format("YYYY-MM-DD") ||
                props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Retailer"),
        accessor: "",
        disableFilters: true,
        accessor: cellProps => {
          return (
              <>
                {cellProps.transaction?.retailer?.firstname +
                  " " +
                  cellProps.transaction?.retailer?.lastname }
              </>
        )
      },
      },
      {
        Header: props.t("Buisness Partner"),
        accessor: "",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {cellProps.transaction?.partner?.firstname +
                " " +
                cellProps.transaction?.partner?.lastname || cellProps.transaction?.partner?.username}
            </>
          )
        },
      },
      {
        Header: props.t("Commission amount"),
        accessor: "amount",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.amount || props.t("Not provided")}</>
        },
      },
      
      {
        Header: props.t("RCOM")+"%",
        accessor: "retailerCommissionValue",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.retailerCommissionValue || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Transaction Amount"),
        accessor: "",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.transaction?.amount || props.t("Not provided")}</>
        },
      },
    ],
    []
  )
 
  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  const handleDateFromTo = date => {
    if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.startDate).add(1, "days").format("Y-MM-DD")
        }
      })
    }else {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.endDate).format("Y-MM-DD"),
        }
      })
    }
  }

  const handleRetailer =(retailer)=>{setFilter(prevFilter => {return {...prevFilter, retailerId:retailer.value }})}
 
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title={props.t("Commission In")} breadcrumbItem={props.t("List commission in")} />
          {/* <Row>
            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
            </Col>
          </Row> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                
                  <TableContainer
                    columns={columns}
                    data={transactionIncomeData}
                    // isGlobalFilter={true}
                    filterDate={true}
                    setDateFilter={handleDateFromTo}
                    resetCommissionInOption={true}
                    customPageSize={100}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    setRetailer={handleRetailer}
                    retailers={retailersData}
                    isRetailerTransactionFilter={true}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
    </React.Fragment>
  )
}
ComissionIn.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default withTranslation()(ComissionIn)
