import React from "react"

import * as Yup from "yup"
import { useFormik } from "formik"
import {

  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap"



//redux
import { useSelector, useDispatch } from "react-redux"


import {
  createMDMCategory,
  getMDMCategories,
  updateMDMCategory,

} from "store/actions"
import { withTranslation } from "react-i18next"



const CategoryModal = (props) => {

    const dispatch = useDispatch()
    const { modal, toggle, category, isEdit } = props
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
    
        initialValues: {
          nameEn: (category && category.nameEn) || "",
          nameFr: (category && category.nameFr) || "",
          nameAr: (category && category.nameAr) || "",
          comission: (category && category.comission) || "",
          type: (category && category.type) || "category", 
        },
        validationSchema: Yup.object({
          nameEn: Yup.string().required(props.t("Please Enter Your English name")),
          nameFr: Yup.string().required(props.t("Please Enter Your French name")),
          nameAr: Yup.string().required(props.t("Please Enter Your Arabic Name")),
          comission: Yup.number().required(props.t("Please Enter Your Commission")),
        }),
        onSubmit: values => {
          if (isEdit) {
            const updateCategory = {
              id: category ? category.id : 0,
              nameEn: values.nameEn,
              nameFr: values.nameFr,
              nameAr: values.nameAr,
              comission: values.comission,
            }
            // update
            // console.log("updateCategory", updateCategory)
            dispatch(updateMDMCategory(updateCategory))
            dispatch(getMDMCategories())
            validation.resetForm()
          } else {
            const newCategory = {
              nameEn: values["nameEn"],
              nameFr: values["nameFr"],
              nameAr: values["nameAr"],
              comission: values["comission"],
              type: values["type"],
            }
            // save new city
            dispatch(createMDMCategory(newCategory))
            dispatch(getMDMCategories())
            validation.resetForm()
          }
          toggle()
        },
      })
    
  return (
<Modal isOpen={modal} toggle={toggle}>
<ModalHeader toggle={toggle} tag="h4">
  {!!isEdit ? props.t("Edit Category") : props.t("Add Category")}
</ModalHeader>
<ModalBody>
  <Form
    onSubmit={e => {
      e.preventDefault()
      validation.handleSubmit()
      return false
    }}
  >
    <Row form>
      <Col className="col-12">
        <div className="mb-3">
          <Label className="form-label">{props.t("English Name")}</Label>
          <Input
            name="nameEn"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.nameEn || ""}
            invalid={
              validation.touched.nameEn &&
              validation.errors.nameEn
                ? true
                : false
            }
          />
          {validation.touched.nameEn &&
          validation.errors.nameEn ? (
            <FormFeedback type="invalid">
              {validation.errors.nameEn}
            </FormFeedback>
          ) : null}
        </div>
        <div className="mb-3">
          <Label className="form-label">{props.t("French Name")}</Label>
          <Input
            name="nameFr"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.nameFr || ""}
            invalid={
              validation.touched.nameFr &&
              validation.errors.nameFr
                ? true
                : false
            }
          />
          {validation.touched.nameFr &&
          validation.errors.nameFr ? (
            <FormFeedback type="invalid">
              {validation.errors.nameFr}
            </FormFeedback>
          ) : null}
        </div>
        <div className="mb-3">
          <Label className="form-label">{props.t("Arabic Name")}</Label>
          <Input
            name="nameAr"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.nameAr || ""}
            invalid={
              validation.touched.nameAr &&
              validation.errors.nameAr
                ? true
                : false
            }
          />
          {validation.touched.nameAr &&
          validation.errors.nameAr ? (
            <FormFeedback type="invalid">
              {validation.errors.nameAr}
            </FormFeedback>
          ) : null}
        </div>
        <div className="mb-3">
          <Label className="form-label">
          {props.t("Category Commission")}
          </Label>
          <Input
            name="comission"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.comission || ""}
            invalid={
              validation.touched.comission &&
              validation.errors.comission
                ? true
                : false
            }
          />
          {validation.touched.comission &&
          validation.errors.comission ? (
            <FormFeedback type="invalid">
              {validation.errors.comission}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="text-end">
          <button
            type="submit"
            className="btn btn-success save-customer"
          >
            {props.t("Save")}
          </button>
        </div>
      </Col>
    </Row>
  </Form>
</ModalBody>
</Modal>)
}


export default withTranslation()(CategoryModal)