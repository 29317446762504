import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { useSelector } from "react-redux";
import _ from "lodash";

const Authmiddleware = ({component: Component, layout: Layout, isAuthProtected, ...rest}) => {


  const user = useSelector(state => state.Login.user);


return (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && _.isEmpty(user)) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)}


Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any, 
}

export default Authmiddleware;
