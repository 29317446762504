import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_LOG } from "./actionTypes"

import {
  getLogsSuccess,
  getLogsFailed,
 
} from "./actions"
import { getLogs } from "helpers/api_helper"
// import { getTransactions } from "helpers/api_helper"

//Include Both Helper File with needed methods

function* getLogsSaga({payload}) {
 
  try {
    const data = yield getLogs(payload)
    yield put(getLogsSuccess(data))
  } catch (error) {
    // console.log('error', error.response)
    yield put(getLogsFailed(error))
  }
}




function* logsSaga() {
  yield takeEvery(GET_LOG, getLogsSaga)
}

export default logsSaga;
