import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { TabPane } from "reactstrap"
import { withTranslation } from "react-i18next"
import TableContainer from "../../components/Common/TableContainer"
//redux
import { useSelector, useDispatch } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import { getBusinessPartner, getEwallet, getRetailers } from "store/actions"
import { useHistory } from "react-router-dom"
import moment from "moment"

const EwalletTransaction = props => {
  //meta title
  document.title = "Kasb"
  const history = useHistory()

  const [filter, setFilter] = useState({
    partnerId: "",
    adminId: "",
    retailerId: "",
    from: "",
    to: "",
    page: 1,
    limit: 100,
  })
  const {
    ewalletData,
    error,
    success,
    nextPage,
    previousPage,
    limit,
    total,
    currentPage,
    loading,
  } = useSelector(state => state.ewallets)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBusinessPartner(filter))
    dispatch(getRetailers(filter))
    dispatch(getEwallet(filter))
  }, [dispatch, filter])

  const { BusinessPartnerData } = useSelector(state => state.businesspartners)
  const { retailersData } = useSelector(state => state.retailers)

  const columns = useMemo(
    () => [
      {
        Header: props.t("Member Name"),
        accessor: "name",
        filterable: true,
        accessor: cellProps => {
          return (
            <div
              className="external-event"
              onClick={() => {
                // console.log(cellProps.user)
                if (cellProps.user?.accountType == "retailer")
                  history.push(`/retailer-profile/${cellProps.user?.id}`)
                else if (cellProps.user?.accountType == "partner")
                  history.push(`/partner-profile/${cellProps.user?.id}`)
              }}
            >
              {cellProps.user?.firstname +
                " " +
                cellProps.user?.lastname +
                " (" +
                cellProps.user?.username +
                ")" || props.t("Not provided")}
            </div>
          )
        },
      },
      {
        Header: props.t("Category Commission"),
        accessor: "commission",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.commission || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Amount"),
        accessor: "amount",
        accessor: cellProps => {
          return (
            <>
              {cellProps.walletTransactions[0]?.amount ||
                props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Type"),
        accessor: "type",
        filterable: true,
        accessor: cellProps => {
          return <>{cellProps.user?.accountType || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Transaction Date"),
        accessor: "",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.walletTransactions?.createdAt &&
                moment(
                  new Date(cellProps.walletTransactions?.createdAt)
                ).format("YYYY-MM-DD HH:mm")) ||
                props.t("Not provided")}
            </>
          )
        },
      },
    ],
    []
  )

  const setRetailer = retailer => {
    setFilter(prevFilter => {
      return { ...prevFilter, retailerId: retailer.value }
    })
  }

  const setPartner = partner => {
    setFilter(prevFilter => {
      return { ...prevFilter, partnerId: partner.value }
    })
  }
  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  const handleDateFromTo = date => {
    if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.startDate).add(1, "days").format("Y-MM-DD")
        }
      })
    } else {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.endDate).format("Y-MM-DD"),
        }
      })
    }
  }
  return (
    <React.Fragment>
      <TabPane tabId="1" id="summary">
        <TableContainer
          columns={columns}
          data={ewalletData}
          resetTransactionIncomeOption={true}
          isGlobalFilter={true}
          setPartner={setPartner}
          partners={BusinessPartnerData}
          isPartnersFilter={true}
          setRetailer={setRetailer}
          retailers={retailersData}
          isRetailersFilter={true}
          filterDate={true}
          setDateFilter={handleDateFromTo}
          isAddOptions={false}
          setPageNumber={setPageNumber}
          setLimit={handleLimit}
          customPageSize={100}
          nextPage={nextPage}
          previousPage={previousPage}
          currentPage={currentPage}
          total={total}
          limit={limit}
          loading={loading}
          success={success}
        />
      </TabPane>
    </React.Fragment>
  )
}

EwalletTransaction.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(EwalletTransaction)
