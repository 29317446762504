//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";
//LOGIN
export const POST_LOGIN = "/auth/admins/signin";
export const VERIFY_ME = "/auth/admins/me";
export const RESET_PASSWORD = "/auth/reset-password/";
//METADATA
export const GET_METADATA = "/post-jwt-profile";
//ADMINS
export const POST_REGISTER = "/auth/admins";
export const GET_ADMINS = "/auth/admins";
export const GET_ADMIN = "/auth/admins/";
export const PUT_UPDATE = "/auth/admins/";
export const GET_USER = "/users/";

//ROLES
export const GET_ROLES = "/roles";
export const PUT_ROLE = "/roles/";
export const GET_PERMISSIONS = "/permissions";

//RETAILER
export const GET_RETAILERS_STATS = "/users/stats/retailer";
export const GET_RETAILERS = "/users/type/retailer";
export const GET_RETAILER = "/users/";
export const PUT_RETAILER = "/users/";
export const POST_RETAILER = "/auth/signup";
//permission
export const GET_ROLESS = "/roles";
export const GET_ROLE = "/roles/";
export const PUT_ROLES= "/roles/";
export const POST_ROLES = "/roles";
export const DELETE_ROLES = "/roles/";
//Ewallet
export const GET_EWALLET = "/wallets";
export const GET_EWALLET_STATS = "/wallets/stats";
//Payout
export const GET_PAYOUT = "/payoutrequests";
export const GET_PAYOUT_STATS = "/payoutrequests/stats";
export const GET_PAYOUT_BY_NAME = "/payoutrequests";
export const PUT_PAYOUT = "/payoutrequests/";
export const POST_PAYOUT = "/payoutrequests/admin"
//Payin
export const GET_PAYIN = "/payinrequests";
export const GET_PAYIN_STATS = "/payinrequests/stats";
export const PUT_PAYIN = "/payinrequests/";
export const POST_PAYIN = "/payinrequests"


//BuisnessPartner
export const GET_PARTNERS_STATS = "/users/stats/partner";
export const GET_BUINESS_PARTNERS = "/users/type/partner";
export const GET_BUINESS_PARTNERS_STATS = "/users/partner/stats";
export const GET_BUINESS_PARTNER  = "/users/";
export const PUT_BUINESS_PARTNER  = "/users/";
//METADATA_CITY
export const GET_METADATA_CITY = "/metadata/type/city";
export const PUT_METADATA_CITY = "/metadata/";
export const POST_METADATA_CITY = "/metadata/";
//METADATA_CONTRY
export const GET_METADATA_COUNTRY = "/metadata/type/country";
export const PUT_METADATA_COUNTRY = "/metadata/";
export const POST_METADATA_COUNTRY = "/metadata/";
//METADATA_CATEGORY
export const GET_METADATA_CATEGORY = "/metadata/type/category";
export const PUT_METADATA_CATEGORY = "/metadata/";
export const POST_METADATA_CATEGORY= "/metadata/";
export const DELETE_METADATA_CATEGORY= "/metadata/";

//level comission
export const GET_METADATA_LEVEL = "/levelcomissions";
export const PUT_METADATA_LEVEL = "/levelcomissions/";
export const POST_METADATA_LEVEL= "/levelcomissions/";
//METADATA_GOVERNORATE
export const GET_METADATA_GOVERNORATE = "/metadata/type/governorate";
export const PUT_METADATA_GOVERNORATE = "/metadata/";
export const POST_METADATA_GOVERNORATE= "/metadata/";
//METADATA_BRAND
export const GET_METADATA_BRAND = "/metadata/type/brand";
export const PUT_METADATA_BRAND = "/metadata/";
export const POST_METADATA_BRAND= "/metadata/";
//METADATA_METHOD-PAYMENT
export const GET_METADATA_PAYMENT_METHOD = "/metadata/type/paymentmethod";
export const PUT_METADATA_PAYMENT_METHOD= "/metadata/";
export const POST_METADATA_PAYMENT_METHOD= "/metadata/";

//FILES
export const UPLOAD_FILES = "/uploads"

//Transactions
export const GET_TRANSACTIONS = "/transactions";
export const GET_TRANSACTIONSINCOME = "/transactions/transactionIncome";
export const GET_TRANSACTIONSINCOME_STATS = "/transactions/transactionIncome/stats";
export const GET_TRANSACTIONS_PARTNER = "/transactions/partnerIncome";
export const GET_TRANSACTIONS_PARTNER_STATS = "/transactions/partnerIncome/stats";

//Configs
export const GET_CONFIGS = "/configs";
export const PUT_CONFIGS = "/configs/";
//Logs
export const GET_LOGS = "/logs";
export const GET_LOGS_BY_MODEL = "/logs/model/";


export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";


//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";
export const ADD_NEW_USER = "/add/user";
export const UPDATE_USER = "/update/user";
export const DELETE_USER = "/delete/user";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

export const TOP_SELLING_DATA = "/top-selling-data";

export const GET_EARNING_DATA = "/earning-charts-data";


