import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import "react-datepicker/dist/react-datepicker.css"
import {
  TabPane,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import TableContainer from "../../../components/Common/TableContainer"

//redux
import { useSelector, useDispatch } from "react-redux"
import * as moment from "moment"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

import { getPayout} from "store/actions"

const PayoutSummary = props => {
  //meta title
  document.title = "Kasb"
 
  const [filter, setFilter] = useState({
    partnerId: "",
    from: "",
    to: "",
    status: "",
    page: 1,
    limit: 100,
  })

  const {
    payoutData,
    success,
    error,
    nextPage,
    previousPage,
    currentPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.payout)
 
  const currentLanguage = localStorage.getItem("I18N_LANGUAGE")

  
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(getPayout(filter))
  }, [dispatch, filter])

 

  const columns = useMemo(
    () => [
    
      {
        Header: props.t("Member Name"),
        accessor: "name",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {" "}
              {(cellProps.partner &&
                cellProps.partner?.firstname +
                  " " +
                  cellProps.partner?.lastname) ||
                props.t("Not provided")}
            </>
          )
        },
      },

      {
        Header: props.t("Created At"),
        accessor: "createdAt",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.createdAt &&
                moment(new Date(cellProps.createdAt)).format(
                  "YYYY-MM-DD HH:mm"
                )) ||
                props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Created By"),
        accessor: "createdBy",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.createdBy &&
                cellProps.createdBy?.firstname +
                  " " +
                  cellProps.createdBy?.lastname) ||
                props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Amount"),
        accessor: "amount",
        filterable: true,
        accessor: cellProps => {
          return <>{cellProps.amount || 0}</>
        },
      },
      {
        Header: props.t("Payout Method"),
        accessor: "payoutmethod",

        accessor: cellProps => {
          return (
            <>
              {" "}
              {cellProps.payoutMethod
                ? (currentLanguage == "ar" && cellProps.payoutMethod?.nameAr) ||
                  (currentLanguage == "en" && cellProps.payoutMethod?.nameEn) ||
                  (currentLanguage == "fr" && cellProps.payoutMethod?.nameFr)
                : props.t("Not provided")}
            </>
          )
        },
      },

      {
        Header: props.t("Approval date"),
        accessor: "releasedAt ",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.releasedAt &&
                moment(cellProps.releasedAt).format("YYYY-MM-DD HH:mm")) ||
                props.t("------")}
            </>
          )
        },
      },
      {
        Header: props.t("Approved by"),
        accessor: "releasedBy ",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.releasedBy &&
                cellProps.releasedBy?.firstname +
                  " " +
                  cellProps.releasedBy?.lastname) ||
                props.t("------")}
            </>
          )
        },
      },

      {
        Header: props.t("Paid Date"),
        accessor: "paidAt",
        filterable: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.paidAt &&
                moment(cellProps.paidAt).format("YYYY-MM-DD HH:mm")) ||
                props.t("------")}
            </>
          )
        },
      },
      {
        Header: props.t("Paid by"),
        accessor: "paidBy",
        accessor: cellProps => {
          return (
            <>
              {(cellProps.paidBy &&
                cellProps.paidBy?.firstname +
                  " " +
                  cellProps.paidBy?.lastname) ||
                props.t("------")}
            </>
          )
        },
      },
      {
        Header: props.t("Status"),
        accessor: "status",
        accessor: cellProps => {
          return <>{cellProps.status}</>
        },
      },
    ],
    []
  )

 
  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }

  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }



  
  const handleDateFromTo = date => {
    if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.startDate).add(1, "days").format("Y-MM-DD")
        }
      })
    }else {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.endDate).format("Y-MM-DD"),
        }
      })
    }
  }
  return (
    <React.Fragment>
    
              

                 
                    <TabPane tabId="1" id="summary">
                      <TableContainer
                        columns={columns}
                        data={payoutData}
                        //isGlobalFilter={true}
                        filterDate={true}
                        setDateFilter={handleDateFromTo}
                        isAddOptions={false}
                        customPageSize={100}
                        setPageNumber={setPageNumber}
                        setLimit={handleLimit}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        currentPage={currentPage}
                        total={total}
                        limit={limit}
                        loading={loading}
                        success={success}
                      />
                    </TabPane>
    </React.Fragment>
  )
}

PayoutSummary.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(PayoutSummary)
