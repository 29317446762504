import { updateBuisnessPartner, getBuisnessPartners, getBuisnessPartnersStats, getPartnerstats } from "helpers/api_helper";
import { all, put, takeEvery } from "redux-saga/effects";
// import Repository from "../../repositories/RetailersRepository";
import { createBusinessPartnerFailed, createBusinessPartnerSuccess, deleteBusinessPartnerFailed, deleteBusinessPartnerSuccess, getBusinessPartnerFailed, getBusinessPartnerStatsFailed, getBusinessPartnerStatsSuccess, getBusinessPartnerSuccess, getPartnerStatsFailed, getPartnerStatsSuccess, updateBusinessPartnerFailed, updateBusinessPartnerSuccess } from "./actions";
import { ActionTypes } from "./actions";


function* getBusinessPartnersSaga({payload}) {
  try {
    // console.log("saga partner")
    const response = yield getBuisnessPartners(payload);
    // console.log("get retailers",response)
    // console.log("get BP",response)
    yield put(getBusinessPartnerSuccess(response));
  } catch (e) {
    yield put(getBusinessPartnerFailed(e));
  }
}

function* getPartnerStatsSaga() {

  try {

    const response = yield getPartnerstats();
   
    yield put(getPartnerStatsSuccess(response));
  } catch (e) {
    console.log("error", e.response)
    yield put(getPartnerStatsFailed(e.response?.status));
  }
}
function* getBusinessPartnersStatsSaga({payload}) {
  try {
    // console.log("---------------------------------------------------------------")
    const response = yield getBuisnessPartnersStats(payload);
    // console.log("get BPStats",response)

    const { data, ...filtersStats } = response
    yield put(getBusinessPartnerStatsSuccess({filtersStats:filtersStats,dataStats:data}))
  } catch (e) {
    yield put(getBusinessPartnerStatsFailed(e));
  }
}





function* createBusinessPartnerSaga({ payload: BusinessPartner }) {
  try {
    // const result = yield new Repository().createRetailer(payload);
    // const data = yield new Repository().getRetailers({current:1 , limit:20});
    // console.log("success", BusinessPartner)
    yield put(createBusinessPartnerSuccess(BusinessPartner));
    yield put(getBusinessPartnerSuccess(payload));
    // console.log("success", payload)
  } catch (e) {
    yield put(createBusinessPartnerFailed(e));
  }
}

function* deleteBusinessPartnerSaga({ payload: BusinessPartner }) {
  try {
    // const result = yield new Repository().deleteRetailer(payload);
    //const data = yield new Repository().getRetailers();
    yield put(deleteBusinessPartnerSuccess(BusinessPartner));
    yield put(getBusinessPartnerSuccess(payload));
  } catch (e) {
    console.log("error delete:", e);
    yield put(deleteBusinessPartnerFailed(e));
  }
}

function* updateBusinessPartnerSaga({ payload: buisnessPartner }) {
  try {
    const { id, ...updatedBuisnessPartner } = buisnessPartner
    const { data } = yield updateBuisnessPartner(id, updatedBuisnessPartner);
    const response = yield getBuisnessPartners(buisnessPartner);
    yield put(getBusinessPartnerSuccess(response));
  } catch (e) {
    yield put(updateBusinessPartnerFailed(e));
  }
}




export default function* businesspartnersSaga() {
  yield all([
    takeEvery(ActionTypes.GET_BUSINESS_PARTNER_REQUEST, getBusinessPartnersSaga),
    takeEvery(ActionTypes.GET_BUSINESS_PARTNER_REQUEST, getPartnerStatsSaga),
    takeEvery(ActionTypes.GET_BUSINESS_PARTNER_STATS_REQUEST, getBusinessPartnersStatsSaga),
    takeEvery(ActionTypes.CREATE_BUSINESS_PARTNER_REQUEST, createBusinessPartnerSaga),
    takeEvery(ActionTypes.DELETE_BUSINESS_PARTNER_REQUEST, deleteBusinessPartnerSaga),
    takeEvery(ActionTypes.UPDATE_BUSINESS_PARTNER_REQUEST, updateBusinessPartnerSaga),


  ]);
}
