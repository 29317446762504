
import React, { lazy } from 'react'
import Loader from '../CommonForBoth/Loader/Loader'

const RTLTheme = lazy(() => import('./RTLTheme'));
const LTRTheme = lazy(() => import('./LTRTheme'));

const LanguageSelector = ({ children }) => {



    const language = localStorage.getItem("I18N_LANGUAGE")
    if (!language) {
        localStorage.setItem("I18N_LANGUAGE", "en")
    }

    return (
        <>
            <React.Suspense fallback={<Loader />}>
                {(localStorage.getItem("I18N_LANGUAGE") == "ar") ?
                    <RTLTheme />
                    :
                    <LTRTheme />
                    }
                {children}
            </React.Suspense>
        </>
    )
}

export default LanguageSelector 