
/**filter */
export const GET_GOVERNORATE_BY_NAME_SUCCESS = "GET_GOVERNORATE_BY_NAME_SUCCESS";
export const GET_GOVERNORATE_BY_NAME_REQUEST = "GET_GOVERNORATE_BY_NAME_REQUEST";
export const GET_GOVERNORATE_BY_NAME_FAILED = "GET_GOVERNORATE_BY_NAME_FAILED";

/**Get */
export const GET_GOVERNORATE_SUCCESS = 'GET_GOVERNORATE_SUCCESS';
export const GET_GOVERNORATE_REQUEST = 'GET_GOVERNORATE_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_GOVERNORATE_FAILED = 'GET_GOVERNORATE_FAILED';

/**Create */
export const CREATE_GOVERNORATE_SUCCESS = 'CREATE_GOVERNORATE_SUCCESS';
export const CREATE_GOVERNORATE_FAILED = 'CREATE_GOVERNORATE_FAILED';
export const CREATE_GOVERNORATE_REQUEST = 'CREATE_GOVERNORATE_REQUEST';
/**Delete */
export const DELETE_GOVERNORATE_SUCCESS = 'DELETE_GOVERNORATE_SUCCESS';
export const DELETE_GOVERNORATE_FAILED = 'DELETE_GOVERNORATE_FAILED';
export const DELETE_GOVERNORATE_REQUEST = 'DELETE_GOVERNORATE_REQUEST';
/**Update */
export const UPDATE_GOVERNORATE_SUCCESS = 'UPDATE_GOVERNORATE_SUCCESS';
export const UPDATE_GOVERNORATE_FAILED = 'UPDATE_GOVERNORATE_FAILED';
export const UPDATE_GOVERNORATE_REQUEST = 'UPDATE_GOVERNORATE_REQUEST';

export const GOVERNORATE_MESSAGES_RESET = 'GOVERNORATE_MESSAGES_RESET';
export const GOVERNORATE_MESSAGES_SUCCESS = 'GOVERNORATE_MESSAGES_SUCCESS';
export const RESET_GOVERNORATE_MESSAGES_SUCCESS = 'RESET_GOVERNORATE_MESSAGES_SUCCESS';
export const RESET_GOVERNORATE_MESSAGES = 'RESET_GOVERNORATE_MESSAGES';

