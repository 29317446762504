import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import * as Yup from "yup"
import { useFormik } from "formik"
//import components
//redux
import { useDispatch, useSelector } from "react-redux"


import {
  Col,
  Row,
  Card,
  CardBody,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import { resetConfigssMessages, updateConfigs } from "store/actions"
import SuccessModal from "components/Common/SuccessModal"
import { checkPermission } from "helpers/functions"

function MMPTable(props) {
  //meta title
  document.title = "Kasb"

  const [purchase, setPurchase] = useState(null)
  const [showMessage, setShowMessage] = useState(false)
  const { configs, error, success } = useSelector(state => state.settings)
  const userRoles = useSelector(state => state.Login.user?.roles)


  const dispatch = useDispatch()


  useEffect(() => {
    if (configs.length > 0) {
      setPurchase(configs.find(el => el.key == "monthly_minimum_purchase"))
    }
  }, [configs])

  useEffect(() => {
    if (success == "UPDATE") {
      setShowMessage(true)
    }
    if (error == "UPDATE") {

    }
  }, [success, error])


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (purchase && purchase.id) || "",
      commission: (purchase && purchase.value) || "",
    },
    // validationSchema: Yup.object({
    //   commission: Yup.number().min(-1, 'Commission must be equal or grearter than 0').required("Please enter commission"),
    // }),
    onSubmit: values => {
      if (checkPermission(userRoles, ["MasterData", "Settings", "UpdateMMP"])) {
        const updatedPurchase = {
          id: purchase.id,
          value: values.commission,
        }
        // update order
        dispatch(updateConfigs(updatedPurchase))
        setTimeout(() => {
          dispatch(resetConfigssMessages())
        }, 500)
        //validation.resetForm()
      }

    },
  })


  return (
    <React.Fragment>
      {/* <SuccessModal
        show={showMessage}
        onCloseClick={() => setShowMessage(false)}
        message={(error && props.t("MMP error")) || (success && props.t("MMP success"))}
      /> */}

      <Row form>
        <Col className="col-lg-4">
          <div className="mb-3">
            <Label className="form-label">{props.t("Monthly minimum purchase")}</Label>
            <Input
              name="commission"
              type="number"
              validate={{
                required: { value: true },
              }}
              disabled={!checkPermission(userRoles, ["MasterData", "Settings", "UpdateMMP"])}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.commission || ""}
              invalid={
                validation.touched.commission && validation.errors.commission
                  ? true
                  : false
              }
            />
            {validation.touched.commission && validation.errors.commission ? (
              <FormFeedback type="invalid">
                {validation.errors.commission}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        {checkPermission(userRoles, ["MasterData", "Settings", "UpdateMMP"]) &&
          <Col className="col-lg-3">
            <button
              onClick={validation.submitForm}
              className="btn btn-success save-user mt-4"
            >
              {props.t("Save")}
            </button>
          </Col>}
      </Row>


    </React.Fragment>
  )
}
MMPTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(MMPTable)
