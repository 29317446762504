import React, { useEffect, useState } from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { upload } from "helpers/api_helper"
import { useSelector, useDispatch } from "react-redux"
import { createMDMBrand, updateMDMBrand } from "store/actions"
import { withTranslation } from "react-i18next"

const BrandModal = props => {
  const dispatch = useDispatch()
  const { modal, toggle, brand, isEdit } = props
  const [loading, setLoading] = useState(false)
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (brand && brand.id) || "",
      nameEn: (brand && brand.nameEn) || "",
      nameFr: (brand && brand.nameFr) || "",
      nameAr: (brand && brand.nameAr) || "",
      type: (brand && brand.type) || "brand",
    },
    validationSchema: Yup.object({
      nameEn: Yup.string().required(props.t("Please Enter Your English name")),
      nameFr: Yup.string().required(props.t("Please Enter Your French name")),
      nameAr: Yup.string().required(props.t("Please Enter Your Arabic Name")),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedBrand = {
          id: brand.id,
          nameEn: values.nameEn,
          nameFr: values.nameFr,
          nameAr: values.nameAr,
          imageUrl: values.imageUrl,
        }
        // update order
        dispatch(updateMDMBrand(updatedBrand))
        validation.resetForm()
      } else {
        const newBrand = {
          imageUrl: values["imageUrl"],
          nameEn: values["nameEn"],
          nameFr: values["nameFr"],
          nameAr: values["nameAr"],
          type: values["type"],
        }
        // console.log("newBrand", newBrand)
        // save new brand
        dispatch(createMDMBrand(newBrand))
        validation.resetForm()
      }
      toggle()
    },
  })
  const handelImage = event => {
    setLoading(true)
    let formData = new FormData()
    formData.set("file", event.target.files[0])
    upload(formData)
      .then(({ path }) => {
        setLoading(false)
        validation.setFieldValue("imageUrl", path)
      })
      .catch(e => {
        setLoading(false)
        // console.log(e.response)
      })
  }


  const onRemove = () => {
    validation.setFieldValue("imageUrl", "")
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Edit Brand") : props.t("Add Brand")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col className="col-12">
              <div className="mb-5">
                <Label className="form-label">Logo</Label>
                <div className="row">
                  <div className="col-12">
                    <div className="position-relative">
                      <Input
                        className="position-absolute"
                        // id="inputGroupFile04"
                        // aria-describedby="inputGroupFileAddon04"
                        // aria-label="Upload"
                        name="imageUrl"
                        type="file"
                        validate={{
                          required: { value: true },
                        }}
                        accept=".png, .jpg, .jpeg"
                        onChange={handelImage}
                        onBlur={validation.handleBlur}
                        //value={validation.values.logo || ""}
                        invalid={
                          validation.touched.logo && validation.errors.logo
                            ? true
                            : false
                        }
                      />
                      {loading && (
                        <Spinner className="mt-2 float-end me-3" size="sm" />
                      )}
                      {/* <i
                          className="far fa-times-circle"
                          id="inputGroupFileAddon04" type="button" onClick={onRemove}
                        ></i>
                        <i className="dripicons-document-delete"/> */}
                    </div>
                  </div>
                </div>
                {validation.touched.logo && validation.errors.logo ? (
                  <FormFeedback type="invalid">
                    {validation.errors.logo}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("English Name")}</Label>
                <Input
                  name="nameEn"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameEn || ""}
                  invalid={
                    validation.touched.nameEn && validation.errors.nameEn
                      ? true
                      : false
                  }
                />
                {validation.touched.nameEn && validation.errors.nameEn ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameEn}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("French Name")}</Label>
                <Input
                  name="nameFr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameFr || ""}
                  invalid={
                    validation.touched.nameFr && validation.errors.nameFr
                      ? true
                      : false
                  }
                />
                {validation.touched.nameFr && validation.errors.nameFr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameFr}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Arabic Name")}</Label>
                <Input
                  name="nameAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameAr || ""}
                  invalid={
                    validation.touched.nameAr && validation.errors.nameAr
                      ? true
                      : false
                  }
                />
                {validation.touched.nameAr && validation.errors.nameAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameAr}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(BrandModal)
