import { ActionTypess } from "./actions"

const INIT_STATE = {
  payoutData: [],
  payoutDataStatus: [],
  payout: {},
  payoutStats: {},
  error: null,
  success: null,
  loading: false,
  nextPage: null,
  previousPage: null,
  limit: null,
  total: null,
  currentPage: null
}

const payout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypess.GET_PAYOUT:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case ActionTypess.GET_PAYOUT_SUCCESS:
      const { data, ...filters } = action.payload
      return {
        ...state,
        payoutData: data,
        ...filters,
        loading: false
      }
    case ActionTypess.GET_PAYOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ActionTypess.GET_PAYOUT_STATS:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case ActionTypess.GET_PAYOUT_STATS_SUCCESS:
      return {
        ...state,
        payoutStats: action.payload,
        loading: false
      }
    case ActionTypess.GET_PAYOUT_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ActionTypess.GET_PAYOUT_BY_NAME:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case ActionTypess.GET_PAYOUT_BY_NAME_SUCCESS:
      const { dataStatus, ...filtersStatus } = action.payload
      return {
        ...state,
        payoutDataStatus: dataStatus,
        ...filtersStatus,
        loading: false
      }
    case ActionTypess.GET_PAYOUT_BY_NAME_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ActionTypess.UPDATE_PAYOUT:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case ActionTypess.UPDATE_PAYOUT_SUCCESS:
      return {
        ...state,
        payoutData: state.payoutData.map(payout =>
          payout.id.toString() === action.payload.id.toString()
            ? { payout, ...action.payload }
            : payout
        ),
        success: "UPDATE",
        loading: false,
      }
    case ActionTypess.UPDATE_PAYOUT_FAILED:
      return {
        ...state,
        error: "FAILED UPDATE",
        loading: false,
      }

    case ActionTypess.CREATE_PAYOUT:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case ActionTypess.CREATE_PAYOUT_SUCCESS:
      const { payoutData, ...payoutFilters } = action.payload
      return {
        ...state,
        payoutData: payoutData,
        ...payoutFilters,
        loading: false
      }
    case ActionTypess.CREATE_PAYOUT_FAIL:
      return {
        ...state,
        error: "FAILED CREATE",
        loading: false,
      }


    default:
      return state
  }
}

export default payout
