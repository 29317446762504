import { combineReducers } from "redux"
import { ActionTypes } from "./actions"

export const initialState = {
  loading: false,
  BusinessPartnerData: [],
  PartnerStatsData: [],
  PartnerStats: {},
  success: "",
  error: "",
  nextPage: null,
  previousPage: null,
  limit: null,
  total:null,
  currentPage: null
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_BUSINESS_PARTNER_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      }
    case ActionTypes.GET_BUSINESS_PARTNER_SUCCESS:
      const {data, ...filters} = action.payload
      // console.log("data",data)
      return {
        ...state,
        BusinessPartnerData: data,
        ...filters,
        success: "GET",
        loading: false,
        success: "GET"
      }
    case ActionTypes.GET_BUSINESS_PARTNER_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      }

      case ActionTypes.GET_PARTNER_STATS_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case ActionTypes.GET_PARTNER_STATS_SUCCESS:
        
        return {
          ...state,
          PartnerStats: action.payload,
          success: "GET",
          loading: false,
        }
      case ActionTypes.GET_PARTNER_STATS_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
    case ActionTypes.GET_BUSINESS_PARTNER_STATS_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      }
    case ActionTypes.GET_BUSINESS_PARTNER_STATS_SUCCESS:
      const {dataStats, ...filtersStats} = action.payload
      // console.log("PartnerStatsData",action.payload)
      // console.log("dataStats",dataStats)

      return {
        ...state,
        PartnerStatsData: dataStats,
        ...filtersStats,
        success: "GET",
        loading: false,
        success: "GET"
      }
      
    case ActionTypes.GET_BUSINESS_PARTNER_STATS_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      }


    case ActionTypes.CREATE_BUSINESS_PARTNER_REQUEST:
      return {
        ...state,
        BusinessPartnerData: [...state.BusinessPartnerData, action.payload],
        loading: true,
        success: "CREATE",
        error: "",
      }
    case ActionTypes.CREATE_BUSINESS_PARTNER_SUCCESS:
      return {
        ...state,
        BusinessPartnerData: [...state.BusinessPartnerData, action.payload],
        success: "CREATE",
        loading: false,
      }
    case ActionTypes.CREATE_RETAILER_FAILED:
      return {
        ...state,
        error: "FAILED CREATE",
        loading: false,
      }
    case ActionTypes.DELETE_BUSINESS_PARTNER_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case ActionTypes.DELETE_BUSINESS_PARTNER_SUCCESS:
      return {
        ...state,
        BusinessPartnerData: state.BusinessPartnerData.filter(
          retailers => retailers.id.toString() !== action.payload.id.toString()
        ),
        success: "DELETE",
        loading: false,
      }

    case ActionTypes.DELETE_BUSINESS_PARTNER_FAILED:
      return {
        ...state,
        error: "FAILED DELETE",
        loading: false,
      }
    case ActionTypes.UPDATE_BUSINESS_PARTNER_REQUEST:
      return {
        ...state,
        loading: true,
        success: "UPDATE",
        error: "",
      }
    case ActionTypes.UPDATE_BUSINESS_PARTNER_SUCCESS:
      return {
        ...state,
        BusinessPartnerData: state.BusinessPartnerData.map(buisnessPartner =>
          buisnessPartner.id.toString() === action.payload.id.toString()
            ? { buisnessPartner, ...action.payload }
            : buisnessPartner
        ),
        success: "UPDATE",
        loading: false,
      }
    case ActionTypes.UPDATE_BUSINESS_PARTNER_FAILED:
      return {
        ...state,
        error: "FAILED UPDATE",
        loading: false,
      }
    default:
      return state
  }
}

export default reducer
