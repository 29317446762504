import {
    GET_LOG,
    GET_LOG_FAIL,
    GET_LOG_SUCCESS,
  } from "./actionTypes"
  
  export const getLogs = (filter) => ({
    type: GET_LOG,
    payload: filter
  })
  
  export const getLogsSuccess = logs => ({
    type: GET_LOG_SUCCESS,
    payload: logs,
  })
  
  export const getLogsFailed = error => ({
    type: GET_LOG_FAIL,
    payload: error,
  })
  