import React, { useEffect, useState } from "react"
import {
  Col,
  Row,
  Label,
  FormFeedback,
  Input,
  Form,
  Container,
  CardBody,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  FormGroup,
} from "reactstrap"
import * as Yup from "yup"
import { createRole, getRoles, requestUser, updateRole } from "store/actions"
import { resetUsersMessages as onReset } from "store/users/actions"
import Breadcrumbs from "components/Common/Breadcrumb"

import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { withTranslation } from "react-i18next"
import { getPermissions } from "helpers/api_helper"
import SuccessModal from "components/Common/SuccessModal"
import classnames from "classnames"
import { useHistory, useParams } from "react-router-dom"
import { reduceArray } from "helpers/functions"

const RoleAddEdit = props => {
  const { role, modal, toggle } = props
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
  })
  const params = useParams()
  const id = params.id
  const dispatch = useDispatch()
  const [permissions, setPermissions] = useState([])
  const [roleEntity, setRoleEntity] = useState([])
  const [message, setMessage] = useState("")
  const [successModal, setSuccessModal] = useState(false)
  const {
    roles,
    success,
    nextPage,
    previousPage,
    limit,
    total,
    currentPage,
    loading,
  } = useSelector(state => state.Roles)
  const [activeTab, setactiveTab] = useState(0)
  const [activeTabVartical, setoggleTabVertical] = useState(0)
  const [isEdit, setIsEdit] = useState(id != undefined ? true : false)
  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])
  const accessToken = useSelector(state => state.Login.accessToken)

  const history = useHistory()

  useEffect(() => {
    if (roles.length > 0 && id != undefined) {
      setRoleEntity(roles.find(data => data.id == id))
    }
  }, [roles])

  useEffect(() => {
    getPermissions()
      .then(data => {
        setPermissions(reduceArray(data))
      })
      .catch(e => {
        // console.log(e.response)
      })
  }, [])

  useEffect(() => {
    // console.log("++++++",success)
    console.log("success", success)
    if (success === "DELETE") {
      setSuccessModal(true)
      setMessage("Role deleted successfully !")
      dispatch(onReset())
    }
    if (success === "CREATE") {
      setSuccessModal(true)
      setMessage("Role created successfully !")
      dispatch(onReset())
    } else if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("Role edited successfully !")
      dispatch(onReset())
    }
  }, [success])

  const toggleTabVertical = tab => {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]
      setoggleTabVertical(tab)
      setPassedStepsVertical(modifiedSteps)
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  // console.log(roleEntity)

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (roleEntity && roleEntity.name) || "",
      permissions: (roleEntity && roleEntity.permissions) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter the role"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedRole = {
          id: roleEntity.id,
          name: values.name,
          permissions: values.permissions,
        }
        //console.log(updatedRole)
        // update permisisons
        dispatch(updateRole(updatedRole))
        dispatch(requestUser({accessToken: accessToken,history: props.history,pathname: window.location.pathname, }))
        history.push(`/roles-list`)

        //validation.resetForm()
        //setIsEdit(false);
      } else {
        const newRole = {
          name: values["name"],
          permissions: values["permissions"],
        }
        // save new user
        dispatch(createRole(newRole))
        history.push(`/roles-list`)
        validation.resetForm()
      }
    },
  })

  return (
    <React.Fragment>
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={props.t("Users")}
            breadcrumbItem={props.t("Roles Managment")}
          />
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row form>
              <Col xs={12}>
                <Row xs={4}>
                  <div className="mb-3">
                    <Label className="form-label">{props.t("Role")}</Label>
                    <Input
                      name="name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      invalid={
                        validation.touched.name && validation.errors.name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <CardBody>
                  <h4 className="card-title mb-4">{props.t("Permissions")}</h4>
                  <div className="vertical-wizard wizard clearfix vertical">
                    <div className="steps clearfix">
                      <ul>
                        {permissions.map((element, index) => (
                          <NavItem
                            key={index}
                            className={classnames({
                              current: activeTabVartical === index,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTabVartical === index,
                              })}
                              onClick={() => {
                                toggleTabVertical(index)
                                toggleTab(activeTabVartical)
                              }}
                            >
                              <span className="number">{index + 1}</span>
                              {element.module}
                            </NavLink>
                          </NavItem>
                        ))}
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        {permissions.map((element, index) => (
                          <TabPane key={index} tabId={index}>
                            {element?.[element.module]?.map((level, index) => (
                              <Row key={index} xs={12}>
                                <Label className="font-size-14 font-weight-bold">
                                  {level.model}
                                </Label>
                                {level?.[level.model]?.length > 0 &&
                                  level?.[level.model]?.map(permission => (
                                    <Col key={permission?.id} xs={4}>
                                      <div className="form-check mb-1">
                                        <Input
                                          id={permission?.id}
                                          type="checkbox"
                                          name="permissions"
                                          className="form-check-input"
                                          onClick={e => {
                                            if (!e.target.checked) {
                                              validation.setFieldValue(
                                                "permissions",
                                                [
                                                  ...validation.values
                                                    .permissions,
                                                  permission,
                                                ]
                                              )
                                            } else {
                                              validation.setFieldValue(
                                                "permissions",
                                                [
                                                  ...validation.values.permissions.filter(
                                                    el => el.id != permission.id
                                                  ),
                                                ]
                                              )
                                            }
                                          }}
                                          onBlur={validation.handleBlur}
                                          checked={
                                            validation.values?.permissions?.some(
                                              el => el.id == permission.id
                                            ) || ""
                                          }
                                          invalid={
                                            validation.touched.permissions &&
                                            validation.errors.permissions
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.permissions &&
                                        validation.errors.permissions?.length <
                                          1 ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.permissions}
                                          </FormFeedback>
                                        ) : null}
                                        <Label
                                          className="form-check-label"
                                          htmlFor="admin"
                                        >
                                          {permission?.action}
                                        </Label>
                                      </div>
                                    </Col>
                                  ))}
                              </Row>
                            ))}
                          </TabPane>
                        ))}
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Col>
            </Row>

            <Row>
              <Col xl="1">
                <div className="text-end">
                  <button type="submit" className="btn btn-primary save-user">
                    {props.t("Save")}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(RoleAddEdit)
