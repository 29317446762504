import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {CREATE_ROLE, DELETE_ROLE, GET_ROLES, UPDATE_ROLE } from "./actionTypes"

import {
  getRolesSuccess,
  getRolesFail,
  updateRoleFail,
  updateRoleSuccess,
  createRoleFail,
  deleteRoleSuccess,
  deleteRoleFail,
  createRoleSuccess,
 
} from "./actions"
import { createRole, deleteRoles, getRoles, updateRole } from "helpers/api_helper"

//Include Both Helper File with needed methods

function* fetchRoles({payload}) {
  try {
    const response = yield getRoles(payload)
    yield put(getRolesSuccess(response))
  } catch (error) {
    yield put(getRolesFail(error))
  }
}

function* updateRoleSaga({ payload: role }) {
  try {
    const {id, ...updatedRole} = role
    updatedRole.permissions = updatedRole.permissions?.map(el => {return el.id})
    const response = yield updateRole(id, updatedRole)
    yield put(updateRoleSuccess(role))
  } catch (error) {
    yield put(updateRoleFail(error.response))
  }
}

function* createRoleSaga({ payload: role }) {

  try {
    //console.log("----", role)
    role.permissions = role.permissions?.map(el => {return el.id})
    const {response} = yield createRole(role)
    const data = yield getRoles({limit: 20, page: 1})
    yield put(createRoleSuccess(data))
  } catch (error) {
    yield put(createRoleFail(error))
  }
}

function* deleteRoleSaga({ payload: id }) {
  try {
    const response = yield deleteRoles(id)
    yield put(deleteRoleSuccess(id))
  } catch (error) {
    yield put(deleteRoleFail(error))
  }
}

function* rolesSaga() {
  yield takeEvery(GET_ROLES, fetchRoles)
  yield takeEvery(CREATE_ROLE, createRoleSaga)
  yield takeEvery(DELETE_ROLE, deleteRoleSaga)
  yield takeEvery(UPDATE_ROLE, updateRoleSaga)
}

export default rolesSaga;
