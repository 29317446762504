
import { cities } from "../../../common/data/cities";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {

  createMDMGovernorateFailed,
  createMDMGovernorateSuccess,
  deleteMDMGovernorateFailed,
  deleteMDMGovernorateSuccess,
  getMDMGovernoratesFailed,
  getMDMGovernoratesSuccess,
  updateMDMGovernorateFailed,
  updateMDMGovernorateSuccess,
} from "./actions"
import {
  GOVERNORATE_MESSAGES_RESET,
  CREATE_GOVERNORATE_REQUEST,
  DELETE_GOVERNORATE_REQUEST,
  GET_GOVERNORATE_BY_NAME_REQUEST,
  GET_GOVERNORATE_REQUEST,
  RESET_GOVERNORATE_MESSAGES,
  UPDATE_GOVERNORATE_REQUEST
} from "./actionTypes";



import { getGovernorates, postGovernorates, putGovernorates } from "helpers/api_helper";



function* getGovernoratesSaga({payload}) {
  try {
    const response = yield getGovernorates(payload || {page: 1, limit: 1000});
    yield put(getMDMGovernoratesSuccess(response))
  } catch (e) {
    yield put(getMDMGovernoratesFailed(e))
  }
}

function* getGovernoratesBynameSaga() {

}

function* getGovernoratesFlatSaga({ payload }) {
  try {
    // const result = yield new Repository().getCategoriesFlat(payload)
    // console.log(result)
    // yield put(getCategoriesFlatSuccess(result))
  } catch (e) {
    yield put(getMDMGovernoratesFailed(e))
  }
}

function* createGovernorateSaga({ payload: governorate }) {
  try {

    const data = yield postGovernorates(governorate);
    const response = yield getGovernorates({limit: 20, page: 1});
    yield put(getMDMGovernoratesSuccess(response))

  } catch (e) {
    yield put(createMDMGovernorateFailed(e))
  }
}

function* deleteGovernorateSaga({ payload: id }) {
  try {
    // const result = yield new Repository().deleteCategory(payload)
    // const data = yield new Repository().getCategories({ current: 1, limit: 20 })
    yield put(deleteMDMGovernorateSuccess(id))
  } catch (e) {
    yield put(deleteMDMGovernorateFailed(e))
  }
}

function* updateGovernorateSaga({ payload: governorate }) {
  try {
    // separer l id & objet governorate
    const { id, ...updatedGovernorate } = governorate
    const data = yield putGovernorates(id, updatedGovernorate);
    const response = yield getGovernorates({limit: 20, page: 1});
    yield put(getMDMGovernoratesSuccess(response))
  } catch (e) {
    yield put(updateMDMGovernorateFailed(e))
  }
}


function* resetGovernorateMessagesSaga() {
  yield put(resetGovernorateMessagesSuccess())
}



export default function* governorateSaga() {
  yield all([
    takeEvery(GET_GOVERNORATE_REQUEST, getGovernoratesSaga),
    takeEvery(GET_GOVERNORATE_BY_NAME_REQUEST, getGovernoratesBynameSaga),
    takeEvery(CREATE_GOVERNORATE_REQUEST, createGovernorateSaga),
    takeEvery(RESET_GOVERNORATE_MESSAGES, resetGovernorateMessagesSaga),
    takeEvery(DELETE_GOVERNORATE_REQUEST, deleteGovernorateSaga),
    takeEvery(UPDATE_GOVERNORATE_REQUEST, updateGovernorateSaga),
  ])
}
