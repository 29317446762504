import { all, call, put, takeEvery } from "redux-saga/effects";
import {

  createMDMCountryFailed,
  createMDMCountrySuccess,
  deleteMDMCountryFailed,
  deleteMDMCountrySuccess,
  getMDMCountriesFailed,
  getMDMCountriesSuccess,
  updateMDMCountryFailed,
  updateMDMCountrySuccess,
} from "./actions"
import {
  COUNTRY_MESSAGES_RESET,
  CREATE_COUNTRY_REQUEST,
  DELETE_COUNTRY_REQUEST,
  GET_COUNTRY_BY_NAME_REQUEST,
  GET_COUNTRY_REQUEST,
  RESET_COUNTRY_MESSAGES,
  UPDATE_COUNTRY_REQUEST
} from "./actionTypes";



import { getCountries, postCountries, putCountries } from "helpers/api_helper";



function* getCountriesSaga({payload}) {
  try {
    const response = yield getCountries(payload || {page: 1, limit: 1000});
    yield put(getMDMCountriesSuccess(response))
  } catch (e) {
    yield put(getMDMCountriesFailed(e))
  }
}

function* getCountriesBynameSaga() {

}

function* getCountriesFlatSaga({ payload }) {
  try {
    // const result = yield new Repository().getCategoriesFlat(payload)
    console.log(result)
    // yield put(getCategoriesFlatSuccess(result))
  } catch (e) {
    yield put(getMDMCountriesFailed(e))
  }
}

function* createCountrySaga({ payload: country }) {
  try {
    const data = yield postCountries(country);
    const response = yield getCountries({limit: 20, page: 1});
    yield put(getMDMCountriesSuccess(response))

  } catch (e) {
    yield put(createMDMCountryFailed(e))
  }
}

function* deleteCountrySaga({ payload: id }) {
  try {
    // const result = yield new Repository().deleteCategory(payload)
    // const data = yield new Repository().getCategories({ current: 1, limit: 20 })
    yield put(deleteMDMCountrySuccess(id))
  } catch (e) {
    yield put(deleteMDMCountryFailed(e))
  }
}

function* updateCountrySaga({ payload: country }) {
  try {
    // separer l id & objet country
    const { id, ...updatedCountry } = country

    const data = yield putCountries(id, updatedCountry);
    const response = yield getCountries({limit: 20, page: 1});
    yield put(getMDMCountriesSuccess(response))
  } catch (e) {
    yield put(updateMDMCountryFailed(e))
  }
}

function* resetCountryMessagesSaga() {
  yield put(resetCountryMessagesSuccess())
}




export default function* countrySaga() {
  yield all([
    takeEvery(GET_COUNTRY_REQUEST, getCountriesSaga),
    takeEvery(GET_COUNTRY_BY_NAME_REQUEST, getCountriesBynameSaga),
    takeEvery(CREATE_COUNTRY_REQUEST, createCountrySaga),
    takeEvery(DELETE_COUNTRY_REQUEST, deleteCountrySaga),
    takeEvery(UPDATE_COUNTRY_REQUEST, updateCountrySaga),
    takeEvery(RESET_COUNTRY_MESSAGES, resetCountryMessagesSaga),
  ])
}
