export const actionTypes = {
    GET_RETAILERS_SUCCESS: 'GET_RETAILERS_SUCCESS',
    GET_RETAILERS_REQUEST: 'GET_RETAILERS_REQUEST',
    GET_RETAILERS_FAILED: 'GET_RETAILERS_FAILED',
    GET_RETAILERS_STATS_SUCCESS: 'GET_RETAILERS_STATS_SUCCESS',
    GET_RETAILERS_STATS_REQUEST: 'GET_RETAILERS_STATS_REQUEST',
    GET_RETAILERS_STATS_FAILED: 'GET_RETAILERS_STATS_FAILED',
    GET_RETAILERSBYNAME_SUCCESS: 'GET_RETAILERSBYNAME_SUCCESS',
    GET_RETAILERS_BY_ID_REQUEST: 'GET_RETAILERS_BY_ID_REQUEST',
    GET_RETAILERSBYNAME_FAILED: 'GET_RETAILERSBYNAME_FAILED',
    CREATE_RETAILER_REQUEST: 'CREATE_RETAILER_REQUEST',
    CREATE_RETAILER_SUCCESS: 'CREATE_RETAILER_SUCCESS',
    CREATE_RETAILER_FAILED: 'CREATE_RETAILER_FAILED',
    DELETE_RETAILER_SUCCESS: 'DELETE_RETAILER_SUCCESS',
    DELETE_RETAILER_FAILED: 'DELETE_RETAILER_FAILED',
    DELETE_RETAILER_REQUEST: 'DELETE_RETAILER_REQUEST',
    UPDATE_RETAILER_SUCCESS: 'UPDATE_RETAILER_SUCCESS',
    UPDATE_RETAILER_FAILED: 'UPDATE_RETAILER_FAILED',
    UPDATE_RETAILER_REQUEST: 'UPDATE_RETAILER_REQUEST',
    RESET_RETAILERS_MESSAGES: 'RESET_RETAILERS_MESSAGES',
    RESET_RETAILERS_MESSAGES_SUCCESS: 'RESET_RETAILERS_MESSAGES_SUCCESS',
};

export function getRetailers(filter) {
    return { type: actionTypes.GET_RETAILERS_REQUEST,
    payload:filter};
}

export function getRetailersSuccess(retailers) {
   
    return { type: actionTypes.GET_RETAILERS_SUCCESS, payload:retailers };
}

export function getRetailersFailed(payload) {
   
    return { type: actionTypes.GET_RETAILERS_FAILED, payload };
}
export function getRetailersStats() {
    return { type: actionTypes.GET_RETAILERS_STATS_REQUEST,
        
   };
}

export function getRetailersStatsSuccess(retailers) {
   
    return { type: actionTypes.GET_RETAILERS_STATS_SUCCESS,
        payload:retailers 
    };
}

export function getRetailersStatsFailed(payload) {
   
    return { type: actionTypes.GET_RETAILERS_STATS_FAILED, payload };
}

export function getRetailersById(name) {
    return { type: actionTypes.GET_RETAILERS_BY_ID_REQUEST,
    payload:name};
}

export function getRetailersByNameSuccess(payload) {
   
    return { type: actionTypes.GET_RETAILERSBYNAME_SUCCESS, payload };
}

export function getRetailersByNameFailed(payload) {
   
    return { type: actionTypes.GET_RETAILERSBYNAME_FAILED, payload };
}

export function createRetailer(retailer) {
    return { type: actionTypes.CREATE_RETAILER_REQUEST, payload: retailer};
}

export function createRetailerSuccess(retailers) {
   
    return { type: actionTypes.CREATE_RETAILER_SUCCESS, payload: retailers };
}

export function createRetailerFailed(payload) {
   
    return { type: actionTypes.CREATE_RETAILER_FAILED, payload };
}

export function deleteRetailer(id) {
    return { type: actionTypes.DELETE_RETAILER_REQUEST, payload: id};
}

export function deleteRetailerSuccess(retailers) {
   
    return { type: actionTypes.DELETE_RETAILER_SUCCESS, payload: retailers };
}

export function deleteRetailerFailed(payload) {
   
    return { type: actionTypes.DELETE_RETAILER_FAILED, payload };
}

export function updateRetailer(retailer) {
    return { type: actionTypes.UPDATE_RETAILER_REQUEST, payload: retailer};
}

export function updateRetailerSuccess(retailers) {
   
    return { type: actionTypes.UPDATE_RETAILER_SUCCESS, payload: retailers};
}

export function updateRetailerFailed(retailers) {
   
    return { type: actionTypes.UPDATE_RETAILER_FAILED, retailers };
}

export function resetRetailersMessages(){
      return { type: actionTypes.RESET_RETAILERS_MESSAGES};
}
  
  export function resetRetailersMessagesSuccess () {
    return { type: actionTypes.RESET_RETAILERS_MESSAGES_SUCCESS};
  }
