
import {
    GOVERNORATE_MESSAGES_RESET,
    CREATE_GOVERNORATE_FAILED,
    CREATE_GOVERNORATE_REQUEST,
    CREATE_GOVERNORATE_SUCCESS,
    DELETE_GOVERNORATE_FAILED,
    DELETE_GOVERNORATE_REQUEST,
    DELETE_GOVERNORATE_SUCCESS,
    GET_GOVERNORATE_BY_NAME_FAILED,
    GET_GOVERNORATE_BY_NAME_REQUEST,
    GET_GOVERNORATE_BY_NAME_SUCCESS,
    GET_GOVERNORATE_FAILED,
    GET_GOVERNORATE_REQUEST,
    GET_GOVERNORATE_SUCCESS,
    RESET_GOVERNORATE_MESSAGES,
    RESET_GOVERNORATE_MESSAGES_SUCCESS,
    UPDATE_GOVERNORATE_FAILED,
    UPDATE_GOVERNORATE_REQUEST,
    UPDATE_GOVERNORATE_SUCCESS
} from "./actionTypes.js"

export const getGovernoratesByName = name => ({
    type: GET_GOVERNORATE_BY_NAME_REQUEST,
    payload: name
})


export const getGovernoratesByNameSuccess = payload => ({
    type: GET_GOVERNORATE_BY_NAME_SUCCESS,
    payload
})


export const getGovernoratesByNameFailed = payload => ({
    type: GET_GOVERNORATE_BY_NAME_FAILED,
    payload
})




export const getMDMGovernorates = (filter) => ({
    type: GET_GOVERNORATE_REQUEST,
    payload:filter
})



export const getMDMGovernoratesSuccess = GOVERNORATE => ({
    type: GET_GOVERNORATE_SUCCESS,
    payload: GOVERNORATE
})

export const getMDMGovernoratesFailed = payload => ({
    type: GET_GOVERNORATE_FAILED,
    payload
})

export const createMDMGovernorate = governorate => ({
    type: CREATE_GOVERNORATE_REQUEST,
    payload: governorate
})

export const createMDMGovernorateSuccess = governorate => ({
    type: CREATE_GOVERNORATE_SUCCESS,
    payload: governorate
})

export const createMDMGovernorateFailed = payload => ({
    type: CREATE_GOVERNORATE_FAILED,
    payload
})

export const deleteMDMGovernorate = id => ({
    type: DELETE_GOVERNORATE_REQUEST,
    payload: id
})

export const deleteMDMGovernorateSuccess = id => ({
    type: DELETE_GOVERNORATE_SUCCESS,
    payload: id
})

export const deleteMDMGovernorateFailed = id => ({
    type: DELETE_GOVERNORATE_FAILED,
    payload: id
})

export const updateMDMGovernorate = (governorate )=> ({
    type: UPDATE_GOVERNORATE_REQUEST,
    payload: governorate
})

export const updateMDMGovernorateSuccess = (governorate ) => ({
    type: UPDATE_GOVERNORATE_SUCCESS,
    payload: governorate
})

export const updateMDMGovernorateFailed = payload => ({
    type: UPDATE_GOVERNORATE_FAILED,
    payload
})

export const resetMDMGovernorateMessages = () => ({
    type: GOVERNORATE_MESSAGES_RESET
}
)


export const resetGovernorateMessages = () => ({
    type: RESET_GOVERNORATE_MESSAGES,
  })
  
  export const resetGovernorateMessagesSuccess = () => ({
    type: RESET_GOVERNORATE_MESSAGES_SUCCESS,
  })
