import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  USER_LOADED,
  USER_REQUESTED,
} from "./actionTypes"
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const initialState = {
  error: "",
  loading: false,
  success: null,
  user: {},
  accessToken: null
}

const login = persistReducer(
  { storage, key: 'kasseb-auth', whitelist: ['user', 'accessToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case LOGIN_USER:
        state = {
          ...state,
          loading: true,
        }
        break
      case USER_REQUESTED:
        state = {
          ...state,
          accessToken: action.payload.accessToken,
        }
        break
      case LOGIN_SUCCESS:
        state = {
          ...state,
          user: action.payload,
          loading: false,
        }
        break
      case USER_LOADED:
        state = {
          ...state,
          user: action.payload.user,
          loading: false,
        }
        break
      case LOGOUT_USER:
        state = { ...initialState }
        break
      // case LOGOUT_USER_SUCCESS:
      //   state = { ...state }
      //   break
      case API_ERROR:
        state = { 
          ...state, 
          error: "LOGIN_ERROR", 
          loading: false 
        }
        break
      default:
        state 
        break
    }
    return state
  })

export default login
