import {
  GET_ROLES,
  GET_ROLES_FAIL,
  GET_ROLES_SUCCESS,
  CREATE_ROLE,
  CREATE_ROLE_FAIL,
  CREATE_ROLE_SUCCESS,
  UPDATE_ROLE,
  UPDATE_ROLE_FAIL,
  UPDATE_ROLE_SUCCESS,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,

} from "./actionTypes"

export const getRoles = filters => ({
  type: GET_ROLES,
  payload: filters
})

export const getRolesSuccess = roles => ({
  type: GET_ROLES_SUCCESS,
  payload: roles,
})

export const getRolesFail = roles => ({
  type: GET_ROLES_FAIL,
  payload: roles,
})

export const createRole = role => ({
  type: CREATE_ROLE,
  payload: role
})

export const createRoleSuccess = role => ({
  type: CREATE_ROLE_SUCCESS,
  payload: role,
})

export const createRoleFail = error => ({
  type: CREATE_ROLE_FAIL,
  payload: error,
})
export const updateRole = role => ({
  type: UPDATE_ROLE,
  payload: role
})

export const updateRoleSuccess = role => ({
  type: UPDATE_ROLE_SUCCESS,
  payload: role,
})

export const updateRoleFail = error => ({
  type: UPDATE_ROLE_FAIL,
  payload: error,
})

export const deleteRole = role => ({
  type: DELETE_ROLE,
  payload: role,
})

export const deleteRoleSuccess = id => ({
  type: DELETE_ROLE_SUCCESS,
  payload: id,
})

export const deleteRoleFail = error => ({
  type: DELETE_ROLE_FAIL,
  payload: error,
})