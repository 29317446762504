import { getLoggedInUser, isUserAuthenticated } from 'helpers/api_helper'
import React, { useRef, useEffect, useState } from 'react'
import { shallowEqual, useSelector, connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { requestUser, logoutUser } from 'store/actions'
import Loader from '../../components/CommonForBoth/Loader/Loader'
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";



const AuthInit = (props) => {


  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(false)
  const didRequest = useRef(false)
  const accessToken = useSelector(state => state.Login.accessToken)


  // We should request user by authToken before rendering the application
  useEffect(() => {

    const requestUserHandler = async () => {
      try {
        if (!didRequest.current) {
          dispatch(requestUser({ accessToken: accessToken, history: props.history, pathname: window.location.pathname }))
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(logoutUser(props.history))
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (accessToken) {
      requestUserHandler()
    } else {
      if (!(window?.location?.pathname?.includes("/reset-password") || window?.location?.pathname?.includes("/user-agreement")))
        dispatch(logoutUser(props.history))
      // setShowSplashScreen(false)
    }
  }, [])

  return showSplashScreen ? <Loader /> : <>{props.children}</>
}

export default withRouter(AuthInit)

AuthInit.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
};