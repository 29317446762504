import React, { useEffect, useState, useRef, useMemo } from "react";
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import UserAddEdit from './UserAddEdit'


import { Name, Email, Roles } from "./usertlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import SuccessModal from "components/Common/SuccessModal";


import {
  getUsers as onGetUsers,
  deleteUser as onDeleteUser,
  resetUsersMessages as onReset
} from "store/users/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { getRoles } from "store/actions";
import { withTranslation } from "react-i18next";
import { checkPermission } from "helpers/functions";


// import columns from "./ColumnsUserList";

const UsersList = props => {

  //meta title
  document.title = "Kasb";
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
  })
  const dispatch = useDispatch();
  const history = useHistory()
  const [user, setUser] = useState();
  const [message, setMessage] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);


  const { users, success, nextPage, previousPage, limit, total, currentPage, loading } = useSelector(state =>
    state.users
  );
  const userRoles = useSelector(state => state.Login.user?.roles)

  const { roles } = useSelector(state => (state.Roles));

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // const [filter, setFilter] = useState(null)



  // console.log(userRoles, ["User", "User", "Update"])

  // console.log(columns, "columns")
  
  const columns = useMemo(
    () => [

      {
        Header: props.t("Image"),
        accessor: "image_url",
        disableFilters: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.img ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  {cellProps.firstname?.charAt(0)}
                </span>
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={cellProps.img}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: props.t("Name"),
        accessor: "firstname",
        disableFilters: true,
        accessor: (cellProps) => {
          return <div className="external-event" >{cellProps.firstname ? cellProps.firstname + " " + cellProps.lastname : ''}</div>;
        },
      },
      {
        Header: props.t("Email"),
        accessor: "email",
        disableFilters: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: props.t("Role"),
        accessor: "roles",
        disableFilters: true,
        Cell: cellProps => {
          return <Roles {...cellProps} />;
        },
      },
      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {checkPermission(userRoles, ["User", "User", "Update"]) && <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              }
              {checkPermission(userRoles, ["User", "User", "Show"]) && 
              <Link
                to={`/user-profile/${cellProps.row?.original?.id}`}
                className="text-tip"
              >
                <i className="bx bx-bullseye mt-1 font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  View
                </UncontrolledTooltip>
              </Link>}
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {

    dispatch(onGetUsers(filter));
    dispatch(getRoles({ limit: 1000, page: 1 }))
    // setIsEdit(false);

  }, [dispatch, filter]);

  useEffect(() => {
    setUser(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setUser(users);
      setIsEdit(false);
    }
  }, [users]);


  useEffect(() => {
    // console.log("sucess", success)
    if (success === "DELETE") {
      setSuccessModal(true)
      setMessage("User deleted successfully !")
      dispatch(onReset())
    } else if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("User edited successfully !")
      dispatch(onReset())
    }
  }, [success])

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setUser({
      id: user.id,
      firstname: user.firstname,
      lastname: user.lastname,
      password: user.password,
      email: user.email,
      roles: user.roles,
    });
    setIsEdit(true);

    toggle();
  };




  //delete customer

  const onClickDelete = user => {
    setUser(user);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    dispatch(onDeleteUser(user?.id));
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUser();
    setIsEdit(false);
    toggle();

  };

  const setPageNumber = (activePage) => { setFilter(prevFilter => { return { ...prevFilter, page: activePage } }) }
  const handleLimit = (limit) => { setFilter(prevFilter => { return { ...prevFilter, limit: limit } }) }

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Users")} breadcrumbItem={props.t("User List")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    loading={loading}
                    isGlobalFilter={true}
                    isAddUserList={checkPermission(userRoles, ["User", "User", "Create"])}
                    success={success}
                    handleUserClick={handleUserClicks}
                    customPageSize={100}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    className="custom-header-css"
                  />
                  <UserAddEdit modal={modal} user={user} isEdit={isEdit} toggle={toggle} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

UsersList.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(UsersList));
