import React from 'react'
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { updateBusinessPartner } from 'store/actions'


const BusinessPartnerModal = (props) => {

    const dispatch = useDispatch()
    const {modal, toggle, buisnessPartner, isEdit} = props


    // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: (buisnessPartner && buisnessPartner.firstname) || "",
      phone: (buisnessPartner && buisnessPartner.phone) || "",
      lastname: (buisnessPartner && buisnessPartner.lastname) || "",
      address: (buisnessPartner && buisnessPartner.address) || "",
      email: (buisnessPartner && buisnessPartner.email) || "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter Your Name"),
      phone: Yup.string().required("Please Enter Your Phone"),
      email: Yup.string().required("Please Enter Your Email"),
      address: Yup.string().required("Please Enter Your Address"),
      lastname: Yup.string().required("Please Enter Your lastname"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedBuisnessPartner = {
          id: buisnessPartner.id,
          firstname: values.firstname,
          phone: values.phone,
          email: values.email,
          address: values.address,
          lastname: values.lastname,
        }
        // update Busness Partner
        //console.log("update", updateCustomer)
        dispatch(updateBusinessPartner(updatedBuisnessPartner))
        validation.resetForm()
      }
      toggle()
    },
  })


    return(
        <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {!!isEdit ? "Edit Business Partner" : "Add Business Partner"}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row form>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">First Name</Label>
                  <Input
                    name="firstname"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.firstname || ""}
                    invalid={
                      validation.touched.firstname &&
                      validation.errors.firstname
                        ? true
                        : false
                    }
                  />
                  {validation.touched.firstname &&
                  validation.errors.firstname ? (
                    <FormFeedback type="invalid">
                      {validation.errors.firstname}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label">Last Name</Label>
                  <Input
                    name="lastname"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.lastname || ""}
                    invalid={
                      validation.touched.lastname &&
                      validation.errors.lastname
                        ? true
                        : false
                    }
                  />
                  {validation.touched.lastname &&
                  validation.errors.lastname ? (
                    <FormFeedback type="invalid">
                      {validation.errors.lastname}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Phone</Label>
                  <Input
                    name="phone"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.phone || ""}
                    invalid={
                      validation.touched.phone &&
                      validation.errors.phone
                        ? true
                        : false
                    }
                  />
                  {validation.touched.phone &&
                  validation.errors.phone ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phone}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Email</Label>
                  <Input
                    name="email"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email &&
                      validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email &&
                  validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label">Address</Label>
                  <Input
                    name="address"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.address || ""}
                    invalid={
                      validation.touched.address &&
                      validation.errors.address
                        ? true
                        : false
                    }
                  />
                  {validation.touched.address &&
                  validation.errors.address ? (
                    <FormFeedback type="invalid">
                      {validation.errors.address}
                    </FormFeedback>
                  ) : null}
                </div>

              
               
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-customer"
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    )
}



export default BusinessPartnerModal;