
import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    Input,
    Form,
} from "reactstrap";
import ReactSelect from "react-select"

import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { createPayout, getBusinessPartner, getMDMPayment, updatePayout } from "store/actions";


const PayoutReleaseModal = (props) => {


    const { payout, isEdit, modal, toggle } = props
    const dispatch = useDispatch();
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
    const [partner, setPartner] = useState()
    const [balance, setBalance] = useState(0)
    const [paymentMethod, setPaymentMethod] = useState()
    const { paymentData } = useSelector(state => state.payments)
    const { BusinessPartnerData } = useSelector(state => state.businesspartners)
    const { payoutData } = useSelector(state => state.payout)

    useEffect(() => {
        dispatch(getMDMPayment({ limit: 1000, page: 1, status: "active" }))
        dispatch(getBusinessPartner({ limit: 10000, page: 1 }))
    }, [])
    useEffect(() => {

    })
    useEffect(() => {
        if (payout?.id) {
            setPartner({ label: payout?.partner?.firstname + " " + payout?.partner?.lastname, value: payout?.partnerId })
            setPaymentMethod({
                value: payout?.payoutMethodId,
                label: (currentLanguage == "ar" && payout?.payoutMethod?.nameAr) ||
                    (currentLanguage == "en" && payout?.payoutMethod?.nameEn) ||
                    (currentLanguage == "fr" && payout?.payoutMethod?.nameFr),
            })
            setBalance(payout.partner?.wallet?.balance || 0)
        }
    }, [payout])

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            partnerId: (payout && payout.partnerId) || "",
            amount: (payout && payout.amount) || "",
            payoutMethodId: (payout && payout.payoutMethodId) || "",
            balance: (payout && payout?.partner && payout.partner?.wallet?.balance) || 0,
        },
        validationSchema: Yup.object({
            partnerId: Yup.string().required("Please select Partner"),
            amount: Yup.number()
                .typeError('Amount must be a number')
                .moreThan(0, "Amount must be more than 0")
                .required("Please Enter your amount")
                .when("balance", (balance, schema) => {
                    return schema.test({
                        test: (amount) => {
                            if (!parseFloat(amount)) return true;
                            return parseFloat(amount) <= parseFloat(balance);
                        },
                        message: "Amount is greater then this user's balance",
                    });
                }),
            payoutMethodId: Yup.string().required("Please select Your Payment method"),


        }),
        onSubmit: values => {
            if (isEdit) {
                const updatePayoutRelease = {
                    id: payout.id,
                    partnerId: values.partnerId,
                    amount: values.amount,
                    payoutMethodId: values.payoutMethodId,
                };
                dispatch(updatePayout(updatePayoutRelease));
                validation.resetForm();
                setPartner()
                setBalance(0)
                setPaymentMethod()
                setIsEdit(false);
            } else {
                const newPayoutRelease = {
                    partnerId: values["partnerId"],
                    amount: parseInt(values["amount"]),
                    payoutMethodId: values["payoutMethodId"],

                };

                //save new user
                //console.log("newPayout", newPayoutRelease)
                dispatch(createPayout(newPayoutRelease));
                validation.resetForm();
                setPartner()
                setBalance(0)
                setPaymentMethod()
            }
            toggle(false);
        },
    });

    return (
        <Modal isOpen={modal} toggle={() => toggle(false)}>
            <ModalHeader toggle={() => toggle(false)} tag="h4">
                {!!isEdit ? props.t("Edit Payout Release") : props.t("Add Payout Release")}
            </ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <Row form>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label className="form-label">{props.t("Partner Name")}</Label>
                                <ReactSelect
                                    value={partner}
                                    onChange={el => {
                                        setPartner(el)
                                        let balanceAmount = payoutData.find(data => data.partnerId == el.value)?.partner?.wallet?.balance
                                        setBalance((balanceAmount != undefined && balanceAmount) || 0)
                                        validation.setFieldValue("balance", (balanceAmount != undefined && balanceAmount) || 0)
                                        validation.setFieldValue("partnerId", el.value)
                                    }}
                                    placeholder={props.t("select partner...")}
                                    options={BusinessPartnerData.map(el => {
                                        return {
                                            value: el.id,
                                            label: el.firstname + " " + el.lastname
                                        }
                                    })}
                                    classNamePrefix="select2-selection"
                                />

                                {validation.errors.userId ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.userId}
                                    </FormFeedback>
                                ) : null}
                            </div>
                            {partner && balance != undefined &&
                                <div className="mb-3">
                                    <Label className="form-label">{props.t("Balance")}</Label>
                                    <Input
                                        name="balance"
                                        type="text"
                                        value={balance}
                                        disabled
                                    />
                                </div>
                            }
                            {/* <div className="mb-3">
                                <Label className="form-label">{props.t("Balance")}</Label>
                                <Input
                                    name="balance"
                                    type="text"
                                    value={partner || ""}
                                    invalid={
                                        validation.touched.amount &&
                                            validation.errors.amount
                                            ? true
                                            : false
                                    }
                                />
                            </div> */}
                            <div className="mb-3">
                                <Label className="form-label">{props.t("Amount")}</Label>
                                <Input
                                    name="amount"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.amount || ""}
                                    invalid={
                                        validation.touched.amount &&
                                            validation.errors.amount
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.amount &&
                                    validation.errors.amount ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.amount}
                                    </FormFeedback>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label className="form-label">{props.t("Payment Method")}</Label>
                                <ReactSelect
                                    value={paymentMethod}
                                    onChange={el => {
                                        setPaymentMethod(el)
                                        validation.setFieldValue("payoutMethodId", el.value)
                                    }}
                                    onBlur={validation.handleBlur}
                                    placeholder={props.t("select payment method...")}
                                    options={paymentData.map(payment => {
                                        return {
                                            value: payment?.id,
                                            label:
                                                (currentLanguage == "ar" && payment?.nameAr) ||
                                                (currentLanguage == "en" && payment?.nameEn) ||
                                                (currentLanguage == "fr" && payment?.nameFr),
                                        }
                                    })}
                                    classNamePrefix="select2-selection"
                                />
                                {/* <Input
                                    name="paymentMethod"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.paymentMethod || ""}
                                    invalid={
                                        validation.touched.paymentMethod &&
                                            validation.errors.paymentMethod
                                            ? true
                                            : false
                                    }
                                /> */}
                                {validation.errors.paymentMethod ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.paymentMethod}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-end">
                                <button
                                    disabled={!(validation.values?.partnerId && validation.values?.payoutMethodId)}
                                    type="submit"
                                    className={`btn btn-${isEdit ? "success" : "primary"} save-user`}
                                >
                                    {isEdit ? props.t("Update") : props.t("Save")}
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )

}

export default withTranslation()(PayoutReleaseModal)