import { GET_CONFIGS, GET_CONFIGS_FAIL, GET_CONFIGS_SUCCESS, RESET_MESSAGES, UPDATE_CONFIGS, UPDATE_CONFIGS_FAIL, UPDATE_CONFIGS_SUCCESS } from "./actionTypes"

export const getConfigs = () => ({
    type: GET_CONFIGS,
})

export const getConfigsSuccess = configs => ({
    type: GET_CONFIGS_SUCCESS,
    payload: configs,
})

export const getConfigsFaild = error => ({
    type: GET_CONFIGS_FAIL,
    payload: error,
})



export const updateConfigs = config => ({
    type: UPDATE_CONFIGS,
    payload: config,
})

export const updateConfigsSuccess = config => ({
    type: UPDATE_CONFIGS_SUCCESS,
    payload: config,
})

export const updateConfigsFail = error => ({
    type: UPDATE_CONFIGS_FAIL,
    payload: error,
})

export const resetConfigssMessages = () => ({
    type: RESET_MESSAGES,
})
