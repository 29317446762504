import React from 'react'
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
} from "reactstrap"

import { withTranslation } from 'react-i18next'


const ShowModal = (props) => {

    const { modal, toggle, level } = props

    // validation


    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
                {props.t("Level")}
            </ModalHeader>
            <ModalBody>

                <Row >
                    <Col className="col-12">
                        <div className="mb-3">
                            <Label className="form-label">{props.t("Level")} :</Label>
                            <div>{level?.level}</div>
                        </div>

                        <div className="mb-3">
                            <Label className="form-label">{props.t("Commission")} % :</Label>
                            <div> {level?.value}</div>
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("Level")} {props.t("Name")} :</Label>
                            <div>{level?.name}</div>
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end">
                            <button
                                className="btn btn-primary save-user"
                                onClick={toggle}
                            >
                                {props.t("Ok")}
                            </button>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}


export default withTranslation()(ShowModal)