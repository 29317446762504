import PropTypes from 'prop-types'
import React from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import { withTranslation } from "react-i18next"
const ConfirmModal =(props) => {
  const { show, onUpdateClick, onCloseClick }= props
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-check-circle-outline"
                style={{ fontSize: "9em", color: "orange" }}
              />
              <h2>{props.t("Are you sure?")}</h2> 
              
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={onUpdateClick}
              >
                {props.t("Yes, change it!")}
              </button>
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={onCloseClick}
              >
                {props.t("Cancel")}
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default  withTranslation()(ConfirmModal)
