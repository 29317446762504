import { GET_CONFIGS, GET_CONFIGS_FAIL, GET_CONFIGS_SUCCESS, UPDATE_CONFIGS, UPDATE_CONFIGS_FAIL, UPDATE_CONFIGS_SUCCESS } from "./actionTypes"

export const initialState = {
  loading: false,
  configs: [],
  success: "",
  error: "",
  nextPage: null,
  previousPage: null,
  limit: 0,
  total: 0,
  currentPage: 1,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONFIGS:
      return {
        ...state,
        loading: true,
      }
    case GET_CONFIGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_CONFIGS_SUCCESS:
      //const { data, ...filters } = action.payload
      return {
        ...state,
        configs: action.payload,
        loading: false,
        success: "GET",
      }
    case UPDATE_CONFIGS:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case UPDATE_CONFIGS_SUCCESS:
      return {
        ...state,
        configs: state.configs.map(config =>
          config.id.toString() === action.payload.id.toString()
            ? { ...config, ...action.payload }
            : config
        ),
        loading: false,
        success: "UPDATE",
      }
    case UPDATE_CONFIGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    
    default:
      return state
  }
}

export default reducer
