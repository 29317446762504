import React from 'react'
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
} from "reactstrap"

import { withTranslation } from 'react-i18next'


const ShowCategory = (props) => {

    const { modal, toggle, category } = props

    // validation


    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
                {props.t("Category")}
            </ModalHeader>
            <ModalBody>

                <Row >
                    <Col className="col-12">
                        <div className="mb-3">
                            <Label className="form-label">{props.t("Arabic Name")} :</Label>
                            <div>{category?.nameAr}</div>
                        </div>

                        <div className="mb-3">
                            <Label className="form-label">{props.t("English Name")} :</Label>
                            <div> {category?.nameEn}</div>
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("French Name")} :</Label>
                            <div>{category?.nameFr}</div>
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("Category Commission")} % :</Label>
                            <div>{category?.comission}</div>
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end">
                            <button
                                className="btn btn-primary save-user"
                                onClick={toggle}
                            >
                                {props.t("Ok")}
                            </button>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}


export default withTranslation()(ShowCategory)