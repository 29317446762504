import React, { useEffect, useState, useMemo } from "react"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteModal from "../../../components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "../../../components/Common/TableContainer"

// Column
import { UserName, ArabicName, EnName } from "./governatesCol"
import {
  getMDMGovernorates,
  getMDMCountries,
  updateMDMGovernorate,
  resetGovernorateMessages,
} from "store/actions"
import SuccessModal from "components/Common/SuccessModal"
import { withTranslation } from "react-i18next"
import { checkPermission } from "helpers/functions"
import GovernorateModal from "./GovernorateModal"

const Governorate = props => {
  const {
    governoratesData,
    error,
    success,
    total,
    limit,
    nextPage,
    previousPage,
    currentPage,
    loading
  } = useSelector(state => state.governorates)
  const { countriesData } = useSelector(state => state.countries)
  const userRoles = useSelector(state => state.Login.user?.roles)

  //meta title
  document.title = "Kasb"

  const dispatch = useDispatch()

  const [modal, setModal] = useState(false)
  const [GovernorateList, setGovernorateList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [governorate, setGovernorate] = useState(null)
  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState("")
  const [filter, setFilter] = useState({
		page:1,
		limit:100,
	  })

  const handleCustomerClick = arg => {
    const governorate = arg

    setGovernorate({
      id: governorate.id,
      nameEn: governorate.nameEn,
      nameFr: governorate.nameFr,
      nameAr: governorate.nameAr,
      parentId: governorate.parentId,
    })

    setIsEdit(true)
    toggle()
  }

  // Countries Column
  const columns = useMemo(
    () => [
      {
        Header: props.t("English Name"),
        accessor: "nameEn",
        disableFilters: true,
        Cell: cellProps => {
          return <EnName {...cellProps} />
        },
      },
      {
        Header: props.t("French Name"),
        accessor: "nameFr",
        disableFilters: true,
        Cell: cellProps => {
          return <UserName {...cellProps} />
        },
      },
      {
        Header: props.t("Arabic Name"),
        accessor: "nameAr",
        disableFilters: true,
        Cell: cellProps => {
          return <ArabicName {...cellProps} />
        },
      },
      {
        Header: props.t("Country"),
        accessor: "parent",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.parent?.nameAr || "not provided"}</>
        },
      },
      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {checkPermission(userRoles, ["MasterData", "Governorate", "Update"]) && 
              <Link
                to="#"
                className="text-tip"
                onClick={() => {
                  const governorateData = cellProps.row.original
                  handleCustomerClick(governorateData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>}
             {checkPermission(userRoles, ["MasterData", "Governorate", "Delete"]) && 
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const governorateData = cellProps.row.original
                  onClickDelete(governorateData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>}
            </div>
          )
        },
      },
    ],
    []
  )

  const toggle = () => {
    if (modal) {
      setModal(false)
      setGovernorate(null)
    } else {
      setModal(true)
    }
  }

  //delete
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = governorate => {
    setGovernorate(governorate)
    setDeleteModal(true)
  }

  const handleDeleteGovernorate = () => {
    if (governorate.id) {
      dispatch(updateMDMGovernorate({ id: governorate.id, status: "archived" }))
      setDeleteModal(false)
      dispatch(getMDMGovernorates())
    }
  }

  useEffect(() => {
    {
      dispatch(getMDMGovernorates(filter))

    }
  }, [dispatch,filter])

  useEffect(() => {
    setGovernorateList(countriesData)
  }, [countriesData])
  //   console.log("countries data", countriesData)

  useEffect(() => {
    setGovernorateList(governoratesData)
  }, [governoratesData])

  useEffect(() => {
    if (!isEmpty(governoratesData)) {
      setGovernorateList(governoratesData)
    }
  }, [governoratesData])

  const handleGovernorateClicks = () => {
    setGovernorateList("")
    setIsEdit(false)
    toggle()
  }


  useEffect(() => {
    if (success === "CREATE") {
      setSuccessModal(true)
      setMessage("Governorate created successfully !")
      dispatch(resetGovernorateMessages())
    } else if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("Successfully !")
      dispatch(resetGovernorateMessages())
    }
  }, [success])

  const setPageNumber = (activePage ) => {setFilter(prevFilter => {return {...prevFilter, page:activePage }})}
  const handleLimit =(limit)=>{setFilter(prevFilter => {return {...prevFilter, limit:limit }})}

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteGovernorate}
        onCloseClick={() => setDeleteModal(false)}
      />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("MDM")} breadcrumbItem={props.t("Governorates List")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={governoratesData}
                    //isGlobalFilter={true}
                    isAddCustList={checkPermission(userRoles, ["MasterData", "Governorate", "Create"])}
                    handelAddClick={handleGovernorateClicks}
                    customPageSize={100}
                    nextPage={nextPage}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                   <GovernorateModal modal={modal} toggle={toggle} isEdit={isEdit} governorate={governorate} />
               
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Governorate)
