
import { all, put, takeEvery } from "redux-saga/effects";
import {
 
  createMDMCityFailed,
  createMDMCitySuccess,
  deleteMDMCityFailed,
  deleteMDMCitySuccess,
  getMDMCitiesFailed,
  getMDMCitiesSuccess,
  updateMDMCityFailed,
  updateMDMCitySuccess,
  resetCityMessagesSuccess,
  
} from "./actions"
import {
  CITY_MESSAGES_RESET,
  CREATE_CITY_REQUEST,
  DELETE_CITY_REQUEST,
  GET_CITY_BY_NAME_REQUEST,
  GET_CITY_REQUEST,
  RESET_CITY_MESSAGES,
  UPDATE_CITY_REQUEST
} from "./actionTypes";



import {getCities, postCities, putCities } from "helpers/api_helper";



function* getCitiesSaga({payload}) {
  try {
    const response = yield getCities(payload || {page: 1, limit: 1000});
    yield put(getMDMCitiesSuccess(response))
  } catch (e) {
    yield put(getMDMCitiesFailed(e))
  }
}

function* getCitiesBynameSaga() {
 
}

function* getCitiesFlatSaga({ payload }) {
  try {
    // const result = yield new Repository().getCategoriesFlat(payload)
    // console.log(result)
    // yield put(getCategoriesFlatSuccess(result))
  } catch (e) {
    yield put(getMDMCitiesFailed(e))
  }
}

function* createCitySaga({ payload: city }) {
  try {

    const data = yield postCities(city);
//  console.log("create city",city)
 const response = yield getCities({page: 1, limit: 20});
    yield put(getMDMCitiesSuccess(response))

  } catch (e) {
    yield put(createMDMCityFailed(e))
  }
}

function* deleteCitySaga({ payload: id }) {
  try {
    // const result = yield new Repository().deleteCategory(payload)
    // const data = yield new Repository().getCategories({ current: 1, limit: 20 })
    yield put(deleteMDMCitySuccess(id))
  } catch (e) {
    yield put(deleteMDMCityFailed(e))
  }
}

function* updateCitySaga({payload:city }) {
  try {
    // separer l id & objet city
    const{id, ...updatedCity}= city
    const data = yield putCities(id,updatedCity);
    const response = yield getCities({page: 1, limit: 20});
    yield put(getMDMCitiesSuccess(response))
    // console.log("update city",response)
  } catch (e) {
    yield put(updateMDMCityFailed(e))
  }
}

function* resetCityMessagesSaga() {
  yield put(resetCityMessagesSuccess())
}





export default function* citySaga() {
  yield all([
    takeEvery(GET_CITY_REQUEST, getCitiesSaga),
    takeEvery(GET_CITY_BY_NAME_REQUEST, getCitiesBynameSaga),
    takeEvery(CREATE_CITY_REQUEST, createCitySaga),
    takeEvery(DELETE_CITY_REQUEST, deleteCitySaga),
    takeEvery(UPDATE_CITY_REQUEST, updateCitySaga),
    takeEvery(RESET_CITY_MESSAGES, resetCityMessagesSaga),
  ])
}
