import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import "react-datepicker/dist/react-datepicker.css"
import { Card, CardBody, TabPane } from "reactstrap"
import { withTranslation } from "react-i18next"
import TableContainer from "../../../components/Common/TableContainer"
import { Link } from "react-router-dom"
//redux
import { useSelector, useDispatch } from "react-redux"
import * as moment from "moment"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import { getPayout, updatePayout } from "store/actions"
import { resetUsersMessages as onReset } from "store/users/actions"
import ConfirmModal from "components/Common/ConfirmModal"
import ConfirmModalStatus from "components/Common/ConfirmModalStatus"
import PayoutReleaseModal from "./PayoutReleaseModal"
import ErrorModal from "components/Common/ErrorModal"
import SuccessModal from "components/Common/SuccessModal"
import { checkPermission } from "helpers/functions"

const PayoutRelease = props => {
  //meta title
  document.title = "Kasb"

  const [filter, setFilter] = useState({
    partnerId: "",
    from: "",
    to: "",
    status: "",
    page: 1,
    limit: 100,
  })

  const {
    payoutData,
    success,
    error,
    nextPage,
    previousPage,
    currentPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.payout)
  const userRoles = useSelector(state => state.Login.user?.roles)

  const [confirmModal, setConfirmModal] = useState(false)
  const [confirm1Modal, setConfirm1Modal] = useState(false)
  const [payoutReleaseModal, setPayoutReleaseModal] = useState(false)
  const [payoutReleaseEntity, setPayoutReleaseEntity] = useState({})
  const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState("")
  const [errorModal, setErrorModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [payout, setPayout] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPayout(filter))
  }, [dispatch, filter])

  const payoutRealseColumns = useMemo(
    () => [
      {
        Header: props.t("Full Name"),
        accessor: "firstname",
        accessor: cellProps => {
          return (
            <>
              {(cellProps.partner &&
                cellProps.partner?.firstname +
                  " " +
                  cellProps.partner?.lastname) ||
                props.t("------")}
            </>
          )
        },
      },
      {
        Header: props.t("Amount"),
        accessor: "amount",
        accessor: cellProps => {
          return <>{cellProps.amount || 0}</>
        },
      },
      {
        Header: props.t("Released Amount"),
        accessor: "releasedAmount",
        accessor: cellProps => {
          return <>{cellProps.releasedAmount || props.t("------")}</>
        },
      },
      {
        Header: props.t("Payout Method"),
        accessor: "releasedAmount",
        accessor: cellProps => {
          return (
            <>
              {" "}
              {cellProps.payoutMethod
                ? (currentLanguage == "ar" && cellProps.payoutMethod?.nameAr) ||
                  (currentLanguage == "en" && cellProps.payoutMethod?.nameEn) ||
                  (currentLanguage == "fr" && cellProps.payoutMethod?.nameFr)
                : props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Payout Type"),
        accessor: "type",

        accessor: cellProps => {
          return <> {cellProps.type || props.t("mobile")}</>
        },
      },
      {
        Header: props.t("E-Wallet Balance"),
        accessor: "partner",
        accessor: cellProps => {
          return (
            <>
              {cellProps.partner?.wallet?.balance?.toFixed(2) ||
                props.t("------")}
            </>
          )
        },
      },

      {
        Header: props.t("Status"),
        accessor: "status",
        accessor: cellProps => {
          return <>{cellProps.status || props.t("------")}</>
        },
      },
      {
        Header: props.t("Created By"),
        accessor: "createdBy",
        accessor: cellProps => {
          return (
            <>
              {(cellProps.createdBy &&
                cellProps.createdBy?.firstname +
                  " " +
                  cellProps.createdBy?.lastname) ||
                props.t("------")}
            </>
          )
        },
      },
      {
        Header: props.t("Created At"),
        accessor: "createdAt",
        accessor: cellProps => {
          return (
            <>
              {(cellProps.createdAt &&
                moment(new Date(cellProps.createdAt)).format(
                  "YYYY-MM-DD HH:mm"
                )) ||
                props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Released At"),
        accessor: "releasedAt",
        accessor: cellProps => {
          return (
            <>
              {(cellProps.releasedAt &&
                moment(new Date(cellProps.releasedAt)).format(
                  "YYYY-MM-DD HH:mm"
                )) ||
                props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <>
              <div className="d-flex ">
                {cellProps?.row?.original?.status == "pending" && checkPermission(userRoles, ["Accounting", "Payout", "UpdatePayoutRelease"])&& 
                 <Link
                  to="#"
                  className="text-tip ms-2 me-4"
                  onClick={() => {
                    const orderData = cellProps.row.original
                    //console.log("order", orderData)
                    onClick1Row(orderData)
                  }}
                >
           
                 <i
                    className="dripicons-checkmark font-size-18"
                    id="edittooltip"
                  />
                </Link>}
                {cellProps?.row?.original?.status == "pending" && checkPermission(userRoles, ["Accounting", "Payout", "DeletePayoutRelease"])&& 
                  <Link
                  to="#"
                  className="text-danger me-4"
                  onClick={() => {
                    const orderData = cellProps.row.original
                    //console.log("orderData", orderData)
                    onClickRow(orderData)
                  }}
                >
                  <i className="mdi mdi-cash-remove font-size-18"></i>
                </Link>}
                {cellProps?.row?.original?.status == "pending" && checkPermission(userRoles, ["Accounting", "Payout", "ReleasePayoutRelease"]) &&
                  <Link
                  to="#"
                  className="text-tip"
                  onClick={() => {
                    let payoutRelease = cellProps.row.original
                    handlePayoutClick(payoutRelease)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                </Link>
        }
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    if (success === "CREATE") {
      setSuccessModal(true)
      setMessage("Payout created successfully !")
      dispatch(onReset())
    } else if (success == "UPDATE") {
      setSuccessModal(true)
      setMessage("Payout edited successfully !")
      dispatch(onReset())
    }
  }, [success])
  const changeStatusReleased = () => {
    if (payout.id) {
      if (payout.status == "pending") {
        if (payout.releasedAmount != null) {
          dispatch(updatePayout({ id: payout.id, status: "released" }))
          setConfirm1Modal(false)
          dispatch(getPayout())
        } else {
          dispatch(
            updatePayout({
              id: payout.id,
              releasedAmount: payout.amount,
              status: "released",
            })
          )
          setConfirm1Modal(false)
          dispatch(getPayout())
        }
      }
    }
  }

  const changeStatusRejected = () => {
    if (payout.id) {
      if (payout.status == "pending") {
        dispatch(updatePayout({ id: payout.id, status: "rejected" }))
        setConfirmModal(false)
        dispatch(getPayout())
      }
    }
  }

  const onClickRow = payout => {
    setPayout(payout)
    setConfirmModal(true)
  }

  const onClick1Row = payout => {
    setPayout(payout)
    setConfirm1Modal(true)
  }

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }

  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  const handelAddPayout = () => {
    setPayoutReleaseModal(true)
    setIsEdit(false)
    setPayoutReleaseEntity(null)
  }

  const handlePayoutClick = payout => {
    setPayoutReleaseEntity(payout)
    setIsEdit(true)
    setPayoutReleaseModal(true)
  }

  const handleDateFromTo = date => {
    if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.startDate).add(1, "days").format("Y-MM-DD"),
        }
      })
    } else {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.endDate).format("Y-MM-DD"),
        }
      })
    }
  }
  return (
    <React.Fragment>
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal(false)}
        message={message}
      />
      <ConfirmModal
        show={confirmModal}
        onUpdateClick={changeStatusRejected}
        onCloseClick={() => setConfirmModal(false)}
      />
      <ConfirmModalStatus
        show={confirm1Modal}
        onUpdateClick={changeStatusReleased}
        onCloseClick={() => setConfirm1Modal(false)}
      />
      <ErrorModal
        show={errorModal}
        onCloseClick={() => setErrorModal(false)}
        message={message}
      />
      <TabPane tabId="2" id="release">
        <div>
          <Card>
            <CardBody>
              <TableContainer
                columns={payoutRealseColumns}
                data={payoutData}
                filterDate={true}
                setDateFilter={handleDateFromTo}
                isAddCustList={checkPermission(userRoles, ["Accounting", "Payout", "CreatePayoutRelease"])}
                handelAddClick={handelAddPayout}
                customPageSize={100}
                setPageNumber={setPageNumber}
                setLimit={handleLimit}
                nextPage={nextPage}
                previousPage={previousPage}
                currentPage={currentPage}
                total={total}
                limit={limit}
                loading={loading}
                success={success}
              />
            </CardBody>
          </Card>
          <PayoutReleaseModal
            modal={payoutReleaseModal}
            payout={payoutReleaseEntity}
            isEdit={isEdit}
            toggle={setPayoutReleaseModal}
          />
        </div>
      </TabPane>
    </React.Fragment>
  )
}

PayoutRelease.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(PayoutRelease)
