/* EWALLET */
export const GET_EWALLET = "GET_EWALLET"
export const GET_EWALLET_SUCCESS = "GET_EWALLET_SUCCESS"
export const GET_EWALLET_FAIL = "GET_EWALLET_FAIL"
/* EWALLET */
export const GET_EWALLET_STATS = "GET_EWALLET_STATS"
export const GET_EWALLET_STATS_FAIL = "GET_EWALLET_STATS_FAIL"
export const GET_EWALLET_STATS_SUCCESS = "GET_EWALLET_STATS_SUCCESS"

/**
 * add EWALLET
 */
export const ADD_EWALLET = "ADD_EWALLET"
export const ADD_EWALLET_SUCCESS = "ADD_EWALLET_SUCCESS"
export const ADD_EWALLET_FAIL = "ADD_EWALLET_FAIL"

/**
 * update EWALLET
 */
export const UPDATE_EWALLET = "UPDATE_EWALLET"
export const UPDATE_EWALLET_SUCCESS = "UPDATE_EWALLET_SUCCESS"
export const UPDATE_EWALLET_FAIL = "UPDATE_EWALLET_FAIL"
