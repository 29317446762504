import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_FAIL,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONSINCOME,
  GET_TRANSACTIONSINCOME_FAIL,
  GET_TRANSACTIONSINCOME_SUCCESS,
  GET_TRANSACTIONSINCOME_STATS,
  GET_TRANSACTIONSINCOME_FAIL_STATS,
  GET_TRANSACTIONSINCOME_SUCCESS_STATS,
  GET_TRANSACTIONS_PARTNER,
  GET_TRANSACTIONS_PARTNER_SUCCESS,
  GET_TRANSACTIONS_PARTNER_FAIL,
  GET_TRANSACTIONS_PARTNER_STATS,
  GET_TRANSACTIONS_PARTNER_STATS_SUCCESS,
  GET_TRANSACTIONS_PARTNER_STATS_FAIL,
} from "./actionTypes"

export const getTransactions = (filter) => ({
  type: GET_TRANSACTIONS,
  payload: filter
})

export const getTransactionsSuccess = transactions => ({
  type: GET_TRANSACTIONS_SUCCESS,
  payload: transactions,
})

export const getTransactionsFailed = error => ({
  type: GET_TRANSACTIONS_FAIL,
  payload: error,
})
export const getTransactionsIncome = (filter) => ({
  type: GET_TRANSACTIONSINCOME,
  payload:filter
})

export const getTransactionsIncomeSuccess = transactions => ({
  type: GET_TRANSACTIONSINCOME_SUCCESS,
  payload: transactions,
})

export const getTransactionsIncomeFailed = error => ({
  type: GET_TRANSACTIONSINCOME_FAIL,
  payload: error,
})
export const getTransactionsIncomeStats = () => ({
  type: GET_TRANSACTIONSINCOME_STATS,

})

export const getTransactionsIncomeStatsSuccess = transactions => ({
  type: GET_TRANSACTIONSINCOME_SUCCESS_STATS,
  payload: transactions,
})

export const getTransactionsIncomeStatsFailed = error => ({
  type: GET_TRANSACTIONSINCOME_FAIL_STATS,
  payload: error,
})

export const getTransactionsPartner = (filter) => ({
  type: GET_TRANSACTIONS_PARTNER,
  payload:filter
})

export const getTransactionsPartnerSuccess = transactions => ({
  type: GET_TRANSACTIONS_PARTNER_SUCCESS,
  payload: transactions,
})

export const getTransactionsPartnerFailed = error => ({
  type: GET_TRANSACTIONS_PARTNER_FAIL,
  payload: error,
})
export const getTransactionsPartnerStats = () => ({
  type: GET_TRANSACTIONS_PARTNER_STATS,
})

export const getTransactionsPartnerStatsSuccess = transactions => ({
  type: GET_TRANSACTIONS_PARTNER_STATS_SUCCESS,
  payload: transactions,
})

export const getTransactionsPartnerStatsFailed = error => ({
  type: GET_TRANSACTIONS_PARTNER_STATS_FAIL,
  payload: error,
})
