import React from 'react'
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

import { useSelector, useDispatch } from "react-redux"
import { createLevel, updateLevel } from 'store/actions'
import { withTranslation } from 'react-i18next'


const LevelModal = (props) => {

    const dispatch = useDispatch()
    const { modal, toggle, level, isEdit } = props

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: (level && level.id) || "",
            name: (level && level.name) || "",
            value: (level && level.value) || "",
            level: (level && level.level) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required(props.t("Please Enter Level Name")),
            value: Yup.string().required(props.t("Please Enter Level value")),
            level: Yup.string().required(props.t("Please Enter Level")),
        }),
        onSubmit: values => {
            if (isEdit) {
                const updatedLevel = {
                    id: level.id,
                    name: values.name,
                    value: values.value,
                    level: values.level,
                }
                // update order
                dispatch(updateLevel(updatedLevel))
                validation.resetForm()
            } else {
                const newLevel = {
                    name: values["name"],
                    value: values["value"],
                    level: values["level"],
                }
                // console.log("newOrder", newLevel)
                // save new order
                dispatch(createLevel(newLevel))
                validation.resetForm()
            }
            toggle()
        },
    })


    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? props.t("Edit level") : props.t("Add level")}
            </ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                    }}
                >
                    <Row form>
                        <Col className="col-12">
                        <div className="mb-3">
                                <Label className="form-label">{props.t("Level")}</Label>
                                <Input
                                    name="level"
                                    type="text"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.level || ""}
                                    invalid={
                                        validation.touched.level &&
                                            validation.errors.level
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.level &&
                                    validation.errors.level ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.level}
                                    </FormFeedback>
                                ) : null}
                            </div>
                          
                            <div className="mb-3">
                                <Label className="form-label">{props.t("Commission")} %</Label>
                                <Input
                                    name="value"
                                    type="text"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.value || ""}
                                    invalid={
                                        validation.touched.value &&
                                            validation.errors.value
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.value &&
                                    validation.errors.value ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.value}
                                    </FormFeedback>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label className="form-label">{props.t("Level")} {props.t("Name")}</Label>
                                <Input
                                    name="name"
                                    type="text"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.name || ""}
                                    invalid={
                                        validation.touched.name &&
                                            validation.errors.name
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.name &&
                                    validation.errors.name ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.name}
                                    </FormFeedback>
                                ) : null}
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-end">
                                <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                >
                                    {props.t("Save")}
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
}


export default withTranslation()(LevelModal)