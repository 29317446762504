import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

import { withTranslation } from "react-i18next"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  getBusinessPartner,
  getRetailers,
  getTransactions,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"


import { Col, Row, Card, CardBody } from "reactstrap"
import moment from "moment"

function Transactions(props) {
  //meta title
  document.title = "Kasb"

  const [filter, setFilter] = useState({
    userId: "",
    retailerId: "",
    partnerId: "",
    from: "",
    to: "",
    page: 1,
    limit: 100,
  })

  const dispatch = useDispatch()

  const {
    transactionsData,
    success,
    error,
    nextPage,
    previousPage,
    currentPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.transactions)
  const { BusinessPartnerData } = useSelector(state => state.businesspartners)
  const { retailersData } = useSelector(state => state.retailers)
  useEffect(() => {
    dispatch(getTransactions(filter))
    dispatch(getBusinessPartner({limit: 10000, page: 1}))
    dispatch(getRetailers({limit: 10000, page: 1}))
  }, [dispatch, filter])

  //delete order

  // console.log("transactionsData",transactionsData)
  
  const columns = useMemo(
    () => [
      {
        Header: props.t("Reference"),
        accessor: "reference",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.reference || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Date"),
        accessor: "createdAt",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {moment(new Date(cellProps.createdAt)).format(
                "YYYY-MM-DD HH:mm"
              ) || props.t("Not provided")}
            </>
          )
        },
      },

      {
        Header: props.t("Buisness Partner"),
        accessor: "partner",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {(cellProps.partner &&
                cellProps.partner?.firstname +
                " " +
                cellProps.partner?.lastname) ||
                cellProps.partner?.username ||
                props.t("Not provided")}
            </>
          )
        },
      },
      {
        Header: props.t("Retailer"),
        accessor: "retailer",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <>
              {cellProps.retailer?.firstname +
                " " +
                cellProps.retailer?.lastname || cellProps.retailer?.username}
            </>
          )
        },
      },

      {
        Header: props.t("Amount"),
        accessor: "amount",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Type"),
        accessor: "paymentType",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.paymentType || props.t("Not provided")}</>
        },
      },
      {
        Header: props.t("Status"),
        accessor: "status",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || props.t("Not provided")}</>
        },
      },
    ],
    []
  )

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }
  const handlePartner = (partner) => {
    setFilter(prevFilter => {
      return { ...prevFilter, userId:partner.value ,partnerId:partner.value}
    })
  }
  const handleRetailer = (retailer) => {
    setFilter(prevFilter => {
      return { ...prevFilter, userId:retailer.value ,retailerId:retailer.value}
    })
  }
  const handleRetailerPartner = (idR,idP) => {
    setFilter(prevFilter => {
      return { ...prevFilter, retailerId: idR , partnerId :idP}
    })
  }
  const handleDateFromTo = date => {
    if (!moment(date?.endDate).diff(date?.startDate, "days")) {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.startDate).add(1, "days").format("Y-MM-DD")
        }
      })
    }else {
      setFilter(prevFilter => {
        return {
          ...prevFilter,
          from: moment(date.startDate).format("Y-MM-DD"),
          to: moment(date.endDate).format("Y-MM-DD"),
        }
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Transactions")}
            breadcrumbItem={props.t("Transaction list")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={transactionsData}
                    resetTransactionIncomeOption={true}
                    customPageSize={100}
                    filterDate={true}
                    setDateFilter={handleDateFromTo}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    setPartner={handlePartner}
                    partners={BusinessPartnerData}
                    isPartnersFilter={true}
                    isRetailerTransactionFilter={true}
                    setRetailer={handleRetailer}
                    retailers={retailersData}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
Transactions.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default withTranslation()(Transactions)
