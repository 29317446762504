
import {
    CATEGORY_MESSAGES_RESET,
    CREATE_CATEGORY_FAILED,
    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILED,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    GET_CATEGORY_BY_NAME_FAILED,
    GET_CATEGORY_BY_NAME_REQUEST,
    GET_CATEGORY_BY_NAME_SUCCESS,
    GET_CATEGORY_FAILED,
    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAILED,
    UPDATE_CATEGORY_REQUEST,
    RESET_CATEGORY_MESSAGES_SUCCESS,
    RESET_CATEGORY_MESSAGES,
    UPDATE_CATEGORY_SUCCESS
} from "./actionTypes.js"

export const getCategoriesByName = name => ({
    type: GET_CATEGORY_BY_NAME_REQUEST,
    payload: name
})


export const getCategoriesByNameSuccess = payload => ({
    type: GET_CATEGORY_BY_NAME_SUCCESS,
    payload
})


export const getCategoriesByNameFailed = payload => ({
    type: GET_CATEGORY_BY_NAME_FAILED,
    payload
})




export const getMDMCategories = (filter) => ({
    type: GET_CATEGORY_REQUEST,
    payload:filter
})



export const getMDMCategoriesSuccess = CATEGORY => ({
    type: GET_CATEGORY_SUCCESS,
    payload: CATEGORY
})

export const getMDMCategoriesFailed = payload => ({
    type: GET_CATEGORY_FAILED,
    payload
})

export const createMDMCategory = category => ({
    type: CREATE_CATEGORY_REQUEST,
    payload: category
})

export const createMDMCategorySuccess = category => ({
    type: CREATE_CATEGORY_SUCCESS,
    payload: category
})

export const createMDMCategoryFailed = payload => ({
    type: CREATE_CATEGORY_FAILED,
    payload
})

export const deleteMDMCategory = response => ({
    type: DELETE_CATEGORY_REQUEST,
    payload: response
})

export const deleteMDMCategorySuccess = response => ({
    type: DELETE_CATEGORY_SUCCESS,
    payload: response
})

export const deleteMDMCategoryFailed = response => ({
    type: DELETE_CATEGORY_FAILED,
    payload: response
})

export const updateMDMCategory = (category )=> ({
    type: UPDATE_CATEGORY_REQUEST,
    payload: category
})

export const updateMDMCategorySuccess = (category ) => ({
    type: UPDATE_CATEGORY_SUCCESS,
    payload: category
})

export const updateMDMCategoryFailed = payload => ({
    type: UPDATE_CATEGORY_FAILED,
    payload
})

export const resetMDMCategoryMessages = () => ({
    type: CATEGORY_MESSAGES_RESET
}
)

export const resetCategoryMessages = () => ({
    type: RESET_CATEGORY_MESSAGES,
  })
  
  export const resetCategoryMessagesSuccess = () => ({
    type: RESET_CATEGORY_MESSAGES_SUCCESS,
  })



