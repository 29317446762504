import {
  CREATE_LEVEL,
  CREATE_LEVEL_FAIL,
  CREATE_LEVEL_SUCCESS,
  DELETE_LEVEL,
  DELETE_LEVEL_FAIL,
  DELETE_LEVEL_SUCCESS,
  GET_LEVELS,
  GET_LEVELS_FAIL,
  GET_LEVELS_SUCCESS,
  UPDATE_LEVEL,
  UPDATE_LEVEL_FAIL,
  RESET_LEVEL_MESSAGES_SUCCESS,
  UPDATE_LEVEL_SUCCESS
} from "./actionTypes";

export const initialState = {
  loading: false,
  levels: [],
  comissionIn: [],
  mmp:[],
  kasbcom:[],
  success: "",
  error: "",
  nextPage: null,
  previousPage: null,
  limit: 0,
  total: 0,
  currentPage: 1
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_LEVELS:
      return {
        ...state,
        loading: true,
      }
    case GET_LEVELS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case GET_LEVELS_SUCCESS:
      const {data, ...filters} = action.payload
      return {
        ...state,
        levels: [...data],
        ...filters,
        loading: false,
        success: "GET"
      }
    case CREATE_LEVEL:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case CREATE_LEVEL_SUCCESS:
      return {
        ...state,
        levels:[...state.levels, {id: state.levels.length, ...action.payload}],
        loading: false,
        success: "ADD"
      }
    case CREATE_LEVEL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case UPDATE_LEVEL:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case UPDATE_LEVEL_SUCCESS:
      return {
        ...state,
        levels: state.levels.map(level =>
          level.id.toString() === action.payload.id.toString()
            ? { level, ...action.payload }
            : level
        ),
        loading: false,
        success: "UPDATE"
      }
    case UPDATE_LEVEL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case DELETE_LEVEL:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case DELETE_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: 'DELETE',
        levels: state.levels.filter(
          level => level.id.toString() !== action.payload.toString()
        ),
      }
    case DELETE_LEVEL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      case RESET_LEVEL_MESSAGES_SUCCESS:
        return {
          ...state,
          error: null,
          success: null
        }


    default:
      return state;
  }
}

export default reducer;
