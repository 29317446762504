import {
  CITY_MESSAGES_RESET,
  CATEGORY_MESSAGES_SUCCESS,
  RESET_CITY_MESSAGES_SUCCESS,
  CREATE_CITY_FAILED,
  CREATE_CITY_REQUEST,
  CREATE_CITY_SUCCESS,
  DELETE_CITY_FAILED,
  DELETE_CITY_REQUEST,
  DELETE_CITY_SUCCESS,
  GET_CITY_BY_NAME_FAILED,
  GET_CITY_BY_NAME_REQUEST,
  GET_CITY_BY_NAME_SUCCESS,
  GET_CITY_FAILED,
  GET_CITY_REQUEST,
  GET_CITY_SUCCESS,
  UPDATE_CITY_FAILED,
  UPDATE_CITY_REQUEST,
  UPDATE_CITY_SUCCESS,
} from "./actionTypes"

export const initialState = {
  loading: false,
  citiesData: [],
  countriesData: [],
  success: "",
  error: "",
  nextPage: null,
  previousPage: null,
  limit: 0,
  total: 0,
  currentPage: 1
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CITY_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      }
    case GET_CITY_SUCCESS:
      const {data, ...filters} = action.payload
      return {
        ...state,
        citiesData: [...data],
        ...filters,
        loading: false,
        success: "GET",
      }
    case GET_CITY_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      }
    case GET_CITY_BY_NAME_REQUEST:
      return {
        ...state,
        success: "",
        error: "",
        loading: true,
      }
    case GET_CITY_BY_NAME_SUCCESS:
      return {
        ...state,
        citiesData: action.payload,
        loading: false,
        success: "SUCCESS",
      }
    case GET_CITY_BY_NAME_FAILED:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      }
    case CREATE_CITY_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CREATE_CITY_SUCCESS:
      return {
        ...state,
        citiesData: [...state.citiesData, action.payload],
        success: "CREATE",
        loading: false,
      }
    case CREATE_CITY_FAILED:
      return {
        ...state,
        error: "FAILED CREATE",
        loading: false,
      }
    case DELETE_CITY_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case DELETE_CITY_SUCCESS:
      return {
        ...state,
        citiesData: state.citiesData.filter(
          city => city.id.toString() !== action.payload.toString()
        ),
        success: "DELETE",
        loading: false,
      }
    case DELETE_CITY_FAILED:
      return {
        ...state,
        error: "FAILED DELETE",
        loading: false,
      }
    case UPDATE_CITY_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case UPDATE_CITY_SUCCESS:
      return {
        ...state,
        citiesData: state.citiesData.map(city =>
          city.id.toString() === action.payload.id.toString()
            ? { city, ...action.payload }
            : city
        ),
        success: "UPDATE",
        loading: false,
      }
    case UPDATE_CITY_FAILED:
      return {
        ...state,
        error: "FAILED UPDATE",
        loading: false,
      }
    case CITY_MESSAGES_RESET:
      return {
        ...state,
        error: "",
        success: "",
        loading: false,
      }
      case RESET_CITY_MESSAGES_SUCCESS:
        return {
          ...state,
          error: null,
          success: null
        }
    default:
      return state
  }
}

export default reducer
