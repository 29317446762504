
import {
    BRAND_MESSAGES_RESET,
    CREATE_BRAND_FAILED,
    CREATE_BRAND_REQUEST,
    CREATE_BRAND_SUCCESS,
    DELETE_BRAND_FAILED,
    DELETE_BRAND_REQUEST,
    DELETE_BRAND_SUCCESS,
    GET_BRAND_BY_NAME_FAILED,
    GET_BRAND_BY_NAME_REQUEST,
    GET_BRAND_BY_NAME_SUCCESS,
    GET_BRAND_FAILED,
    GET_BRAND_REQUEST,
    GET_BRAND_SUCCESS,
    UPDATE_BRAND_FAILED,
    UPDATE_BRAND_REQUEST,
    RESET_BRAND_MESSAGES_SUCCESS,
    RESET_BRAND_MESSAGES,
    UPDATE_BRAND_SUCCESS
} from "./actionTypes.js"

export const getBrandByName = name => ({
    type: GET_BRAND_BY_NAME_REQUEST,
    payload: name
})


export const getBrandByNameSuccess = payload => ({
    type: GET_BRAND_BY_NAME_SUCCESS,
    payload
})


export const getBrandByNameFailed = payload => ({
    type: GET_BRAND_BY_NAME_FAILED,
    payload
})




export const getMDMBrand = (filter) => ({
    type: GET_BRAND_REQUEST,
    payload:filter
   
})



export const getMDMBrandSuccess = brand => ({
    type: GET_BRAND_SUCCESS,
    payload: brand
})

export const getMDMBrandFailed = payload => ({
    type: GET_BRAND_FAILED,
    payload
})

export const createMDMBrand = brand => ({
    type: CREATE_BRAND_REQUEST,
    payload: brand
})

export const createMDMBrandSuccess = brand => ({
    type: CREATE_BRAND_SUCCESS,
    payload: brand
})

export const createMDMBrandFailed = payload => ({
    type: CREATE_BRAND_FAILED,
    payload
})

export const deleteMDMBrand = response => ({
    type: DELETE_BRAND_REQUEST,
    payload: response
})

export const deleteMDMBrandSuccess = response => ({
    type: DELETE_BRAND_SUCCESS,
    payload: response
})

export const deleteMDMBrandFailed = response => ({
    type: DELETE_BRAND_FAILED,
    payload: response
})

export const updateMDMBrand = (brand )=> ({
    type: UPDATE_BRAND_REQUEST,
    payload: brand
})

export const updateMDMBrandSuccess = (brand ) => ({
    type: UPDATE_BRAND_SUCCESS,
    payload: brand
})

export const updateMDMBrandFailed = payload => ({
    type: UPDATE_BRAND_FAILED,
    payload
})

export const resetMDMBrandMessages = () => ({
    type: BRAND_MESSAGES_RESET
}
)

export const resetBrandMessages = () => ({
    type: RESET_BRAND_MESSAGES,
  })
  
  export const resetBrandMessagesSuccess = () => ({
    type: RESET_BRAND_MESSAGES_SUCCESS,
  })



