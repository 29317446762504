import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"
import TapVisitors from "./TopVisitors"
import StackBarChart from "./stackedbarchart"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import MiniCards from "pages/Dashboard/mini-card"
import { map } from "lodash"
import BarChart from "./barchart"
import Tasks from "./Tasks"
import TasksIncome from "./TasksIncome"
import Transactions from "./transactions"

const Dashboard = props => {
  const history = useHistory()
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [chartSeries, setChartSeries] = useState([])

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  //meta title
  document.title = "Kasb"
  const [miniCards, setMiniCards] = useState([
    {
      title: props.t("E-wallet Balance"),
      iconClass: "bx bxs-chevron-up-square",
      text: props.t("$820.23"),
      color: "warning",
    },
    {
      title: props.t("Income"),
      iconClass: "bx bx-comment-check",
      text: props.t("$0.00"),
      color: "info",
    },
    {
      title: props.t("Bonus"),
      iconClass: "bx bx-dollar",
      text: props.t("$820.23"),
      color: "success",
    },
    {
      title: props.t("Paid"),
      iconClass: "bx bx-dollar",
      text: props.t("$0.00"),
      color: "success",
    },
    {
      title: props.t("Pending Amout"),
      iconClass: "bx bx-window-close",
      text: props.t("$0.00"),
      color: "danger",
    },
  ])
  return (
    <React.Fragment>
      <div className="page-content">
        {/* <Container fluid> */}
        {/* Render Breadcrumb */}
        <Breadcrumbs
          title={props.t("Dashboards")}
          breadcrumbItem={props.t("Dashboards")}
        />
        <Row>
          <Col>
            {/* <Row>
              <Col xl="12">
                <Row>
                  {map(miniCards, (card, key) => (
                    <MiniCards
                      title={card.title}
                      text={card.text}
                      iconClass={card.iconClass}
                      color={card.color}
                      key={"_card_" + key}
                    />
                  ))}
                </Row>
              </Col>
            </Row>
            <br /> */}
            {/* <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">INCOME VS COMMISSION</CardTitle>
                    <Row className="justify-content-center">
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">86541</h5>
                          <p className="text-muted">Activated</p>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">2541</h5>
                          <p className="text-muted">Pending</p>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20">102030</h5>
                          <p className="text-muted">Deactivated</p>
                        </div>
                      </Col>
                    </Row>
                    <BarChart />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">PAYOUT OVERVIEW</CardTitle>
                    <Row className="justify-content-center">
                      <Col sm={4}>
                        
                      </Col>
                      <Col sm={4}>
                       
                      </Col>
                      <Col sm={4}>
                       
                      </Col>
                    </Row>
                    <StackBarChart />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Tasks />
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">JOININGS</CardTitle>
                    <Row className="justify-content-center">
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20"></h5>
                          <p className="text-muted"></p>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20"></h5>
                          <p className="text-muted"></p>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0 font-size-20"></h5>
                          <p className="text-muted"></p>
                        </div>
                      </Col>
                    </Row>
                    <BarChart />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={6}><TasksIncome /></Col>
              <Col lg={6}><Transactions /></Col>
            </Row>
<<<<<<< HEAD
         
=======
            <Row></Row> */}
            {/* <br />
            <br />
            <br />
            <br />
            <br />
            <br /> */}
            <Card className="rounded-2 shadow">
              <CardBody>
                <Row className="justify-content-between">
                  <Col xl="1">
                    <Row className="justify-content-center mini-stats-wid  mt-3">
                      <div
                        onClick={() => history.push("/users-list")}
                        className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-highlight external-event"
                      >
                        <span className="avatar-title bg-highligth">
                          <i className={"bx bx-user font-size-24"} />
                        </span>
                      </div>
                      <p className="text-center fw-medium mt-2">
                        {props.t("USERS")}
                      </p>
                    </Row>
                  </Col>
                  <Col xl="1">
                    <Row className="justify-content-center mini-stats-wid  mt-3">
                      <div
                        onClick={() => history.push("/retailers-list")}
                        className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-highlight external-event"
                      >
                        <span className="avatar-title bg-highligth">
                          <i className={"bx bx bx-store-alt font-size-24"} />
                        </span>
                      </div>
                      <p className="text-center fw-medium mt-2">
                        {props.t("RETAILERS")}
                      </p>
                    </Row>
                  </Col>
                  <Col xl="2">
                    <Row className="justify-content-center mini-stats-wid  mt-3">
                      <div
                        onClick={() => history.push("/businessPartners-list")}
                        className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-highlight external-event"
                      >
                        <span className="avatar-title bg-highligth">
                          <i className={"bx bx bx-mobile-alt font-size-24"} />
                        </span>
                      </div>
                      <p className="text-center fw-medium mt-2">
                        {props.t("BUISNESS PARTNERS")}
                      </p>
                    </Row>
                  </Col>
                  <Col xl="1">
                    <Row className="justify-content-center mini-stats-wid  mt-3">
                      <div
                        onClick={() => history.push("/transactions-list")}
                        className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-highlight external-event"
                      >
                        <span className="avatar-title bg-highligth">
                          <i className={"bx bx-transfer font-size-24"} />
                        </span>
                      </div>
                      <p className="text-center fw-medium mt-2">
                        {props.t("TRANSACTIONS")}
                      </p>
                    </Row>
                  </Col>
                  <Col xl="1">
                    <Row className="justify-content-center mini-stats-wid  mt-3">
                      <div
                        onClick={() => history.push("/logs")}
                        className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-highlight external-event external-event"
                      >
                        <span className="avatar-title bg-highligth">
                          <i className={"dripicons-document font-size-24"} />
                        </span>
                      </div>
                      <p className="text-center fw-medium mt-2">
                        {props.t("AUDIT")}
                      </p>
                    </Row>
                  </Col>
                  <Col xl="2">
                    <Row className="justify-content-center mini-stats-wid mt-3">
                      <div
                        onClick={() => history.push("/level-comission-list")}
                        className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-highlight external-event"
                      >
                        <span className="avatar-title bg-highligth">
                          <i className={"bx bx-share-alt font-size-24"} />
                        </span>
                      </div>
                      <p className="text-center fw-medium mt-2">
                        {props.t("LEVEL COMMISSION")}
                      </p>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* </Container> */}
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
