/* Levels */
export const GET_CONFIGS = "GET_CONFIGS"
export const GET_CONFIGS_SUCCESS = "GET_CONFIGS_SUCCESS"
export const GET_CONFIGS_FAIL = "GET_CONFIGS_FAIL"


/**
 * CREATE MMP
 */
 export const CREATE_MMP = "CREATE_MMP"
 export const CREATE_MMP_SUCCESS = "CREATE_MMP_SUCCESS"
 export const CREATE_MMP_FAIL = "CREATE_MMP_FAIL"
 

/**
 * Edit MMP
 */
 export const UPDATE_CONFIGS = "UPDATE_CONFIGS"
 export const UPDATE_CONFIGS_SUCCESS = "UPDATE_CONFIGS_SUCCESS"
 export const UPDATE_CONFIGS_FAIL = "UPDATE_CONFIGS_FAIL"
 
 /**
  * Delete MMP
  */
 export const DELETE_MMP = "DELETE_MMP"
 export const DELETE_MMP_SUCCESS = "DELETE_MMP_SUCCESS"
 export const DELETE_MMP_FAIL = "DELETE_MMP_FAIL"

 
/** Reset Messages */
export const RESET_MESSAGES = "RESET_MESSAGES"

export const RESET_MMP_MESSAGES_SUCCESS = 'RESET_MMP_MESSAGES_SUCCESS';
export const RESET_MMP_MESSAGES = 'RESET_MMP_MESSAGES';
