import { put, takeEvery } from "redux-saga/effects"

import { ActionTypess, createPayoutFailed, getPayoutByNameFailed, getPayoutByNameSuccess, getPayoutStatsFailed, getPayoutStatsSuccess, updatePayoutByNameFailed, updatePayoutFailed, updatePayoutSuccess } from "./actions";

import {
  getPayoutSuccess,
  getPayoutFailed,

} from "./actions"
import { getPayoutByname, getPayouts, getPayoutStats, postPayout, putPayouts } from "helpers/api_helper"

//Include Both Helper File with needed methods


function* getPayoutSaga({ payload }) {

  try {
    const response = yield getPayouts(payload);
    yield put(getPayoutSuccess(response));
  } catch (e) {
    yield put(getPayoutFailed(e.response?.status));
  }
}

function* getPayoutStatsSaga() {

  try {
    const response = yield getPayoutStats();
    yield put(getPayoutStatsSuccess(response));
  } catch (e) {
    yield put(getPayoutStatsFailed(e.response?.status));
  }
}


function* createPayoutSaga({ payload: payout }) {
  try {
    const data = yield postPayout(payout);
    const response = yield getPayouts({ page: 1, limit: 20 });
    yield put(getPayoutSuccess(response));
  } catch (e) {
    yield put(createPayoutFailed(e));
  }
}


function* updatePayoutSaga({ payload: payout }) {
  try {
    const { id, ...updatedPayout } = payout
    const data = yield putPayouts(id, updatedPayout);
    // console.log("data",data)
    const response = yield getPayouts({ page: 1, limit: 20 });
    yield put(getPayoutSuccess(response));
  } catch (e) {
    // console.log("error update:", e.response);
    yield put(updatePayoutFailed(e));
  }
}





function* payoutSaga() {
  yield takeEvery(ActionTypess.GET_PAYOUT, getPayoutSaga)
  yield takeEvery(ActionTypess.GET_PAYOUT_STATS, getPayoutStatsSaga)
  yield takeEvery(ActionTypess.UPDATE_PAYOUT, updatePayoutSaga)
  yield takeEvery(ActionTypess.CREATE_PAYOUT, createPayoutSaga)
}

export default payoutSaga;
