/* Levels */
export const GET_LEVELS = "GET_LEVELS"
export const GET_LEVELS_SUCCESS = "GET_LEVELS_SUCCESS"
export const GET_LEVELS_FAIL = "GET_LEVELS_FAIL"


/**
 * CREATE Level
 */
 export const CREATE_LEVEL = "CREATE_LEVEL"
 export const CREATE_LEVEL_SUCCESS = "CREATE_LEVEL_SUCCESS"
 export const CREATE_LEVEL_FAIL = "CREATE_LEVEL_FAIL"
 

/**
 * Edit Level
 */
 export const UPDATE_LEVEL = "UPDATE_LEVEL"
 export const UPDATE_LEVEL_SUCCESS = "UPDATE_LEVEL_SUCCESS"
 export const UPDATE_LEVEL_FAIL = "UPDATE_LEVEL_FAIL"
 
 /**
  * Delete LEVEL
  */
 export const DELETE_LEVEL = "DELETE_LEVEL"
 export const DELETE_LEVEL_SUCCESS = "DELETE_LEVEL_SUCCESS"
 export const DELETE_LEVEL_FAIL = "DELETE_LEVEL_FAIL"

 
/** Reset Messages */
export const RESET_MESSAGES = "RESET_MESSAGES"

export const RESET_LEVEL_MESSAGES_SUCCESS = 'RESET_LEVEL_MESSAGES_SUCCESS';
export const RESET_LEVEL_MESSAGES = 'RESET_LEVEL_MESSAGES';
