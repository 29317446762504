import { getEwallet, getEwalletStats } from "helpers/api_helper";
import { all, put, takeEvery } from "redux-saga/effects";
// import Repository from "../../repositories/RetailersRepository";
import { getEwalletFailed, addEwalletSuccess, getEwalletSuccess, addEwalletFail, updateEwalletSuccess, updateEwalletFail, getEwalletStatsFailed, getEwalletStatsSuccess} from "./actions";
import { ADD_EWALLET, GET_EWALLET, GET_EWALLET_STATS, UPDATE_EWALLET } from "./actionTypes";



function* getEwalletSaga({ payload }) {
  try {
    const response = yield getEwallet(payload);
    // console.log("data",response)

    yield put(getEwalletSuccess(response));
  } catch (e) {
    // console.log("error", e.response)
    yield put(getEwalletFailed(e.response?.status));
  }
}

function* getEwalletStatsSaga() {
  try {
    const response = yield getEwalletStats();
    // console.log("data",response)

    yield put(getEwalletStatsSuccess(response));
  } catch (e) {
    // console.log("error", e.response)
    yield put(getEwalletStatsFailed(e.response?.status));
  }
}

function* createEwalletSaga({ payload: Ewallet }) {
  try {
    // const result = yield new Repository().createEwallet(payload);
    // const { data } = yield createEwallet(Ewallet);
    // const response = yield getEwallets();
    yield put(addEwalletSuccess(response));
  } catch (e) {
    // console.log(e.response)
    yield (addEwalletFail(e));
  }
}



function* updateEwalletSaga({ payload: Ewallet }) {
  try {
    // const { id, ...updatedRetailer } = retailer
    // const data = yield updateRetailer(id, updatedRetailer);
    // const response = yield getRetailers();
    yield put(getEwalletSuccess(response));
  } catch (e) {
    // console.log("error update:", e.response);
    yield put(updateEwalletFail(e));
  }
}





export default function* ewalletSaga() {
  yield all([
    takeEvery(GET_EWALLET, getEwalletSaga),
    takeEvery(GET_EWALLET_STATS, getEwalletStatsSaga),
    takeEvery(ADD_EWALLET, createEwalletSaga),
    takeEvery(UPDATE_EWALLET, updateEwalletSaga),
   
  ]);
}
