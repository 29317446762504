import React from 'react'
import './Loader.scss'

const Loader = () => {


    return (
        <div className="loading">
            Loading&#8230;
        </div>
         )
}

export default Loader