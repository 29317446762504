
/**filter */
export const GET_CATEGORY_BY_NAME_SUCCESS = "GET_CATEGORY_BY_NAME_SUCCESS";
export const GET_CATEGORY_BY_NAME_REQUEST = "GET_CATEGORY_BY_NAME_REQUEST";
export const GET_CATEGORY_BY_NAME_FAILED = "GET_CATEGORY_BY_NAME_FAILED";

/**Get */
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_CATEGORY_FAILED = 'GET_CATEGORY_FAILED';

/**Create */
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILED = 'CREATE_CATEGORY_FAILED';
export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
/**Delete */
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILED = 'DELETE_CATEGORY_FAILED';
export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
/**Update */
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILED = 'UPDATE_CATEGORY_FAILED';
export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';

export const CATEGORY_MESSAGES_RESET = 'CATEGORY_MESSAGES_RESET';
export const CATEGORY_MESSAGES_SUCCESS = 'CATEGORY_MESSAGES_SUCCESS'
export const RESET_CATEGORY_MESSAGES_SUCCESS = 'RESET_CATEGORY_MESSAGES_SUCCESS';
export const RESET_CATEGORY_MESSAGES = 'RESET_CATEGORY_MESSAGES';

