
/**filter */
export const GET_PAYMENT_BY_NAME_SUCCESS = "GET_PAYMENT_BY_NAME_SUCCESS";
export const GET_PAYMENT_BY_NAME_REQUEST = "GET_PAYMENT_BY_NAME_REQUEST";
export const GET_PAYMENT_BY_NAME_FAILED = "GET_PAYMENT_BY_NAME_FAILED";

/**Get */
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_PAYMENT_FAILED = 'GET_PAYMENT_FAILED';

/**Create */
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAILED = 'CREATE_PAYMENT_FAILED';
export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST';
/**Delete */
export const DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS';
export const DELETE_PAYMENT_FAILED = 'DELETE_PAYMENT_FAILED';
export const DELETE_PAYMENT_REQUEST = 'DELETE_PAYMENT_REQUEST';
/**Update */
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAILED = 'UPDATE_PAYMENT_FAILED';
export const UPDATE_PAYMENT_REQUEST = 'UPDATE_PAYMENT_REQUEST';

export const PAYMENT_MESSAGES_RESET = 'PAYMENT_MESSAGES_RESET';
export const PAYMENT_MESSAGES_SUCCESS = 'PAYMENT_MESSAGES_SUCCESS';
export const RESET_PAYMENT_MESSAGES_SUCCESS = 'RESET_PAYMENT_MESSAGES_SUCCESS';
export const RESET_PAYMENT_MESSAGES = 'RESET_PAYMENT_MESSAGES';

