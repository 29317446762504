import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAIL,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAIL,
  CREATE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  roles: [],
  error: null,
  success: null,
  loading: false,
  nextPage: null,
  previousPage: null,
  limit: 0,
  total: 0,
  currentPage: 1,
}

const roles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLES:
      return {
        ...state,
        loading: true,
      }
    case GET_ROLES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_ROLE:
      return {
        ...state,
        loading: true,
      }
    case GET_ROLES_SUCCESS:
      const { data, ...filters } = action.payload
      return {
        ...state,
        roles: [...data],
        ...filters,
        success: "GET_ROLE",
        loading: false,
      }
    case CREATE_ROLE_SUCCESS:
      return {
        ...state,
        roles: [...state.roles, action.payload],
        success: "CREATE",
        loading: false,
      }

    case CREATE_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_ROLE:
      return {
        ...state,
        loading: true,
        error: "",
        success: ""
      }
    case UPDATE_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        roles: state.roles.map(role =>
          role.id.toString() === action.payload.id.toString()
            ? { role, ...action.payload }
            : role
        ),
        success: "UPDATE",
        loading: false,
      }


      case DELETE_ROLE_SUCCESS:
        return {
          ...state,
          loading: false,
          success: 'DELETE',
          roles: state.roles.filter(
            role => role.id.toString() !== action.payload.toString()
          ),
        }
  
      case DELETE_ROLE_FAIL:
        return {
          ...state,
          error: action.payload,
        }

    default:
      return state
  }
}

export default roles
