import React, { useEffect, useState } from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

import { useSelector, useDispatch } from "react-redux"
import {
  createMDMPayment,
  getMDMPayment,
  updateMDMPayment,
} from "store/actions"
import { withTranslation } from "react-i18next"

const PaymentModal = props => {
  const dispatch = useDispatch()
  const { modal, toggle, payment, isEdit } = props
  const [filter, setFilter] = useState({
		page:1,
		limit:20,
	  })

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (payment && payment.id) || "",
      nameEn: (payment && payment.nameEn) || "",
      nameFr: (payment && payment.nameFr) || "",
      nameAr: (payment && payment.nameAr) || "",
      serviceCharge: (payment && payment.serviceCharge) || "",
      status: (payment && payment.status) || "inactive",
      type: (payment && payment.type) || "paymentmethod",
    },
    validationSchema: Yup.object({
      nameEn: Yup.string().required(props.t("Please Enter Your English name")),
      nameFr: Yup.string().required(props.t("Please Enter Your French name")),
      nameAr: Yup.string().required(props.t("Please Enter Your Arabic Name")),
      serviceCharge: Yup.number().required(props.t("Please Enter Your service charge")),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedPayment = {
          id: payment.id,
          nameEn: values.nameEn,
          nameFr: values.nameFr,
          nameAr: values.nameAr,
          serviceCharge: values.serviceCharge,
          status: values.status ? "active" : "inactive",
        }
        // update order
        dispatch(updateMDMPayment(updatedPayment))
        // console.log("updated payment methode", updatedPayment)
        validation.resetForm()
      } else {
        const newPayment = {
          nameEn: values["nameEn"],
          nameFr: values["nameFr"],
          nameAr: values["nameAr"],
          serviceCharge: values["serviceCharge"],
          status: values["status"] ? "active" : "inactive",
          type: values["type"],
        }
        // console.log("newPaymentMethod", newPayment)

        // save new method-payment
        dispatch(getMDMPayment(filter))
        dispatch(createMDMPayment(newPayment))
       
        validation.resetForm()
      }
      toggle()
    },
  })

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit
          ? props.t("Edit Payment Method")
          : props.t("Add Payment Method")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">{props.t("English Name")}</Label>
                <Input
                  name="nameEn"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameEn || ""}
                  invalid={
                    validation.touched.nameEn && validation.errors.nameEn
                      ? true
                      : false
                  }
                />
                {validation.touched.nameEn && validation.errors.nameEn ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameEn}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("French Name")}</Label>
                <Input
                  name="nameFr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameFr || ""}
                  invalid={
                    validation.touched.nameFr && validation.errors.nameFr
                      ? true
                      : false
                  }
                />
                {validation.touched.nameFr && validation.errors.nameFr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameFr}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Arabic Name")}</Label>
                <Input
                  name="nameAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameAr || ""}
                  invalid={
                    validation.touched.nameAr && validation.errors.nameAr
                      ? true
                      : false
                  }
                />
                {validation.touched.nameAr && validation.errors.nameAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameAr}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">
                  {props.t("Service Charge")}
                </Label>
                <Input
                  name="serviceCharge"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.serviceCharge || ""}
                  invalid={
                    validation.touched.serviceCharge &&
                    validation.errors.serviceCharge
                      ? true
                      : false
                  }
                />
                {validation.touched.serviceCharge &&
                validation.errors.serviceCharge ? (
                  <FormFeedback type="invalid">
                    {validation.errors.serviceCharge}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Status")}</Label>
                <div className="form-check form-switch mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    //style={{borderColor: '#34c38f'}}
                    name="status"
                    onClick={e => {
                      validation.setFieldValue(
                        "status",
                        !validation.values.status
                      )
                    }}
                    onBlur={validation.handleBlur}
                    checked={validation.values?.status || false}
                    invalid={
                      validation.touched.status && validation.errors.status
                        ? true
                        : false
                    }
                  />
                  {validation.touched.status && validation.errors.status ? (
                    <FormFeedback type="invalid">
                      {validation.errors.status}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(PaymentModal)
