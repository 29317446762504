
import { getLevelcomissions, postLevelcomissions, putLevelcomissions } from "helpers/api_helper";
import { all, put, takeEvery } from "redux-saga/effects"

import {
  createlevelFail,
  createlevelSuccess,
  deleteLevelFail,
  deleteLevelSuccess,
  getLevelsFaild,
  getLevelsSuccess,
  resetLevelsMessages,
  updatelevelFail,
  resetLevelMessagesSuccess,
  updatelevelSuccess
} from "./actions";
import { CREATE_LEVEL, DELETE_LEVEL, GET_LEVELS, RESET_MESSAGES, UPDATE_LEVEL , RESET_LEVEL_MESSAGES,} from "./actionTypes";



function* getLevelsSaga({payload}) {
  try {
    const response = yield getLevelcomissions(payload || {page: 1, limit: 1000} );
    yield put(getLevelsSuccess(response))
  } catch (e) {
    yield put(getLevelsFaild(e))
  }
}

function* deleteLevelsSaga({ payload: id }) {
  try {
    //const response = yield call(deleteUser, user) replace with api
    yield put(deleteLevelSuccess(id))
  } catch (error) {
    yield put(deleteLevelFail(error))
  }
}

function* createLevelsSaga({ payload: level }) {
  try {
    const data = yield postLevelcomissions(level)
    // console.log("create level",response)
    const response = yield getLevelcomissions({limit: 20, page: 1})
    yield put(getLevelsSuccess(response))
  } catch (error) {
    yield put(createlevelFail(error))
  }
}

function* updateLevelsSaga({ payload: level }) {
  // console.log("saga", level)
  try {
    const{id, ...updatedLevel}= level
  const data =  yield putLevelcomissions(id,updatedLevel)
  const response = yield getLevelcomissions({limit: 20, page: 1})
    yield put(getLevelsSuccess(response))
  } catch (error) {
    yield put(updatelevelFail(error))
  }
}

function* resetLevelsMessagesSaga() {
  yield put(resetLevelsMessages())
}

function* resetLevelMessagesSaga() {
  yield put(resetLevelMessagesSuccess())
}


export default function* mdmsSaga() {
  yield all([
    takeEvery(GET_LEVELS, getLevelsSaga),
    takeEvery(DELETE_LEVEL, deleteLevelsSaga),
    takeEvery(UPDATE_LEVEL, updateLevelsSaga),
    takeEvery(CREATE_LEVEL, createLevelsSaga),
    takeEvery(RESET_MESSAGES, resetLevelsMessagesSaga),
    takeEvery(RESET_LEVEL_MESSAGES, resetLevelMessagesSaga),
  ])
}
