import React, { useEffect, useRef, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"
import {
  createRetailer,
  getMDMBrand,
  getMDMCategories,
  getMDMCities,
  getMDMCountries,
  getMDMGovernorates,
  updateRetailer,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { upload } from "helpers/api_helper"
import { withTranslation } from "react-i18next"

const ModalRetailer = props => {

  const dispatch = useDispatch()
  const fileRef = useRef()
  const [loading, setLoading] = useState(false)
  const { modal, toggle, retailer, isEdit } = props
  const { categoriesData } = useSelector(state => state.categories)
  const { citiesData } = useSelector(state => state.cities)
  const { countriesData } = useSelector(state => state.countries)
  const { governoratesData } = useSelector(state => state.governorates)
  const { brandData } = useSelector(state => state.brands)
  const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
  const { user } = useSelector(state => state.Login)
  useEffect(() => {
    dispatch(getMDMCategories())
    dispatch(getMDMCities())
    dispatch(getMDMCountries())
    dispatch(getMDMGovernorates())
    dispatch(getMDMBrand())
  }, [])
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: (retailer && retailer.firstname) || "",
      lastname: (retailer && retailer.lastname) || "",
      image_url: (retailer && retailer.image_url) || "",
      phone: (retailer && retailer.phone) || "",
      shopName: (retailer && retailer.shopName) || "",
      email: (retailer && retailer.email) || "",
      cityId: (retailer && retailer.cityId) || "",
      countryId: (retailer && retailer.countryId) || "",
      brandId: (retailer && retailer.brandId) || "",
      governorateId: (retailer && retailer.governorateId) || "",
      categoryId: (retailer && retailer.categoryId) || "",
      comission: (retailer && retailer.comission) || "",
      createdByAdmin: (retailer && retailer.createdByAdmin) || "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string()
        .required(props.t("Please Enter Your First Name"))
        .matches(props.t("^[a-zA-Z]+$", "only latin words")),
      lastname: Yup.string()
        .required(props.t("Please Enter Your Last Name"))
        .matches(props.t("^[a-zA-Z]+$", "only latin words")),
      phone: Yup.string().required(props.t("Please Enter Your Phone")),
      shopName: Yup.string().required(props.t("Please Enter Your Shop Name")),
      comission: Yup.string().required(props.t("Please Enter Your Comission")),
      email: Yup.string().required(props.t("Please Enter Your Email")),
      countryId: Yup.string().required(props.t("Please Enter Your City")),
      //brandId: Yup.string().required("Please Enter Your brand"),
      categoryId: Yup.string().required(props.t("Please Enter Your Category")),

    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedRetailer = {
          id: retailer.id,
          image_url: values.image_url || retailer.image_url,
          username: retailer.username,
          firstname: values.firstname,
          lastname: values.lastname,
          shopName: values.shopName,
          phone: values.phone,
          email: values.email,
          cityId: parseInt(values.cityId) || null,
          countryId: parseInt(values.countryId) || null,
          brandId: parseInt(values.brandId) || null,
          governorateId: parseInt(values.governorateId) || null,
          categoryId: parseInt(values.categoryId) || null,
          comission: values.comission,
          password: values.password || retailer.password,
        }
        // update order
        dispatch(updateRetailer(updatedRetailer))

        validation.resetForm()
      } else {
        const newRetailer = {
          firstname: values["firstname"],
          image_url: values["image_url"],
          shopName: values["shopName"],
          lastname: values["lastname"],
          password: values["password"],
          phone: values["phone"],
          email: values["email"],
          cityId: parseInt(values["cityId"]) || null,
          brandId: parseInt(values["brandId"]) || null,
          countryId: parseInt(values["countryId"]) || null,
          governorateId: parseInt(values["governorateId"]) || null,
          categoryId: parseInt(values["categoryId"]) || null,
          comission: values["comission"],
          accountType: "retailer",
          createdBy: user?.id || null,

        }
        //console.log("neww", newRetailer)
        // save new category
        dispatch(createRetailer(newRetailer))
        validation.resetForm()
      }
      toggle()
    },
  })

  const handelImage = event => {
    setLoading(true)
    let formData = new FormData()
    formData.set("file", event.target.files[0])
    upload(formData)
      .then(({ path }) => {
        setLoading(false)
        validation.setFieldValue("image_url", path)
      })
      .catch(e => {
        setLoading(false)
        console.log(e.response)
      })
  }

  const handleBrandImage = async (e) => {
    validation.handleChange(e)
    let image = brandData.find(brand => brand.id == e.target.value)?.imageUrl
    validation.setFieldValue("image_url", image)
  }

  const handleFileUpload = (e) => {
    e.preventDefault()
    fileRef?.current?.click()
  }

  const resetFileHandler = (e) => {
    e.preventDefault()
    validation.setFieldValue("image_url", "")
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Edit Retailer") : props.t("Add Retailer")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">{props.t("Brand")}</Label>
                <Input
                  name="brandId"
                  type="select"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={(e) => handleBrandImage(e)}
                  onBlur={validation.handleBlur}
                  value={validation.values.brandId || ""}
                  invalid={
                    validation.touched.brandId && validation.errors.brandId
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  {brandData.map(item => (
                    <option key={item.id} value={item.id}>
                      {(currentLanguage == 'ar' && item.nameAr) || (currentLanguage == 'en' && item.nameEn) || (currentLanguage == 'fr' && item.nameFr)}
                    </option>
                  ))}
                </Input>
                <p className="card-title-desc font-size-11 ms-1 me-1">
                    {props.t("Selecting a brand wil automatically set your logo to the chosen brand image. You can change it with the below field.")}
                </p>
                {validation.touched.brandId && validation.errors.brandId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.brandId}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-5">
                <Label className="form-label">{props.t("Logo")}</Label>
                <div className="row">
                  <div className="col-12">
                    <div className="position-relative">
                      <button className="btn btn-light w-100" onClick={handleFileUpload}>
                        {!loading && !validation.values.image_url && props.t("Upload Image")}
                        {loading && <Spinner className="text-center mt-1" size="sm" />}
                        {!loading && validation.values?.image_url?.split("/")[validation.values?.image_url?.split('/').length - 1]}
                      </button>
                      <span className="btn btn-info btn-icon sketch-picker ms-n4 mt-n2 rounded-circle" onClick={resetFileHandler}>
                        <i className="bx bx-x bx-xs"></i>
                      </span>
                      <Input
                        innerRef={fileRef}
                        name="image_url"
                        type="file"
                        hidden
                        validate={{
                          required: { value: true },
                        }}
                        accept=".png, .jpg, .jpeg"
                        onChange={handelImage}
                        onBlur={validation.handleBlur}
                        //value={convertToBlob(validation.values.image_url)}
                        invalid={
                          validation.touched.logo && validation.errors.logo
                            ? true
                            : false
                        }
                      />
                      {/* {!loading && validation.values.image_url && isEdit && */}
                      {/* (<i
                        className="bx bx-x bx-sm float-end me-3"
                        //type="button"
                        onClick={onRemove}
                      />) */}
                      {/* } */}
                    </div>
                  </div>
                </div>
                {validation.touched.logo && validation.errors.logo ? (
                  <FormFeedback type="invalid">
                    {validation.errors.logo}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("First name")}</Label>
                <Input
                  name="firstname"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.firstname || ""}
                  invalid={
                    validation.touched.firstname && validation.errors.firstname
                      ? true
                      : false
                  }
                />
                {validation.touched.firstname && validation.errors.firstname ? (
                  <FormFeedback type="invalid">
                    {validation.errors.firstname}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">{props.t("Last name")}</Label>
                <Input
                  name="lastname"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.lastname || ""}
                  invalid={
                    validation.touched.lastname && validation.errors.lastname
                      ? true
                      : false
                  }
                />
                {validation.touched.lastname && validation.errors.lastname ? (
                  <FormFeedback type="invalid">
                    {validation.errors.lastname}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Password")}</Label>
                <Input
                  name="password"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Shop name")}</Label>
                <Input
                  name="shopName"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.shopName || ""}
                  invalid={
                    validation.touched.shopName && validation.errors.shopName
                      ? true
                      : false
                  }
                />
                {validation.touched.shopName && validation.errors.shopName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.shopName}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">{props.t("Phone")}</Label>
                <Input
                  name="phone"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phone || ""}
                  invalid={
                    validation.touched.phone && validation.errors.phone
                      ? true
                      : false
                  }
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phone}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">{props.t("Email")}</Label>
                <Input
                  name="email"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="row">
                <div className="col-4 mb-3">
                  <Label className="form-label">{props.t("Country")}</Label>
                  <Input
                    name="countryId"
                    type="select"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.countryId || ""}
                    invalid={
                      validation.touched.countryId &&
                        validation.errors.countryId
                        ? true
                        : false
                    }
                  >
                    <option key="empty" disabled></option>
                    {countriesData.map(item => (
                      <option key={item.id} value={item.id}>
                        {(currentLanguage == 'ar' && item.nameAr) || (currentLanguage == 'en' && item.nameEn) || (currentLanguage == 'fr' && item.nameFr)}
                      </option>
                    ))}
                  </Input>
                  {validation.touched.countryId &&
                    validation.errors.countryId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.countryId}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-4 mb-3">
                  <Label className="form-label">{props.t("Governorate")}</Label>
                  <Input
                    name="governorateId"
                    type="select"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.governorateId || ""}
                    invalid={
                      validation.touched.governorateId &&
                        validation.errors.governorateId
                        ? true
                        : false
                    }
                  >
                    <option key="empty" disabled></option>
                    {governoratesData
                      .filter(
                        item =>
                          item.parentId ==
                          (validation.values.countryId ||
                            validation.initialValues.countryId)
                      )
                      ?.map(item => (
                        <option key={item.id} value={item.id}>
                          {(currentLanguage == "ar" && item.nameAr) || (currentLanguage == "en" && item.nameEn) || (currentLanguage == "fr" && item.nameFr)}
                        </option>
                      ))}
                  </Input>
                  {validation.touched.governorateId &&
                    validation.errors.governorateId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.governorateId}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-4 mb-3">
                  <Label className="form-label">{props.t("City")}</Label>
                  <Input
                    name="cityId"
                    type="select"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.cityId || ""}
                    invalid={
                      validation.touched.cityId && validation.errors.cityId
                        ? true
                        : false
                    }
                  >
                    <option key="empty" disabled></option>
                    {citiesData
                      .filter(
                        item =>
                          item.parentId ==
                          (validation.values.governorateId ||
                            validation.initialValues.governorateId)
                      )
                      ?.map(item => (
                        <option key={item.id} value={item.id}>
                          {(currentLanguage == "ar" && item.nameAr) || (currentLanguage == "en" && item.nameEn) || (currentLanguage == "fr" && item.nameFr)}
                        </option>
                      ))}
                  </Input>
                  {validation.touched.cityId && validation.errors.cityId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.cityId}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>

              <div className="mb-3">
                <Label className="form-label">{props.t("Category")}</Label>
                <Input
                  type="select"
                  name="categoryId"
                  className="form-select"
                  onChange={e => {
                    validation.handleChange(e)
                    validation.setFieldValue(
                      "comission",
                      categoriesData.find(
                        category => category.id == e.target.value
                      )?.comission
                    )
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.categoryId || []}
                  invalid={
                    validation.touched.categoryId &&
                      validation.errors.categoryId
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  {categoriesData.map(item => (
                    <option key={item.id} value={item.id}>
                      {(currentLanguage == "ar" && item.nameAr) || (currentLanguage == "en" && item.nameEn) || (currentLanguage == "fr" && item.nameFr)}
                    </option>
                  ))}
                </Input>
                {validation.touched.categoryId &&
                  validation.errors.categoryId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.categoryId}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Commission")}</Label>
                <Input
                  name="comission"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.comission || ""}
                  disabled={!isEdit}
                  invalid={
                    validation.touched.comission && validation.errors.comission
                      ? true
                      : false
                  }
                />
                {validation.touched.comission && validation.errors.comission ? (
                  <FormFeedback type="invalid">
                    {validation.errors.comission}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Added by")}</Label>
                <Input
                  name="createdByAdmin"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={user?.firstname + " " + user?.lastname}
                  disabled
                  invalid={
                    validation.touched.createdByAdmin && validation.errors.createdByAdmin
                      ? true
                      : false
                  }
                />

                {validation.touched.createdByAdmin && validation.errors.createdByAdmin ? (
                  <FormFeedback type="invalid">
                    {validation.errors.createdByAdmin}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-primary save-user">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(ModalRetailer)
