import { all, call, put, takeEvery } from "redux-saga/effects";
import {

  createMDMPaymentFailed,
  createMDMPaymentSuccess,
  deleteMDMPaymentFailed,
  deleteMDMPaymentSuccess,
  getMDMPaymentFailed,
  getMDMPaymentSuccess,
  updateMDMPaymentFailed,
  updateMDMPaymentSuccess,
} from "./actions"
import {
  PAYMENT_MESSAGES_RESET,
  CREATE_PAYMENT_REQUEST,
  DELETE_PAYMENT_REQUEST,
  GET_PAYMENT_BY_NAME_REQUEST,
  GET_PAYMENT_REQUEST,
  RESET_PAYMENT_MESSAGES,
  UPDATE_PAYMENT_REQUEST
} from "./actionTypes";



import { getPaymentMethod, postPaymentMethod, putPaymentMethod } from "helpers/api_helper";



function* getPaymentSaga({payload}) {
  try {
    // console.log("payload pay",payload)
    const response = yield getPaymentMethod(payload || {page: 1, limit: 1000});
    yield put(getMDMPaymentSuccess(response))
  } catch (e) {
    yield put(getMDMPaymentFailed(e))
  }
}

function* getPaymentBynameSaga() {

}



function* createPaymentSaga({ payload: payment }) {
  try {
    const data = yield postPaymentMethod(payment);
    const response = yield getPaymentMethod({limit: 20, page: 1});
    yield put(getMDMPaymentSuccess(response))

  } catch (e) {
    yield put(createMDMPaymentFailed(e))
  }
}

function* deletePaymentSaga({ payload: id }) {
  try {
    // const result = yield new Repository().deleteCategory(payload)
    // const data = yield new Repository().getCategories({ current: 1, limit: 20 })
    yield put(deleteMDMPaymentSuccess(id))
  } catch (e) {
    yield put(deleteMDMPaymentFailed(e))
  }
}

function* updatePaymentSaga({ payload: payment }) {
  try {
    // separer l id & objet payment
    const { id, ...updatedpayment } = payment

    const data = yield putPaymentMethod(id, updatedpayment);
    const response = yield getPaymentMethod({limit: 20, page: 1});
    yield put(getMDMPaymentSuccess(response))
  } catch (e) {
    yield put(updateMDMPaymentFailed(e))
  }
}

function* resetPaymentMessagesSaga() {
  yield put(resetPaymentMessagesSuccess())
}




export default function* paymentSaga() {
  yield all([
    takeEvery(GET_PAYMENT_REQUEST, getPaymentSaga),
    takeEvery(GET_PAYMENT_BY_NAME_REQUEST, getPaymentBynameSaga),
    takeEvery(CREATE_PAYMENT_REQUEST, createPaymentSaga),
    takeEvery(DELETE_PAYMENT_REQUEST, deletePaymentSaga),
    takeEvery(UPDATE_PAYMENT_REQUEST, updatePaymentSaga),
    takeEvery(RESET_PAYMENT_MESSAGES, resetPaymentMessagesSaga),
  ])
}
