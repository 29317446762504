import {
    GET_EWALLET,
    GET_EWALLET_SUCCESS,
    GET_EWALLET_FAIL,
    ADD_EWALLET,
    ADD_EWALLET_SUCCESS,
    ADD_EWALLET_FAIL,
    UPDATE_EWALLET,
    UPDATE_EWALLET_SUCCESS,
    UPDATE_EWALLET_FAIL,
    GET_EWALLET_STATS,
    GET_EWALLET_STATS_SUCCESS,
    GET_EWALLET_STATS_FAIL,
    
  } from "./actionTypes"
  
  const INIT_STATE = {
    ewalletData: [],
    ewalletStats: {},
    error: null,
    success: null,
    loading: false,
    nextPage: null,
    previousPage: null,
    limit: 0,
    total: 0,
    currentPage: 1
  }
  
  const ewallet = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_EWALLET:
        return {
          ...state,
          loading: true,
          success: '',
          error: ''
        }
      case GET_EWALLET_SUCCESS:
        const { data, ...filters } = action.payload
        return {
          ...state,
          ewalletData: [...data],
          loading: false,
          success: "GET",
          ...filters
        }
  
      case GET_EWALLET_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        }
      case GET_EWALLET_STATS:
        return {
          ...state,
          loading: true,
          success: '',
          error: ''
        }
      case GET_EWALLET_STATS_SUCCESS:
      
        return {
          ...state,
          ewalletStats: action.payload,
          loading: false,
          success: "GET",
        }
  
      case GET_EWALLET_STATS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        }
  
      case ADD_EWALLET:
        return {
            ...state,
            success: "",
            error: "",
  
        }
  
      case ADD_EWALLET_SUCCESS:
        return {
          ...state,
          ewalletData: [...state.ewalletData, action.payload],
  
        }
  
      case ADD_EWALLET_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
     
      case UPDATE_EWALLET:
        return {
          ...state,
          loading: true,
          error: "",
          success: ""
        }
  
      case UPDATE_EWALLET_SUCCESS:
        return {
          ...state,
          success:"update",
          ewalletData: state.ewalletData.map(ewallet =>
            ewallet.id.toString() === action.payload.id.toString()
              ? { ewallet, ...action.payload }
              : ewallet
          ),
          loading: false,
          success: "UPDATE"
        }
  
      case UPDATE_EWALLET_FAIL:
        return {
          ...state,
          error: action.payload,
        }
      
     
  
      default:
        return state
    }
  }
  
  export default ewallet
  