
/**filter */
export const GET_BRAND_BY_NAME_SUCCESS = "GET_BRAND_BY_NAME_SUCCESS";
export const GET_BRAND_BY_NAME_REQUEST = "GET_BRAND_BY_NAME_REQUEST";
export const GET_BRAND_BY_NAME_FAILED = "GET_BRAND_BY_NAME_FAILED";

/**Get */
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_REQUEST = 'GET_BRAND_REQUEST';
export const GET_FLAT_REQUEST = 'GET_FLAT_REQUEST';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_BRAND_FAILED = 'GET_BRAND_FAILED';

/**Create */
export const CREATE_BRAND_SUCCESS = 'CREATE_BRAND_SUCCESS';
export const CREATE_BRAND_FAILED = 'CREATE_BRAND_FAILED';
export const CREATE_BRAND_REQUEST = 'CREATE_BRAND_REQUEST';
/**Delete */
export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS';
export const DELETE_BRAND_FAILED = 'DELETE_BRAND_FAILED';
export const DELETE_BRAND_REQUEST = 'DELETE_BRAND_REQUEST';
/**Update */
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_FAILED = 'UPDATE_BRAND_FAILED';
export const UPDATE_BRAND_REQUEST = 'UPDATE_BRAND_REQUEST';

export const BRAND_MESSAGES_RESET = 'BRAND_MESSAGES_RESET';
export const BRAND_MESSAGES_SUCCESS = 'BRAND_MESSAGES_SUCCESS'
export const RESET_BRAND_MESSAGES_SUCCESS = 'RESET_BRAND_MESSAGES_SUCCESS';
export const RESET_BRAND_MESSAGES = 'RESET_BRAND_MESSAGES';

