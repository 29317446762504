
import { getConfigs, updateConfigs } from "helpers/api_helper";
import { all, put, takeEvery } from "redux-saga/effects"

import { getConfigsFaild, getConfigsSuccess, resetConfigssMessages, updateConfigsFail, updateConfigsSuccess } from "./actions";
import { GET_CONFIGS, RESET_MESSAGES, UPDATE_CONFIGS } from "./actionTypes";



function* getConfigsSaga() {
  try {
    const response = yield getConfigs();
    yield put(getConfigsSuccess(response))
  } catch (e) {
    yield put(getConfigsFaild(e))
  }
}

function* updateConfigsSaga({ payload }) {
  try {
    const { id, value } = payload
    // console.log("id", id, value)
    const response = yield updateConfigs(id, { value: value })
    yield put(updateConfigsSuccess(payload))
  } catch (error) {
    yield put(updateConfigsFail(error))
  }
}

function* resetConfigsMessagesSaga() {
  yield put(resetConfigssMessages())
}



export default function* mmpsSaga() {
  yield all([
    takeEvery(GET_CONFIGS, getConfigsSaga),
    takeEvery(UPDATE_CONFIGS, updateConfigsSaga),
    takeEvery(RESET_MESSAGES, resetConfigsMessagesSaga),
  ])
}
