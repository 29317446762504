import { ActionsTypess } from "./actions"

const INIT_STATE = {
  payinData: [],
  payinDataStatus: [],
  payin: {},
  payinStats: {},
  error: null,
  success: null,
  loading: false,
  nextPage: null,
  previousPage: null,
  limit: null,
  total: null,
  currentPage: null
}

const payin = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionsTypess.GET_PAYIN:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case ActionsTypess.GET_PAYIN_SUCCESS:
      const { data, ...filters } = action.payload
      return {
        ...state,
        payinData: data,
        ...filters,
        loading: false
      }
    case ActionsTypess.GET_PAYIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ActionsTypess.GET_PAYIN_STATS:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case ActionsTypess.GET_PAYIN_STATS_SUCCESS:
      
      return {
        ...state,
        payinStats: action.payload,
        loading: false
      }
    case ActionsTypess.GET_PAYIN_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ActionsTypess.GET_PAYIN_BY_NAME:
      return {
        ...state,
        loading: true,
        success: "",
        error: ""
      }
    case ActionsTypess.GET_PAYIN_BY_NAME_SUCCESS:
      const { dataStatus, ...filtersStatus } = action.payload
      return {
        ...state,
        payinDataStatus: dataStatus,
        ...filtersStatus,
        loading: false
      }
    case ActionsTypess.GET_PAYIN_BY_NAME_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ActionsTypess.UPDATE_PAYIN:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case ActionsTypess.UPDATE_PAYIN_SUCCESS:
      return {
        ...state,
        payinData: state.payinData.map(payin =>
          payin.id.toString() === action.payload.id.toString()
            ? { payin, ...action.payload }
            : payin
        ),
        success: "UPDATE",
        loading: false,
      }
    case ActionsTypess.UPDATE_PAYIN_FAILED:
      return {
        ...state,
        error: "FAILED UPDATE",
        loading: false,
      }

    case ActionsTypess.CREATE_PAYIN:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      }
    case ActionsTypess.CREATE_PAYIN_SUCCESS:
      const { payinData, ...payinFilters } = action.payload
      return {
        ...state,
        payinData: payinData,
        ...payinFilters,
        loading: false
      }
    case ActionsTypess.CREATE_PAYIN_FAIL:
      return {
        ...state,
        error: "FAILED CREATE",
        loading: false,
      }


    default:
      return state
  }
}

export default payin
