const cities = [
    {
      id: 1,
      username: "Sousse",
      arabicname:"سوسة",
    },
    {
      id: 2,
      username: "Kairouan ",
      arabicname:"قيروان ",
     
    },
    {
      id: 3,
      username: "Sfax",
      arabicname:"صفاقص ",
    },
    {
      id: 4,
      username: "Bizerte",
      arabicname:"بنزرت ",
    },
    {
      id: 5,
      username: "Tunis",
      arabicname:"تونس",
    },
    
    {
      id: 6,
      username: "Tozeur",
      arabicname:"توزر",
    },
  ]
  export {cities}