import PropTypes from 'prop-types'
import React from "react"
import { withTranslation } from 'react-i18next'
import { Col, Modal, ModalBody, Row } from "reactstrap"

const ErrorModal = (props) => {

  const { show, onCloseClick, message } = props
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}> 
            <div className="text-center">
              <i
                className="fas fa-exclamation-circle"
                style={{ fontSize: "7em", color: "red" }}
              />
                <br/>  <br/>
              <h2>{props.t("Sorry!")}</h2>
              <br/>
            
              <br/>
        
              <h4>{message}</h4>
              <br/>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-secondary btn-lg ms-2"
                onClick={onCloseClick}
              >
                {props.t("OK")}
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

ErrorModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  message: PropTypes.string,
  t: PropTypes.any,
}

export default withTranslation()(ErrorModal)
