import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  RESET_USERS_MESSAGES_SUCCESS,
  UPDATE_USER,
  GET_USERS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  userProfile: {},
  error: null,
  success: null,
  loading: false,
  nextPage: null,
  previousPage: null,
  limit: 0,
  total: 0,
  currentPage: 1
}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        loading: true,
        success: '',
        error: ''
      }
    case GET_USERS_SUCCESS:
      const { data, ...filters } = action.payload
      return {
        ...state,
        users: [...data],
        loading: false,
        success: "GET",
        ...filters
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],

      }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        loading: false,
        success: "GET_PROFILE"
      }

    case UPDATE_USER:
      return {
        ...state,
        loading: true,
        error: "",
        success: ""
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        success: "UPDATE",
        users: state.users.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false,
        
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      case UPDATE_PROFILE:
        return {
          ...state,
          loading: true,
          error: "",
          success: ""
        }
  
      case UPDATE_PROFILE_SUCCESS:
        return {
          ...state,
          success: "UPDATE",
          userProfile:{...state.userProfile,...action.payload},
          loading: false,
        }
  
      case UPDATE_PROFILE_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: 'DELETE',
        users: state.users.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case RESET_USERS_MESSAGES_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        error: null,
        success: null
      }

    default:
      return state
  }
}

export default contacts
