import PropTypes from 'prop-types'
import React from "react"
import { withTranslation } from 'react-i18next'
import { Col, Modal, ModalBody, Row } from "reactstrap"

const SuccessModal = (props) => {

  const { show, onCloseClick, message } = props
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-check-circle-outline"
                style={{ fontSize: "9em", color: "#4BB543" }}
              />
              <h2>{props.t("Success")}</h2>
              <h4>{message}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={onCloseClick}
              >
                {props.t("OK")}
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

SuccessModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  message: PropTypes.string,
  t: PropTypes.any,
}

export default withTranslation()(SuccessModal)
