import {
    GET_EWALLET,
    GET_EWALLET_FAIL,
    GET_EWALLET_SUCCESS,
    GET_EWALLET_STATS,
    GET_EWALLET_STATS_FAIL,
    GET_EWALLET_STATS_SUCCESS,
    ADD_EWALLET,
    ADD_EWALLET_FAIL,
    ADD_EWALLET_SUCCESS,
    UPDATE_EWALLET,
    UPDATE_EWALLET_FAIL,
    UPDATE_EWALLET_SUCCESS,
    
   
  } from "./actionTypes"
  
  export const getEwallet = (filter) => ({
    type: GET_EWALLET,
    payload:filter
  })
  
  export const getEwalletSuccess = ewallet => ({
    type: GET_EWALLET_SUCCESS,
    payload: ewallet,
  })
  
  export const getEwalletFailed = error => ({
    type: GET_EWALLET_FAIL,
    payload: error,
  })
  export const getEwalletStats = () => ({
    type: GET_EWALLET_STATS,
  })
  
  export const getEwalletStatsSuccess = ewallet => ({
    type: GET_EWALLET_STATS_SUCCESS,
    payload: ewallet,
  })
  
  export const getEwalletStatsFailed = error => ({
    type: GET_EWALLET_STATS_FAIL,
    payload: error,
  })
  

  
  export const addNewEwallet = ewallet => ({
    type: ADD_EWALLET,
    payload: ewallet,
  })
  
  export const addEwalletSuccess = ewallet => ({
    type: ADD_EWALLET_SUCCESS,
    payload: ewallet,
  })
  
  export const addEwalletFail = error => ({
    type: ADD_EWALLET_FAIL,
    payload: error,
  })
  
  export const updateEwallet = ewallet => ({
    type: UPDATE_EWALLET,
    payload: ewallet,
  })
  
  export const updateEwalletSuccess = ewallet => ({
    type: UPDATE_EWALLET_SUCCESS,
    payload: ewallet,
  })
  
  export const updateEwalletFail = error => ({
    type: UPDATE_EWALLET_FAIL,
    payload: error,
  })