import React, { useEffect, useRef, useState } from "react"
import * as Yup from "yup"
import logo from "../../../assets/images/logo.png"
import { useFormik } from "formik"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
  Card,
  CardBody,
  Table,
  Button,
} from "reactstrap"

import { getUsers as onGetUsers } from "store/users/actions"
import { useDispatch, useSelector } from "react-redux"
const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
import { withTranslation } from "react-i18next"
import { getMDMPayment, getPayout } from "store/actions"
import { Link } from "react-router-dom"
import moment from "moment"
import ReactToPrint from "react-to-print"

const PayoutPrintModal = props => {
  const [filter, setFilter] = useState({
    page: 1,
    limit: 50,
    status: "active",
  })
  const dispatch = useDispatch()
  const { modal, toggle, payout, isEdit } = props
  const { users } = useSelector(state => state.users)

  useEffect(() => {
    dispatch(onGetUsers(filter))

    dispatch(getMDMPayment(filter))
  }, [dispatch, filter])
  //Print the Invoice
  const componentRef = useRef()

  return (
    <Modal
      isOpen={modal}
      style={{ minWidth: "70vw" }}
      toggle={() => toggle(false)}
      ref={componentRef}
    >
      <ModalBody>
        <Row>
          <Col lg="12" xl="12">
            <Card>
              <CardBody>
                <div className="invoice-title">
                  <h4 className="float-end font-size-16">Id # {payout.id}</h4>
                  <div className="mb-4">
                    <div>
                      <strong> DELIVERY RECEIPT</strong>
                    </div>
                    <br />
                    <img src={logo} alt="logo" height="80" />
                  </div>
                </div>
                <hr />
                <Row>
                  <Col sm="6">
                    <address>
                      <strong>Company Kasb</strong>
                      <br />
                      <br />
                      <strong>
                        Company phone number : </strong> {""} +216 53 404 503
                     
                      <br />
                      <br />
                      <strong>
                        Company address :  </strong> {""} Accelerantech Sarl, Boulevard 20
                        Mars, Imm Aoun Jawhra Sousse, 4000
                    
                      <br />
                      <br />
                      <strong>Email :</strong> {""} contact.us@accelerantech.com
                      <br />
                      <br />

                      <br />
                    </address>
                  </Col>
                </Row>
                <div className="py-2 mt-3">
                  <h3 className="font-size-15 font-weight-bold">
                  <strong>   Process Payment</strong>
                  </h3>
                </div>
                <div className="table-responsive">
                  <Table className="table-nowrap">
                    <thead>
                      <tr>
                        <th >Full name</th>
                        <th>Requested amount</th>
                        <th>Paid amount</th>
                        <th>Payment Method</th>
                        <th >Payment date</th>
                        <th >paid by</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {payout.partner?.firstname +
                            " " +
                            payout.partner?.lastname || props.t("Not provided")}
                        </td>
                        <td>{payout.amount || props.t("----")}</td>
                        <td>{payout.releasedAmount || payout.amount || props.t("----")}</td>
                        <td> {payout.payoutMethod
                            ? (currentLanguage == "ar" &&
                                payout.payoutMethod?.nameAr) ||
                              (currentLanguage == "en" &&
                                payout.payoutMethod?.nameEn) ||
                              (currentLanguage == "fr" &&
                                payout.payoutMethod?.nameFr)
                            : props.t("Not provided")}</td>
                      
                        <td>
                          {(payout.paidAt &&
                            moment(new Date(payout.paidAt)).format(
                              "YYYY-MM-DD HH:mm"
                            )) ||
                            props.t("Not provided")}
                        </td>
                        <td>
                          {(payout.paidBy &&
                            payout.paidBy?.firstname +
                              " " +
                              payout.paidBy?.lastname) ||
                            props.t("------")}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <br />
                  <br />
                  <br />
                <Row>
                  <Col sm="6"></Col>
                  <Col sm="2">
                    <div className="border-0 text-center">
                      <strong>Signature</strong>
                    </div>
                  </Col>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </Row>
                <div className="d-print-none">
                  <div className="float-end">
                    <ReactToPrint
                      trigger={() => (
                        <Button type="button" color="success">
                          Print{" "}
                        </Button>
                      )}
                      content={() => componentRef.current}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(PayoutPrintModal)
