import {
    BRAND_MESSAGES_RESET,
    RESET_BRAND_MESSAGES_SUCCESS,
    BRAND_MESSAGES_SUCCESS,
    CREATE_BRAND_FAILED,
    CREATE_BRAND_REQUEST,
    CREATE_BRAND_SUCCESS,
    DELETE_BRAND_FAILED,
    DELETE_BRAND_REQUEST,
    DELETE_BRAND_SUCCESS,
    GET_BRAND_BY_NAME_FAILED,
    GET_BRAND_BY_NAME_REQUEST,
    GET_BRAND_BY_NAME_SUCCESS,
    GET_BRAND_FAILED,
    GET_BRAND_REQUEST,
    GET_BRAND_SUCCESS,
    UPDATE_BRAND_FAILED,
    UPDATE_BRAND_REQUEST,
    UPDATE_BRAND_SUCCESS,
  } from "./actionTypes"
  
  export const initialState = {
    loading: false,
    brandData: [],
    success: "",
    error: "",
    nextPage: null,
    previousPage: null,
    limit: 0,
    total:0,
    currentPage: 1
  }
  
  function reducer(state = initialState, action) {
    switch (action.type) {
      case GET_BRAND_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_BRAND_SUCCESS:
        const {data, ...filters} = action.payload
        return {
          ...state,
          brandData: [...data],
          ...filters,
          loading: false,
          success: "GET",
        }
      case GET_BRAND_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case GET_BRAND_BY_NAME_REQUEST:
        return {
          ...state,
          success: "",
          error: "",
          loading: true,
        }
      case GET_BRAND_BY_NAME_SUCCESS:
        return {
          ...state,
          brandData: action.payload,
          loading: false,
          success: "SUCCESS",
        }
      case GET_BRAND_BY_NAME_FAILED:
        return {
          ...state,
          error: action.payload.message,
          loading: false,
        }
      case CREATE_BRAND_REQUEST:
        return {
          ...state,
          loading: true,
        }
      case CREATE_BRAND_SUCCESS:
        return {
          ...state,
          brandData: [...state.brandData, action.payload],
          success: "CREATE",
          loading: false,
        }
      case CREATE_BRAND_FAILED:
        return {
          ...state,
          error: "FAILED CREATE",
          loading: false,
        }
      case DELETE_BRAND_REQUEST:
        return {
          ...state,
          loading: true,
          success: "",
          error: "",
        }
      case DELETE_BRAND_SUCCESS:
        return {
          ...state,
          brandData: state.brandData.filter(
            brand => brand.id.toString() !== action.payload.toString()
          ),
          success: "DELETE",
          loading: false,
        }
      case DELETE_BRAND_FAILED:
        return {
          ...state,
          error: "FAILED DELETE",
          loading: false,
        }
      case UPDATE_BRAND_REQUEST:
        return {
          ...state,
          loading: true,
          success: "UPDATE",
          error: "",
        }
      case UPDATE_BRAND_SUCCESS:
        return {
          ...state,
          brandData: state.brandData.map(brand =>
            brand.id.toString() === action.payload.id.toString()
              ? { brand, ...action.payload }
              : brand
          ),
          success: "UPDATE",
          loading: false,
        }
      case UPDATE_BRAND_FAILED:
        return {
          ...state,
          error: "FAILED UPDATE",
          loading: false,
        }
      case BRAND_MESSAGES_RESET:
        return {
          ...state,
          error: "",
          success: "",
          loading: false,
        }
        case RESET_BRAND_MESSAGES_SUCCESS:
        return {
          ...state,
          error: null,
          success: null
        }
      default:
        return state
    }
  }
  
  export default reducer
  